import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { KiosksService } from '../services/kiosks.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class KioskListResolver implements Resolve<Observable<string>> {

    constructor(private kiosksService: KiosksService) { }

    resolve() {
        return this.kiosksService.getKiosks().catch(() => {
            return Observable.of('error');
        });
    }

}

