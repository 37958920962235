import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../interfaces/user';
import { LoginData } from '../interfaces/logindata';
import { SessionToken } from '../interfaces/sessiontoken';
import { of } from 'rxjs/observable/of';
import { constants } from '../../assets/constants';

@Injectable()
export class UsersService {
  private urlGetUsers =  constants.urlAPI + constants.endpoints.users.get;
  private urlGetUser =  constants.urlAPI + constants.endpoints.user.get;
  private urlPutUser =  constants.urlAPI + constants.endpoints.user.put;
  private urlPostUser = constants.urlAPI + constants.endpoints.user.post;
  private urlDeleteUser = constants.urlAPI + constants.endpoints.user.delete;
  private urlGetUserStatus = constants.urlAPI + constants.endpoints.userStatus.get;

  constructor(public http: HttpClient) { }

  public getUsers(): Observable<any> {
    return this.http.get(this.urlGetUsers);
  }

  public getUser(id): Observable<any> {
    return this.http.get(this.urlGetUser.replace(':id', id));
  }

  public putUser(user: User): Observable<any> {
    const data = user;
    return this.http.put(this.urlPutUser, data);
  }

  public postUser(user: User): Observable<any> {
    const data = user;
    return this.http.post(this.urlPostUser, data);
  }

  public deleteUser(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteUser.replace(':id', id.toString()));
  }

  public getUserStatus(): Observable<any> {
    return this.http.get(this.urlGetUserStatus);
  }
}
