import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { KioskListItem } from '../../interfaces/kiosk';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-monitoring-list',
  templateUrl: './monitoring-list.component.html',
  styleUrls: ['./monitoring-list.component.scss']
})
export class MonitoringListComponent implements OnInit, AfterViewInit {

  gymList: any;

  displayedColumns = ['id', 'orderid', 'name', 'contact', 'status', 'detail'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) {
    this.gymList = [];
  }

  ngOnInit() {
    const apiGyms = this.route.snapshot.data.gyms;
    if (apiGyms !== 'error') {
      this.gymList =  apiGyms;
    }
    this.translate.get('GYMS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.gymList);

    // Filter in nested objects
    this.dataSource.filterPredicate = (data, filter: string)  => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();

    // In order to sort nested objects
    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'gym.name': return item.gym.name.toLowerCase();
        default: return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }
}
