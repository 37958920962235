import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UsersService } from '../services/users.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class UserListResolver implements Resolve<Observable<string>> {

    constructor(private usersService: UsersService) { }

    resolve() {
        return this.usersService.getUsers().catch(() => {
            return Observable.of('error');
        });
    }

}

