import { SubscriptionAlert } from './../interfaces/subscription';
import { AlertsService } from './alerts.service';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Role } from '../interfaces/role';
import { Application } from '../interfaces/application';
import { Profile } from '../interfaces/profile';
import { Status } from '../interfaces/status';
import { GymType } from '../interfaces/gymtype';
import { TrainingMode } from '../interfaces/trainingmode';

import { ProfilesService } from './profiles.service';
import { RolesService } from './roles.service';
import { ApplicationsService } from './applications.service';
import { GymsService } from './gyms.service';
import { ScriptsService } from './scripts.service';
import { HttpClient } from '@angular/common/http';
import { UsersService } from './users.service';
import { GymStationType } from '../interfaces/gymstationtype';
import { Subject } from 'rxjs/Subject';
import { EventsService } from './events.service';
import { constants } from '../../assets/constants';
import { SessionToken } from '../interfaces/sessiontoken';
import { FloorType } from '../interfaces/floortype';
import { Mood } from '../interfaces/mood';
import { GymExecutionMode } from '../interfaces/gym';
import { SubscriptionsService } from './subscriptions.service';
import { GymFormat } from '../interfaces/gymformat';
import { Parameter } from '../interfaces/parameter';

@Injectable()
export class GlobalService implements OnInit {
  profileList: Profile[];
  roleList: Role[];
  applicationList: Application[];
  gymStatusList: Status[];
  gymTypeList: GymType[];
  gymFormatList: GymFormat[];
  scriptStatusList: Status[];
  trainingModeList: TrainingMode[];
  userStatusList: Status[];
  stationTypesList: GymStationType[];
  executionModesList: GymExecutionMode[];
  moodList: Mood[];
  gymAlerts: SubscriptionAlert;

  public hrTypeList = [{id: 0, name: 'Ant+'}, {id: 2, name: 'WASP'}, {id: 1, name: 'MyZone'}];
  public floorTypeList: FloorType[] = [{id: 0, name: 'None'}, {id: 1, name: 'ControlBox'}, {id: 2, name: 'Versatile'}];

  public defaultParameters: Parameter[] = [
    { parameterId: 1, valueTypeId: constants.parameterValueTypes.stringType, parameterValue: "OFF"},
    { parameterId: 2, valueTypeId: constants.parameterValueTypes.stringType, parameterValue: "OFF"},
    { parameterId: 3, valueTypeId: constants.parameterValueTypes.doubleType, parameterValue: 1.0},
    { parameterId: 4, valueTypeId: constants.parameterValueTypes.stringType, parameterValue: ""},
    { parameterId: 5, valueTypeId: constants.parameterValueTypes.booleanType, parameterValue: 0},
    { parameterId: 6, valueTypeId: constants.parameterValueTypes.booleanType, parameterValue: 0},
    { parameterId: 7, valueTypeId: constants.parameterValueTypes.intType, parameterValue: 1}
  ];

  // Observable string sources
  private titleEventSource = new Subject<string>();
  // Observable string streams
  pageTitleChangeEvent$ = this.titleEventSource.asObservable();

  private urlGetTrainingModes = constants.urlAPI + constants.endpoints.trainingModes.get;
  private urlGetStationTypes = constants.urlAPI + constants.endpoints.stationTypes.get;
  private urlGetExecutionModes = constants.urlAPI + constants.endpoints.executionModes.get;
  private urlGetMoods = constants.urlAPI + constants.endpoints.moods.get;

  constructor(public http: HttpClient,
    private rolesService: RolesService,
    private profilesService: ProfilesService,
    private applicationsService: ApplicationsService,
    private gymsService: GymsService,
    private scriptsService: ScriptsService,
    private usersService: UsersService,
    private eventsService: EventsService,
    private alertsService: AlertsService,
    private subscriptionsService: SubscriptionsService) {

    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'login' && params.value === 'ok') {
        this.loadGlobals();
      }
    });
    this.loadGlobals();   
  }

  ngOnInit() {
  }

  private loadGlobals() {
    this.loadRoles();
    this.loadProfiles();
    this.loadApplications();
    this.loadGymStatus();
    this.loadGymTypes();
    this.loadGymFormats();
    this.loadScriptStatus();
    this.loadTrainingModes();
    this.loadUserStatus();
    this.loadStationTypes();
    this.loadExecutionModes();
    this.loadMoods();
    this.loadGymAlerts();
  }

  public getGlobals(): Observable<any> {
    const globalData = {
      profileList: this.profileList,
      roleList: this.roleList,
      applicationList: this.applicationList,
      gymStatusList: this.gymStatusList,
      gymTypeList: this.gymTypeList,
      gymFormatList: this.gymFormatList,
      scriptStatusList: this.scriptStatusList,
      trainingModeList: this.trainingModeList,
      userStatusList: this.userStatusList,
      stationTypesList: this.stationTypesList
    };

    return Observable.of(globalData);
  }

  private loadRoles() {
    this.rolesService.getRoles().subscribe(
      result => { this.roleList = result; },
      error => { console.log('Error: loadRoles'); }
    );
  }
  public getRoles() { return this.roleList; }

  private loadProfiles() {
    this.profilesService.getProfiles().subscribe(
      result => { this.profileList = result; },
      error => { console.log('Error: loadProfiles'); }
    );
  }
  public getProfiles() { return this.profileList; }

  private loadApplications() {
    this.applicationsService.getApplications().subscribe(
      result => { this.applicationList = result; },
      error => { console.log('Error: loadApplications'); }
    );
  }
  public getApplications(type?: number) { // type: 1 - APPs / 2 - DLLs
    if (type) {
      // const appTypeList: Application[] = [];
      // if (this.applicationList !== undefined) {
      //   for (const application of this.applicationList) {
      //     if (application.type === type) {
      //       appTypeList.push(application);
      //     }
      //   }
      // }
      // return appTypeList;
      return this.applicationList.filter(app => (app.type === type));
    } else {
      return this.applicationList;
    }
  }

  private loadGymStatus() {
    this.gymsService.getGymStatus().subscribe(
      result => { this.gymStatusList = result; },
      error => { console.log('Error: loadGymStatus'); }
    );
  }
  public getGymStatus(): Observable<Status[]> {
    return new Observable(observer => {
      observer.next(this.gymStatusList);
    });
  }
  public getGymStatusList() {
    return this.gymStatusList;
  }

  private loadGymTypes() {
    this.gymsService.getGymTypes().subscribe(
      result => { this.gymTypeList = result; },
      error => { console.log('Error: loadGymTypes'); }
    );
  }

  public getGymTypes(): Observable<GymType[]> {
    return new Observable(observer => {
      observer.next(this.gymTypeList);
    });
  }

  private loadGymFormats() {
    this.gymFormatList = [
      { id: 1, name:"PRAMA", description:null },
      { id: 2, name:"BOOTIQ", description:null },
      { id: 3, name:"PBP", description:null },
      { id: 4, name:"Test", description:null },
    ]
    /*
    this.gymsService.getGymCategories().subscribe(
      result => { this.gymCategoryList = result; },
      error => { console.log('Error: loadGymCategories'); }
    );
    */
  }

  public getGymFormats(): Observable<GymType[]> {
    return new Observable(observer => {
      observer.next(this.gymFormatList);
    });
  }

  private loadScriptStatus() {
    this.scriptsService.getScriptStatus().subscribe(
      result => { this.scriptStatusList = result; },
      error => { console.log('Error: loadScriptStatus'); }
    );
  }
  public getScriptStatusList() { return this.scriptStatusList; }


  private loadTrainingModes() {
    const resp: Observable<any> = this.http.get(this.urlGetTrainingModes);
    resp.subscribe(
      result => { this.trainingModeList = result; },
      error => { console.log('Error: loadTrainingModes'); }
    );
  }
  public getTrainingModes(): Observable<any> {
    return new Observable(observer => {
      observer.next(this.trainingModeList);
    });
  }

  private loadMoods() {
    const resp: Observable<any> = this.http.get(this.urlGetMoods);
    resp.subscribe(
      result => { this.moodList = result; },
      error => { console.log('Error: loadMoods'); }
    );
  }
  public getMoods(): Observable<any> {
    return new Observable(observer => {
      observer.next(this.moodList);
    });
  }

  private loadUserStatus() {
    this.usersService.getUserStatus().subscribe(
      result => { this.userStatusList = result; },
      error => { console.log('Error: loadUserStatus'); }
    );
  }
  public getUserStatusList() { return this.userStatusList; }

  private loadStationTypes() {
    const resp: Observable<any> = this.http.get(this.urlGetStationTypes);
    resp.subscribe(
      result => { this.stationTypesList = result; },
      error => { console.log('Error: loadStationTypes'); }
    );
  }
  public getStationTypes() {
    return this.stationTypesList;
  }

  private loadExecutionModes() {
    const resp: Observable<any> = this.http.get(this.urlGetExecutionModes);
    resp.subscribe(
      result => { this.executionModesList = result; },
      error => { console.log('Error: loadExecutionModes'); }
    );
  }
  public getExecutionModes() {
    return this.executionModesList;
  }

  private loadGymAlerts() {
    this.subscriptionsService.getAlerts().subscribe(
      result => { this.gymAlerts = result; },
      error => { console.log('Error: loadGymAlerts'); }
    );
  }

  public removeByKey(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }

  public removeByKeys(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value && array[index][params.key2] === params.value2) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }

  public changePageTitle(params) {
    this.titleEventSource.next(params);
  }

  public countryList() {
    return ['Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua & Deps', 'Argentina',
      'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados',
      'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia Herzegovina', 'Botswana',
      'Brazil', 'Brunei', 'Bulgaria', 'Burkina', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde',
      'Central African Rep', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo', 'Congo {Democratic Rep}',
      'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica',
      'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
      'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia',
      'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana',
      'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland {Republic}',
      'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati',
      'Korea North', 'Korea South', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
      'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Madagascar', 'Malawi',
      'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico',
      'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar, {Burma}',
      'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Norway',
      'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland',
      'Portugal', 'Qatar', 'Romania', 'Russian Federation', 'Rwanda', 'St Kitts & Nevis', 'St Lucia',
      'Saint Vincent & the Grenadines', 'Samoa', 'San Marino', 'Sao Tome & Principe', 'Saudi Arabia', 'Senegal',
      'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
      'South Africa', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Swaziland', 'Sweden', 'Switzerland',
      'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tonga', 'Trinidad & Tobago', 'Tunisia',
      'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom',
      'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam', 'Yemen',
      'Zambia', 'Zimbabwe'];
  }

  public postFile(data: File): Observable<string> {
    return Observable.create(observer => {
      const url = constants.urlAPI + constants.endpoints.media.post;
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      const token: SessionToken = JSON.parse(localStorage.getItem('pmsession'));
      formData.append('files', data, data.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response).resultFiles[0]);
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + token.token);
      xhr.send(formData);
    });
  }

  // Añade 2 horas para evitar GMT
  public fixUTC(date: Date) {
    date.setHours(date.getHours() + 2);
    return date;
  }

  // Redondea a hora 0:00:00 restando el GMT
  public roundDate(dateString: string){
    let date = new Date(dateString);
    var offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - offset);
    return date;
  }

  // Devuelve true si date esta dentro de (dateNow - gymAlert.months)
  public gymCloseToExpiration(dateString: string){
    let dateUntil = new Date(dateString);
    let dateNow = new Date();
    let dateAlert = new Date(dateUntil);
    dateAlert.setMonth( dateUntil.getMonth() - this.gymAlerts.months);

    return dateNow >= dateAlert && dateNow < dateUntil;
  }
}
