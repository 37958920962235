import { Component, OnInit } from '@angular/core';
import { Script } from '../../interfaces/script';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { ScriptsService } from '../../services/scripts.service';
import { AlertsService } from '../../services/alerts.service';
import { SessionToken } from '../../interfaces/sessiontoken';
import { Gym } from '../../interfaces/gym';
import { MatDialog, MatCheckbox } from '@angular/material';
import { ModalRemoveScriptComponent } from './modal-remove-script.component';

@Component({
  selector: 'app-script-detail',
  templateUrl: './script-detail.component.html',
  styleUrls: ['./script-detail.component.scss']
})
export class ScriptDetailComponent implements OnInit {

  canCreate: boolean;
  canDelete: boolean;
  canModify: boolean;

  script: Script = {
    id: null,
    name: '',
    description: '',
    filename: '',
    creationDate: '',
    type: 0,
    periodType: 2,
    periodValue: 1,
    gyms: [],
    status: 1
  };
  scriptList: Script[];
  gymList: Gym[];

  periodType: number;

  isNew: Boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private globalService: GlobalService,
    private scriptsService: ScriptsService,
    private alertsService: AlertsService,
    public dialog: MatDialog) {

    this.periodType = 1;
  }

  ngOnInit() {
    this.scriptList = this.route.snapshot.data.scriptList;
    this.gymList = this.route.snapshot.data.gymList;

    this.route.params.subscribe(params => {
      if (params.id !== 'new') {
        this.script = this.route.snapshot.data.script;
        this.globalService.changePageTitle([this.script.name]);
      } else {
        this.isNew = true;
        this.translate.get('SCRIPTS.TITLE_NEW').subscribe((translation) => {
          this.globalService.changePageTitle([translation]);
        });
      }
    });

    this.canCreate = this.authService.isInRole(constants.roles.script_create);
    this.canModify = this.authService.isInRole(constants.roles.script_modify);
    this.canDelete = this.authService.isInRole(constants.roles.script_delete);

    this.script.status = this.script.status === 1 ? true : false;
  }

  public saveScript() {
    this.scriptsService.putScript(this.script).subscribe(
      result => {
        this.translate.get('SCRIPTS.SCRIPT_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/scripts']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createScript() {
    this.script.id = 0;
    this.scriptsService.postScript(this.script).subscribe(
      result => {
        this.translate.get('SCRIPTS.SCRIPT_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/scripts']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private preparePeriodValue() {
    if (this.script.type === 1) {
      this.script.periodValue = this.periodType === 1 ? this.script.periodValue * 1 : this.script.periodValue * 24;
    } else {
      this.script.periodValue = 0;
    }
    this.periodType = 1;
  }

  private prepareActiveGyms() {
    for (const gym of this.script.gyms) {
      gym.active = gym.activelocal;
    }
  }

  private prepareStatusCheck(){
    this.script.status = this.script.status === true ? 1 : 0;
  }

  private modalRemoveScriptOpen(script: Script) {
    this.dialog.open(ModalRemoveScriptComponent, {
      data: {
        script: script,
        contacts: this.scriptList
      }
    });
  }

  onSubmit() {
    this.preparePeriodValue();
    this.prepareActiveGyms();
    this.prepareStatusCheck();

    if (this.isNew) {
      this.createScript();
    } else {
      this.saveScript();
    }
  }

  async postFile(data: File): Promise<any> {
    this.globalService.postFile(data).subscribe(
      fileName => {
        this.script.filename = fileName;
      }, error => {
        this.translate.get('SCRIPTS.ERROR_UPLOAD_SCRIPT').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }

  
}
