import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { BodyChallengesService } from '../services/body-challenges.service';

@Injectable()
export class BodyChallengeFormResolver implements Resolve<Observable<string>> {

    constructor(private bodyChallengesService: BodyChallengesService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.bodyChallengesService.getBodyChallengeForm(route.paramMap.get('id')).catch(() => {
            return Observable.of('error');
        });
    }
}

