import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SubscriptionGym } from '../../../interfaces/subscription';


@Component({
  selector: 'app-gyms-list',
  templateUrl: './gyms-list.component.html',
  styleUrls: ['./gyms-list.component.scss']
})
export class GymsListComponent implements OnInit {

  @Input() gymList: SubscriptionGym[];

  displayedColumns = ['name', 'alert', 'alertTest', 'programs', 'packs', 'pro', 'bootiq', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  public editMode: number;

  constructor() {    
    this.editMode = 0;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.gymList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  public editItem(itemId: number){
    
  }

}
