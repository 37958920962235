import { Component, OnInit } from '@angular/core';
import { GymProgram } from '../../interfaces/gymprogram';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramsService } from '../../services/programs.service';
import { AlertsService } from '../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { SessionListComponent } from '../partials/session-list/session-list.component';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-specific-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss']
})
export class SpecificProgramDetailComponent implements OnInit {

  program: GymProgram = {
    id: null,
    name: '',
    activeFrom: '',
    activeUntil: '',
    available: true,
    image: '',
    idDemo: null,
    status: 0,
    isSpecific: true,
    cloneSessionsFromProgram: null,
    sessions: [],
    type: 0
  };

  isNew: Boolean = false;
  creatorPrograms: GymProgram[];

  imagePath: string;
  urlNoImage: string;

  constructor(public route: ActivatedRoute,
    private router: Router,
    private programsService: ProgramsService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    public globalService: GlobalService) {
  }

  ngOnInit() {
    this.imagePath = constants.urlAPP + constants.logos.urlPrograms;
    this.urlNoImage = constants.logos.urlNoImage;

    if (this.route.snapshot.data.program === 'new') {
      this.isNew = true;

      this.translate.get('SPECIFIC_PROGRAMS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.program = this.route.snapshot.data.program;
      this.globalService.changePageTitle([this.program.name]);
    }

    this.creatorPrograms = this.route.snapshot.data.creatorPrograms;
  }

  public saveProgram() {
    this.programsService.putSpecificProgram(this.program).subscribe(
      result => {
        this.translate.get('SPECIFIC_PROGRAMS.PROGRAM_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createProgram() {
    this.program.id = 0;
    this.programsService.postSpecificProgram(this.program).subscribe(
      result => {
        this.translate.get('SPECIFIC_PROGRAMS.PROGRAM_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/programs']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public changeProgramClone(event) {
    this.program.sessions = event.value.sessions;
  }

  onSubmit() {
    if (this.isNew) {
      this.createProgram();
    } else {
      this.saveProgram();
    }
  }

  async postFile(data: File): Promise<any> {
    this.globalService.postFile(data).subscribe(
      fileName => {
        this.imagePath = constants.urlAPP + constants.urlTempFiles;
        this.program.image = fileName;
      }, error => {
        this.translate.get('PROGRAMS.ERROR_UPLOAD_LOGO').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }

}
