import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Gym } from '../../interfaces/gym';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { Chain } from '../../interfaces/chain';

@Component({
  selector: 'app-gyms',
  templateUrl: './gyms.component.html',
  styleUrls: ['./gyms.component.scss']
})
export class GymsComponent implements OnInit, AfterViewInit {
  canCreate: boolean;
  canModify: boolean;

  gymList: any;
  chainList: Chain[];

  displayedColumns = ['id', 'name', 'order', 'chain', 'status', 'country', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private authService: AuthService) { }

  ngOnInit() {
    this.gymList = this.route.snapshot.data.gymList;
    this.chainList = this.route.snapshot.data.chainList;

    this.translate.get('GYMS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.gym_create);
    this.canModify = this.authService.isInRole(constants.roles.gym_detail);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.gymList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  private getChainName(id: number) {
    if (id) {
      return this.chainList.filter(chain => (chain.id === id))[0].name;
    } else {
      return '-';
    }
  }
}
