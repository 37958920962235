import { Component, OnInit, Input } from '@angular/core';
import { GymsService } from '../../../services/gyms.service';
import { Gym } from '../../../interfaces/gym';
import { MatTableDataSource } from '@angular/material';
import { ApplicationVersion } from '../../../interfaces/applicationversion';

@Component({
  selector: 'app-version-gym-list',
  templateUrl: './version-gym-list.component.html',
  styleUrls: ['./version-gym-list.component.scss']
})
export class VersionGymListComponent implements OnInit {

  @Input() gymsActive;
  @Input() gymList;

  dataSource;
  displayedColumns = ['gymName', 'versionName', 'forcedDependencies'];
  // private gymList: Gym[];

  constructor(private gymsService: GymsService) {
    // this.getGymList();
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.gymList);
  }

  private getGymList() {
    this.gymsService.getGyms().subscribe(
      result => {
        this.gymList = result;
        this.dataSource = new MatTableDataSource(this.gymList);
      },
      error => {
        console.log('Error: getGyms');
      }
    );
  }

  private isChecked(gymId: number) {
    return this.gymsActive.filter(dep => (dep === gymId)).length > 0;
  }

  private activeGymsChanged(gymId: number) {
    if (this.isChecked(gymId)) {
      this.gymsActive.splice(this.gymsActive.indexOf(gymId), 1);
    } else {
      this.gymsActive.push(gymId);
    }
  }

}
