import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { VersionsService } from '../../../services/versions.service';
import { Application } from '../../../interfaces/application';
import { ApplicationsService } from '../../../services/applications.service';
import { ApplicationVersion } from '../../../interfaces/applicationversion';

import * as semver from 'semver';


@Component({
  selector: 'app-dependency-list',
  templateUrl: './dependency-list.component.html',
  styleUrls: ['./dependency-list.component.scss']
})
export class DependencyListComponent implements OnInit {
  private panelOpenState = false;
  applicationList: Application[];
  // private versionList: ApplicationVersion[];

  @Input() dependencies: ApplicationVersion[];
  @Input() dependencyType: number;
  @Input() title: string;

  constructor(private globalService: GlobalService,
    private versionsService: VersionsService,
    private applicationsService: ApplicationsService) { }

  ngOnInit() {
    this.applicationList = this.globalService.getApplications(this.dependencyType); // APPs:1 DLLs:2
  }


  private getApplicationVersions(index: number) {
    // if (this.applicationList[index].versions !== undefined || this.applicationList[index].versions.length !== 0) {
    if (true) {
      this.applicationsService.getApplication(this.applicationList[index].id).subscribe(
        result => {
          this.applicationList[index].versions = result.versions.sort( (x1: ApplicationVersion, x2: ApplicationVersion) => {
            return semver.compare(x1.version, x2.version);
          }).reverse();
        },
        error => {
          console.log('Error');
        }
      );
    }
  }

  private isChecked(versionId: number) {

    return this.dependencies.filter(dep => (dep.id === versionId)).length > 0;
  }

  private changeDependencies(applicationId: number, version: ApplicationVersion) {
    this.globalService.removeByKey(this.dependencies, {
      key: 'application',
      value: applicationId
    });
    if (version) {
      this.dependencies.push(version);
    }
  }

}
