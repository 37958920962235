import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { VersionsService } from '../../../services/versions.service';
import { Application } from '../../../interfaces/application';
import { ApplicationsService } from '../../../services/applications.service';
import { ApplicationVersion } from '../../../interfaces/applicationversion';

@Component({
  selector: 'app-version-parent-app-list',
  templateUrl: './version-parent-app-list.component.html',
  styleUrls: ['./version-parent-app-list.component.scss']
})
export class VersionParentAppListComponent implements OnInit {
  private applicationList: Application[];
  private applicationVersionList: ApplicationVersion[];
  private auxVersionList: ApplicationVersion[];

  @Input() dependencies: ApplicationVersion[];

  constructor(private globalService: GlobalService,
    private versionsService: VersionsService,
    private applicationsService: ApplicationsService) {

    this.applicationList = this.globalService.getApplications(1); // APP types (1)

    // this.versionsService.getVersions().subscribe(
    //   result => {
    //     this.versionList = result;
    //   },
    //   error => {
    //     console.log('Error: getVersions');
    //   }
    // );
  }

  ngOnInit() { }


  private getApplicationVersions(index: number) {
    // if (this.applicationList[index].versions !== undefined || this.applicationList[index].versions.length !== 0) {
    if (true) {
      this.applicationsService.getApplication(this.applicationList[index].id).subscribe(
        result => {
          this.applicationList[index].versions = result.versions;
        },
        error => {
          console.log('Error');
        }
      );
    }
  }

  private isChecked(versionId: number) {

    return this.dependencies.filter(dep => (dep.id === versionId)).length > 0;
  }

  private changeDependencies(applicationId: number, version: ApplicationVersion) {
    this.removeByKey(this.dependencies, {
      key: 'application',
      value: applicationId
    });
    if (version) {
      this.dependencies.push(version);
    }
  }

  private removeByKey(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }


}
