import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'appname'
})
export class AppNamePipe implements PipeTransform {

  constructor(private globalService: GlobalService) { }

  transform(id: number): string {
    return this.globalService.getApplications().filter(app => (app.id === id))[0].name;
  }

}
