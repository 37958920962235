import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { SubscriptionsGymItemExt, SubscriptionsOrder, SubscriptionAlert } from '../../../interfaces/subscription';
import { Gym } from '../../../interfaces/gym';
import { GymProgram } from '../../../interfaces/gymprogram';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class SubscriptionsProgramComponent implements OnInit {

  public creatorPrograms: GymProgram[];
  public program: GymProgram;
  public programGymList: SubscriptionsGymItemExt[];
  public alerts: SubscriptionAlert;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) {

    this.creatorPrograms = this.route.snapshot.data.creatorPrograms;
    this.program = this.creatorPrograms.find(obj => obj.id === Number(this.route.snapshot.paramMap.get('id')));
    this.programGymList = this.route.snapshot.data.programGymList;
    this.alerts = this.route.snapshot.data.alerts;    
  }

  ngOnInit() {
    this.globalService.changePageTitle([this.translate.instant('SUBSCRIPTIONS.TITLE') + ' - ' + this.translate.instant('SUBSCRIPTIONS.PROGRAM') + ' ' + this.program.name]);
  }

}
