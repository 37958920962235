import { BodyChallenge, ChallengeSendMeasurement } from './../interfaces/bodychallenge';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from '../../assets/constants';

@Injectable({
  providedIn: 'root'
})
export class BodyChallengesService {

  private urlGetBodyChallengeList =  constants.urlAPI + constants.endpoints.bodychallenges.getList;
  private urlGetBodyChallengeForm =  constants.urlAPI + constants.endpoints.bodychallenges.getForm;
  private urlGetBodyChallengeDetail =  constants.urlAPI + constants.endpoints.bodychallenges.getDetail;
  private urlPutBodyChallengeDetail =  constants.urlAPI + constants.endpoints.bodychallenges.putDetail;
  private urlPostBodyChallengeDetail =  constants.urlAPI + constants.endpoints.bodychallenges.postDetail;

  private urlGetBodyChallengeAvailableTypes =  constants.urlAPI + constants.endpoints.bodychallenges.getAvailableTypes;
  private urlGetBodyChallengeAvailableUnits =  constants.urlAPI + constants.endpoints.bodychallenges.getAvailableUnits;
  
  private urlGetBodyChallengeParameterList =  constants.urlAPI + constants.endpoints.bodychallenges.getParameterList;
  private urlGetBodyChallengeGymList =  constants.urlAPI + constants.endpoints.bodychallenges.getGymList;
  private urlGetBodyChallengeUserList =  constants.urlAPI + constants.endpoints.bodychallenges.getUserList;
  private urlGetSendToPramacloud =  constants.urlAPI + constants.endpoints.bodychallenges.getSendToPramacloud;
  
  private urlPostMeasurement =  constants.urlAPI + constants.endpoints.bodychallenges.postMeasurement;
  private urlPutMeasurement =  constants.urlAPI + constants.endpoints.bodychallenges.putMeasurement;

  constructor(public http: HttpClient) { }

  public getBodyChallengeList(): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeList);
  }
  public getBodyChallengeForm(id): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeForm.replace(':id', id));
  }
  public getBodyChallengeDetail(id): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeDetail.replace(':id', id));
  }
  public getBodyChallengeAvailableTypes(): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeAvailableTypes);
  }
  public getBodyChallengeAvailableUnits(): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeAvailableUnits);
  }
  public getBodyChallengeParameterList(): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeParameterList);
  }
  public getBodyChallengeGymList(): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeGymList);
  }
  public getBodyChallengeUserList(): Observable<any> {
    return this.http.get(this.urlGetBodyChallengeUserList);
  }

  public putBodyChallenge(bodyChallenge: BodyChallenge): Observable<any> {
    const data = bodyChallenge;
    return this.http.put(this.urlPutBodyChallengeDetail, data);    
  }

  public postBodyChallenge(bodyChallenge: BodyChallenge): Observable<any> {
    const data = bodyChallenge;
    return this.http.post(this.urlPostBodyChallengeDetail, data);
  }

  public postMeasurement(bodyChallengeId: number): Observable<any> {
    const data = null;
    return this.http.post(this.urlPostMeasurement.replace(':id', bodyChallengeId.toString()), data);    
  }
  public putMeasurement(measurement: ChallengeSendMeasurement): Observable<any> {
    const data = measurement;
    return this.http.put(this.urlPutMeasurement, data);    
  }

  public getSendToPramacloud(): Observable<any> {
    return this.http.get(this.urlGetSendToPramacloud);
  }

  
  
  
}
