import { AlertsService } from './../../../services/alerts.service';
import { BodyChallengesService } from './../../../services/body-challenges.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './../../../services/global.service';
import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { BodyChallenge } from './../../../interfaces/bodychallenge';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { constants } from '../../../../assets/constants';

@Component({
  selector: 'app-body-challenge-list',
  templateUrl: './body-challenge-list.component.html',
  styleUrls: ['./body-challenge-list.component.scss']
})
export class BodyChallengeListComponent implements OnInit {

  bodyChallengeList: BodyChallenge[];

  canCreate: boolean;
  canFill: boolean;
  canConfig: boolean;

  displayedColumns = ['name', 'initDate', 'finishDate', 'status', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,   
    private alertsService: AlertsService, 
    private bodyChallengesService: BodyChallengesService,
    private authService: AuthService) {

    this.bodyChallengeList = this.route.snapshot.data.bodyChallengeList;
    console.log(this.bodyChallengeList);

    this.translate.get('BODY_CHALLENGES.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.management_body_challenge_editor);
    this.canFill = this.authService.isInRole(constants.roles.management_body_challenge_editor);
    this.canConfig = this.authService.isInRole(constants.roles.management_body_challenge_editor);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.bodyChallengeList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  public sendToPramacloud(){
    this.bodyChallengesService.getSendToPramacloud().subscribe(
      result => {
        this.translate.get('BODY_CHALLENGES.SENT_TO_PRAMACLOUD').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  

}
