import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Chain } from '../interfaces/chain';
import { constants } from '../../assets/constants';

@Injectable()
export class ChainsService {

  private urlGetChains = constants.urlAPI + constants.endpoints.chains.get;
  private urlPutChain =  constants.urlAPI + constants.endpoints.chain.put;
  private urlPostChain =  constants.urlAPI + constants.endpoints.chain.post;
  private urlDeleteChain =  constants.urlAPI + constants.endpoints.chain.delete;

  constructor(public http: HttpClient) { }

  public getChains(): Observable<any> {
    return this.http.get(this.urlGetChains);
  }

  public putChain(chain: Chain): Observable<any> {
    const data = chain;
    return this.http.put(this.urlPutChain, data);
  }

  public postChain(chain: Chain): Observable<any> {
    const data = chain;
    return this.http.post(this.urlPostChain, data);
  }

  public deleteChain(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteChain + id);
  }

}
