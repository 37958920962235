import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { Gym } from '../../../interfaces/gym';
import { ApplicationMini, Application } from '../../../interfaces/application';
import { ApplicationVersionGym, ApplicationVersion } from '../../../interfaces/applicationversion';

@Component({
  selector: 'app-version-gyms',
  templateUrl: './version-gyms.component.html',
  styleUrls: ['./version-gyms.component.scss']
})
export class VersionsManagerGymVersionsComponent implements OnInit {

  public versionId: number;
  public versionGymList: ApplicationVersionGym[];
  public versionList: ApplicationVersion[];
  public applicationList: Application[];

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) {

    this.versionId = Number(this.route.snapshot.paramMap.get('id'));
    this.versionGymList = this.route.snapshot.data.versionGymList;
    this.versionList = this.route.snapshot.data.versionList;
    this.applicationList = this.route.snapshot.data.applicationList;
  }

  ngOnInit() {
    // Averiguamos nombre de App y Version
    const version = this.versionList.find(x => x.id === this.versionId);
    const application = this.applicationList.find(x => x.id === version.application);
    this.translate.get('VERSIONS.VERSIONS').subscribe((translation) => {
      this.globalService.changePageTitle([translation + ' - ' + application.name + ' - ' + version.version]);
    });
  }

}
