import { Injectable } from '@angular/core';
import { constants } from '../../assets/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  private urlGetGyms = constants.urlAPI + constants.endpoints.subscriptions.getGyms;
  private urlGetPrograms = constants.urlAPI + constants.endpoints.subscriptions.getPrograms;
  private urlGetPacks = constants.urlAPI + constants.endpoints.subscriptions.getPacks;
  private urlGetAlerts = constants.urlAPI + constants.endpoints.subscriptions.getAlerts;

  private urlGetGymPrograms = constants.urlAPI + constants.endpoints.subscriptions.getGymPrograms;
  private urlGetGymPacks = constants.urlAPI + constants.endpoints.subscriptions.getGymPackages;
  private urlGetGymPro = constants.urlAPI + constants.endpoints.subscriptions.getGymPro;
  private urlGetGymBootiq = constants.urlAPI + constants.endpoints.subscriptions.getGymBootiq;
  private urlGetGymOrders = constants.urlAPI + constants.endpoints.subscriptions.getGymOrders;
  // private urlGetGymOrderAttachment = constants.urlAPI + constants.endpoints.subscriptions.getGymOrderAttachment;
  private urlPostGymOrder = constants.urlAPI + constants.endpoints.subscriptions.postGymOrder;
  private urlPutGymSubscriptionItem = constants.urlAPI + constants.endpoints.subscriptions.putGymSubscriptionItem;
  private urlPutGymOrder = constants.urlAPI + constants.endpoints.subscriptions.putGymOrder;
  private urlDeleteGymOrder = constants.urlAPI + constants.endpoints.subscriptions.deleteGymOrder;
  private urlGetProgramGyms = constants.urlAPI + constants.endpoints.subscriptions.getProgramGyms;
  private urlPutProgramSubscriptionItem = constants.urlAPI + constants.endpoints.subscriptions.putProgramSubscriptionItem;

  constructor(public http: HttpClient) { }

  public getGyms(): Observable<any> {
    return this.http.get(this.urlGetGyms);
  }
  public getPrograms(): Observable<any> {
    return this.http.get(this.urlGetPrograms);
  }
  public getPacks(): Observable<any> {
    return this.http.get(this.urlGetPacks);
  }
  public getAlerts(): Observable<any> {
    return this.http.get(this.urlGetAlerts);
  }

  public getGymPrograms(gymId): Observable<any> {
    return this.http.get(this.urlGetGymPrograms.replace(':id', gymId));
  }
  public getGymPacks(gymId): Observable<any> {
    return this.http.get(this.urlGetGymPacks.replace(':id', gymId));
  }
  public getGymPro(gymId): Observable<any> {
    return this.http.get(this.urlGetGymPro.replace(':id', gymId));
  }
  public getGymBootiq(gymId): Observable<any> {
    return this.http.get(this.urlGetGymBootiq.replace(':id', gymId));
  }
  public getGymOrders(gymId): Observable<any> {
    return this.http.get(this.urlGetGymOrders.replace(':id', gymId));
  }
  // public getGymOrderAttachment(orderId): Observable<any> {
  //   return this.http.get(this.urlGetGymOrderAttachment.replace(':id', orderId));
  // }
  public postGymOrder(order): Observable<any> {
    const data = order;
    return this.http.post(this.urlPostGymOrder, data);
  }
  public putGymSubscriptionItem(item, gymId): Observable<any> {
    const data = item;
    return this.http.put(this.urlPutGymSubscriptionItem.replace(':id', gymId), data);
  }
  public putGymOrder(orders): Observable<any> {
    const data = orders;
    return this.http.put(this.urlPutGymOrder, data);
  }
  public deleteGymOrder(order): Observable<any> {
    return this.http.delete(this.urlDeleteGymOrder.replace(':id', order.id.toString()));
  }

  public getProgramGyms(orderId): Observable<any> {
    return this.http.get(this.urlGetProgramGyms.replace(':id', orderId));
  }
  public putProgramSubscriptionItem(item, programId): Observable<any> {
    const data = item;
    return this.http.put(this.urlPutProgramSubscriptionItem.replace(':id', programId), data);
  }

}
