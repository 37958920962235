import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { SubscriptionsGymItemExt, SubscriptionsOrder } from '../../../interfaces/subscription';
import { Gym } from '../../../interfaces/gym';

@Component({
  selector: 'app-gym',
  templateUrl: './gym.component.html',
  styleUrls: ['./gym.component.scss']
})
export class SubscriptionsGymComponent implements OnInit {

  public gym: Gym;
  public gymProgramList: SubscriptionsGymItemExt[];
  public gymPackList: SubscriptionsGymItemExt[];
  public gymProList: SubscriptionsGymItemExt[];
  public gymBootiqList: SubscriptionsGymItemExt[];
  public gymOrderList: SubscriptionsOrder[];
  // gymList: SubscriptionGym[];
  // packList: SubscriptionItem[];
  // alerts: SubscriptionAlert;

  @Input() embedded: boolean;
  @Input() inputGym: Gym;
  @Input() inputGymProgramList: SubscriptionsGymItemExt[];
  @Input() inputGymPackList: SubscriptionsGymItemExt[];
  @Input() inputGymProList: SubscriptionsGymItemExt[];
  @Input() inputGymBootiqList: SubscriptionsGymItemExt[];
  @Input() inputGymOrderList: SubscriptionsOrder[];

  //embedded: boolean = false;


  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) {

      if (this.embedded) {
        this.gym = this.inputGym;
        this.gymProgramList = this.inputGymProgramList;
        this.gymPackList = this.inputGymPackList;
        this.gymProList = this.inputGymProList;
        this.gymBootiqList = this.inputGymBootiqList;
        this.gymOrderList = this.inputGymOrderList;
      } else {
        this.gym = this.route.snapshot.data.gym;
        this.gymProgramList = this.route.snapshot.data.gymProgramList;
        this.gymPackList = this.route.snapshot.data.gymPackList;
        this.gymProList = this.route.snapshot.data.gymProList;
        this.gymBootiqList = this.route.snapshot.data.gymBootiqList;
        this.gymOrderList = this.route.snapshot.data.gymOrderList;
      }
      

    // this.gymList = this.route.snapshot.data.gymList;
    // this.packList = this.route.snapshot.data.packList;
    // this.alerts = this.route.snapshot.data.alerts;    
  }

  ngOnInit() {
    this.translate.get('SUBSCRIPTIONS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation + ' - ' + this.gym.name]);
    });
  }

}
