const environment = ''; // 'dev' / 'test' / ''

export const constants = {

  appVersion: '1.7.9',
  appId: 2,

  //localUrlAPI: 'http://localhost:4200',
  //urlAPI: 'http://localhost:58680',     // 'https://' + environment + 'api.pramacloud.com',
  urlAPI: 'https://' + environment + 'api.pramacloud.com',
  urlAPP: 'https://' + environment + 'manager.pramacloud.com',
  
  // qa
  //urlAPI: 'http://qaapi.pramacloud.com',
  //urlAPP: 'http://qamanager.pramacloud.com',


  endpoints: {
    application: {
      get: '/management/application/:id',
      post: '/management/application',
      put: '/management/application'
    },
    applications: {
      get: '/management/application'
    },
    applicationVersion: {
      get: '/management/application/version/:id',
      post: '/management/application/version',
      put: '/management/application/version',
      delete: '/management/application/version/:id'
    },
    applicationVersions: {
      get: '/management/application/versions',
      getGymVersions: '/management/application/gym-versions/:id',
      getApplicationVersions: '/management/application/versions',
      getGymVersionsLog: '/management/application/gym-versions-logs/:gymId',
      putGymVersionsLog: '/management/application/gym-versions-logs',
      getVersionGymsLog: '/management/application/application-version-logs/:versionId',
      putVersionGymsLog: '/management/application/application-version-logs'
    },

    chain: {
      post: '/management/chain',
      put: '/management/chain',
      delete: '/management/chain/'
    },
    chains: {
      get: '/management/chain',
    },
    contact: {
      post: '/management/contact',
      put: '/management/contact',
      delete: '/management/contact/'
    },
    contacts: {
      get: '/management/contact'
    },
    login: {
      post: '/token/user'
    },
    media: {
      post: '/media/upload',
    },
    profiles: {
      get: '/management/profile'
    },
    profile: {
      post: '/management/profile',
      put: '/management/profile',
      delete: '/management/profile/:id'
    },
    kiosks: {
      get: '/management/kiosk'
    },
    refreshToken: {
      post: '/token/user/renew'
    },
    roles: {
      get: '/management/role'
    },
    scripts: {
      get: '/management/script',
    },
    script: {
      get: '/management/script/:id',
      post: '/management/script',
      put: '/management/script',
      delete: '/management/script/:id',
    },
    scriptStatus: {
      get: '/management/script/status'
    },
    trainingModes: {
      get: '/management/trainingmode'
    },
    moods: {
      get: '/creator/mood'
    },
    user: {
      get: '/management/user/:id',
      post: '/management/user',
      put: '/management/user',
      delete: '/management/user/:id'
    },
    users: {
      get: '/management/user',
    },
    userStatus: {
      get: '/management/user/status'
    },
    validateToken: {
      post: '/token/user/valid'
    },

    gyms: {
      get: '/management/gym',
      getRel: '/management/gym/appversion/:id',
      getStatus: '/management/gym/status',
      getTypes: '/management/gym/types',
      getFormats: '/management/gym/formats'
    },
    gym: {
      get: '/management/gym/:id',
      post: '/management/gym',
      put: '/management/gym',
      disable: '/management/script/:id1/gym/:id2',
      forceScript: '/management/script/:id1/force/:id2',
      getTimetable: '/management/calendar/:id',
      postTimetable: '/management/calendar/:id'
    },
    programs: {
      put: '/management/program',
      creatorGet: '/management/creatordtoprograms'
    },
    specificPrograms: {
      get: '/management/program/specific',
      getId: '/management/program/specific/:id',
      post: '/management/program/specific',
      put: '/management/program/specific'
    },
    sessions: {
      creatorGet: '/management/creatorsessions/:id'
    },
    stationTypes: {
      get: '/management/station'
    },
    executionModes: {
      get: '/creator/executionmode'
    },
    monitoringList: {
      get: '/management/monitoring'
    },
    monitoringDetail: {
      get: '/management/monitoring/:id'
    },
    subscriptions: {
      getGyms: '/management/subscription/gyms-list',
      getPrograms: '/management/subscription/programs-list',
      getPacks: '/management/subscription/packages-list',
      getAlerts: '/management/subscription/alert-active',
      getGymPrograms: '/management/subscription/gym-programs/:id',
      getGymPackages: '/management/subscription/gym-packages/:id',
      getGymPro: '/management/subscription/gym-pro/:id',
      getGymBootiq: '/management/subscription/gym-bootiq/:id',
      getGymOrders: '/management/subscription/gym-order/:id',
      getGymOrderAttachment: '/management/subscription/gym-order/attachment/:id',
      postGymOrder: '/management/subscription/gym-order',
      putGymSubscriptionItem: '/management/subscription/gym-subscription/:id',
      putGymOrder: '/management/subscription/gym-order',
      deleteGymOrder: '/management/subscription/gym-order/:id',
      getProgramGyms: '/management/subscription/subscription-gyms/:id',
      putProgramSubscriptionItem: '/management/subscription/subscription-gym/:id'
    },
    gymAlerts: {
      get: '/management/gym/alerts',
      put: '/management/gym/alerts',
      getApp: '/management/application/alert-active',
      putApp: '/management/application/alert',
      getSubs: '/management/subscription/alert-active',
      putSubs: '/management/subscription/alert'
    },
    bodychallenges: {
      getList: '/management/bodychallenges',
      getForm: '/management/bodychallenge/:id',
      getDetail: '/management/bodychallenge/:id',
      putDetail: '/management/bodychallenge',
      postDetail: '/management/bodychallenge',
      getAvailableTypes: '/management/bodychallenge/types',
      getAvailableUnits: '/management/bodychallenge/units',
      getParameterList: '/management/bodychallenge/parameters',
      getGymList: '/management/bodychallenge/gyms',
      getUserList: '/management/bodychallenge/users',
      postMeasurement: '/management/bodychallenge/measurement/:id',
      putMeasurement: '/management/bodychallenge/measurement/',
      getSendToPramacloud: '/management/bodychallenge/send-to-pramacloud'
    },
    languages: {
      get: "/common/langs"
    },
  },

  keySession: 'pmsession',
  keyRoles: 'pmroles',
  roles: {
    management_user: 1,
    application_create: 5,
    application_detail: 6,
    application_modify: 7,
    application_version_create: 8,
    application_version_detail: 9,
    application_version_list: 12,
    application_version_modify: 13,
    chain_create: 16,
    chain_delete: 17,
    chain_modify: 18,
    chains_list: 19,
    contact_create: 21,
    contact_delete: 22,
    contact_modify: 23,
    contacts_list: 24,
    gym_detail: 25,
    gym_create: 26,
      gym_update: 27,
    gym_list: 28,
    profile_create: 29,
    profile_delete: 30,
    profile_detail: 31,
    profile_modify: 32,
    script_create: 33,
    script_delete: 34,
    script_detail: 35,
    scripts_list: 36,
    script_modify: 37,
    user_detail: 38,
    user_create: 39,
    user_modify: 40,
    user_list: 41,
    contact_detail: 42,
    creator_user: 43,
    chain_detail: 44,
    program_list: 45,
    program_detail: 46,
    program_modify: 47,
    program_create: 48,
    timetable_edit: 49,
    kiosk_list: 50,
    profiles_list: 51,
    applications_list: 52,
    monitoring_list: 53,
    monitoring_detail: 54,
    gym_launcher: 55,
    subscription_list: 56,
    management_body_challenge_list: 61,
    management_body_challenge_editor: 62,
  },
  alertStatus: {
    OK: 0,
    PENDING: 1,
    PROGRESS: 2,
    CRITICAL: 3
  },
  programTypes: {
    Program: 0,
    Package: 1,
    OldProgram: 2,
    Pro: 3,
    Bootiq: 4
  },
  status: {
    inactive: 0,
    active: 1,
  },
  appsIds: {
    Prama: 1,
    Bootiq: 2
  },
  trainingModesIcons: {
    path: '/assets/img/trainingmodes/',
    icons: [
      {
        id: 1,
        iconFile: 'free-training.png'
      },
      {
        id: 2,
        iconFile: 'workout-together.png'
      },
      {
        id: 3,
        iconFile: 'group-training.png'
      },
      {
        id: 4,
        iconFile: 'manual-training.png'
      },
      {
        id: 5,
        iconFile: 'challenge.png'
      },
      {
        id: 6,
        iconFile: 'custom-group-training.png'
      },
      {
        id: 7,
        iconFile: 'timeline.png'
      }
    ]
  },
  stationIcons: {
    path: '/assets/img/stations/',
    icons: [
      {
        id: 1,
        iconFile: 'web.png'
      },
      {
        id: 2,
        iconFile: 'hoops.png'
      },
      {
        id: 3,
        iconFile: 'slides.png'
      },
      {
        id: 4,
        iconFile: 'squares.png'
      },
      {
        id: 5,
        iconFile: 'ladder.png'
      },
      {
        id: 6,
        iconFile: 'combo.png'
      },
      {
        id: 7,
        iconFile: 'sprint.png'
      },
      {
        id: 8,
        iconFile: 'floor.png'
      },
      {
        id: 9,
        iconFile: 'vertical.png'
      },
      {
        id: 10,
        iconFile: 'eighth.png'
      },
      {
        id: 12,
        iconFile: 'comboplus.png'
      }
    ]
  },
  moodIcons: {
    path: '/assets/img/moods/',
    offMood: 11,
    defaultMood: 21
  },
  urlTempFiles: '/public/temp/',
  urlSubscriptionOrderAttachments: '/public/subscriptions/:orderId/:file',
  logos: {
    urlChains: '/public/chains/',
    urlGyms: '/public/gyms/',
    urlPrograms: '/public/programs-img/',
    urlSpecificPrograms: '/public/specific-programs-img/',
    urlNoImage: '../assets/img/noimage.png'
  },
  gymParameters: {
    standByMood: 1,
    shutdownMood: 2,
    caloriesMultiplier: 3,
    primaryColor: 4,
    bootiqOEM: 5,
    showLetterStation: 6,
    maxBootiqStations: 7
  },
  parameterValueTypes: {
    booleanType: 1,
    stringType: 2,
    intType: 3,
    doubleType: 4,
    longType: 5,
    charType: 6,
    datetimeType: 7
  }
}
