import { Component, OnInit, Input } from '@angular/core';
import { TrainingMode } from '../../../interfaces/trainingmode';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-gym-training-modes-list',
  templateUrl: './gym-training-modes-list.component.html',
  styleUrls: ['./gym-training-modes-list.component.scss']
})
export class GymTrainingModesListComponent implements OnInit {

  @Input() trainingModes: TrainingMode[];
  @Input() trainingModeList: TrainingMode[];

  constructor(private globalService: GlobalService) {
  }

  ngOnInit() {
  }

  private isChecked(trainingMode: TrainingMode) {
    return this.trainingModes.filter(tm => (tm.id === trainingMode.id)).length > 0;
  }

  private trainingModesChanged(trainingMode: TrainingMode) {
    if (this.isChecked(trainingMode)) {
      this.globalService.removeByKey(this.trainingModes, {
        key: 'id',
        value: trainingMode.id
      });
    } else {
      this.trainingModes.push(trainingMode);
    }
  }
}
