import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ApplicationVersionGym } from '../../../interfaces/applicationversion';
import { Gym } from '../../../interfaces/gym';
import { VersionsService } from '../../../services/versions.service';


@Component({
  selector: 'app-application-version-list',
  templateUrl: './application-version-list.component.html',
  styleUrls: ['./application-version-list.component.scss']
})
export class ApplicationVersionListComponent implements OnInit {

  @Input() appId: number;

  private versionList: ApplicationVersionGym[];

  displayedColumns = ['version', 'description', 'gyms', 'applyFrom', 'beta', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  constructor(private versionsService: VersionsService) {
    this.versionList = [];
    this.dataSource = new MatTableDataSource(this.versionList);
  }

  ngOnInit() {
    this.versionsService.getApplicationVersions(this.appId).subscribe(
      result => {
         this.versionList = result;
         this.refreshTableData();
      });    
  }

  ngAfterViewInit() {
    this.refreshTableData();
  }

  private refreshTableData(){
    this.dataSource = new MatTableDataSource(this.versionList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
