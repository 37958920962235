import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { SpecificProgramSession } from '../../interfaces/SpecificProgramSession';
import { GymProgram } from '../../interfaces/gymprogram';
import { ProgramsService } from '../../services/programs.service';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, AfterViewInit {
  canCreate: boolean;
  canModify: boolean;

  programList: any;
  sessionList: SpecificProgramSession[];
  editMode: number;

  displayedColumns = ['id', 'name', 'defaultSession', 'actions'];
  dataSource = new MatTableDataSource;

  programSessions: number[];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private authService: AuthService,
    private programsService: ProgramsService,
    private alertsService: AlertsService) { }

  ngOnInit() {
    this.programList = this.route.snapshot.data.creatorPrograms;
    this.sessionList = this.route.snapshot.data.creatorSessions;

    this.translate.get('PROGRAMS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.programSessions = [];

    this.canModify = this.authService.isInRole(constants.roles.program_modify);
    this.canCreate = this.authService.isInRole(constants.roles.program_create);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.programList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  getGymName(id: number) {
    return this.route.snapshot.data.gymList.filter(gym => (gym.id === id))[0].name;
  }

  getSessionName(sessionId: number) {
    const session = this.sessionList.filter(ses => (ses.id === sessionId))[0];
    return session ? session.alias : '';
  }

  private openEditor(program: GymProgram) {
    this.updateProgramSessions(program);
    this.editMode = program.id;
    this.dataSource = new MatTableDataSource(this.programList);
  }

  private updateProgramSessions(program: GymProgram) {
    this.programSessions = [];
    for (const session of program.sessions) {
      this.programSessions.push(session.id);
    }
  }

  public saveProgram(program: GymProgram) {
    this.programsService.putProgram(program).subscribe(
      result => {
        this.translate.get('PROGRAMS.PROGRAM_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
        // this.resetEditForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }
}
