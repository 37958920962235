import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AlertsService } from '../../../../services/alerts.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Gym } from '../../../../interfaces/gym';
import { VersionsService } from '../../../../services/versions.service';
import { ApplicationGymVersion } from '../../../../interfaces/applicationversion';

@Component({
  selector: 'app-gym-versions-log',
  templateUrl: './gym-versions-log.component.html',
  styleUrls: ['./gym-versions-log.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GymVersionsLogComponent implements OnInit {

  @Input() gym: Gym;
  @Input() appId: number;

  public versionList: ApplicationGymVersion[];

  displayedColumns = ['version', 'since', 'alert', 'applyFrom', 'actions'];
  dataSource = new MatTableDataSource;
  expandedElement: ApplicationGymVersion | null;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public editMode: number;
  isDateError: Boolean = false;

  constructor(private versionsService: VersionsService, private alertsService: AlertsService){
    this.editMode = 0;    
    this.versionList = [];
  }

  ngOnInit() {
    this.versionsService.getGymVersionsLog(this.gym.id, this.appId).subscribe(
      result => {
         this.versionList = result;
         this.refreshTableData();
      });
  }

  ngAfterViewInit() {    
    this.refreshTableData();
  }

  private refreshTableData(){
    let currentId = 1;    
    this.versionList.forEach(x => {
      x.id = currentId;
      currentId++;
    });
    this.dataSource = new MatTableDataSource(this.versionList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  private editItem(item: any) {
    this.editMode = item.id;
  }

  private saveItem(item: any) {
    this.versionsService.putGymVersionsLog(item).subscribe(
      result => {
        this.alertsService.alertTrigger({ msg: 'Information update' })
      },
      error => {
        console.log('Error', error);
        this.alertsService.alertTrigger({ msg: 'Error saving data' })
      }
    );
    this.resetEditMode();
  }

  // private checkDateChange(item: any) {
  //   const dateFrom = new Date(item.activeFrom);
  //   const dateUntil = new Date(item.activeUntil);
  //   if (dateFrom && dateUntil) {
  //     if (dateFrom > dateUntil) {
  //       this.isDateError = true;
  //       return false;
  //     }
  //   }
  //   this.isDateError = false;
  //   return true;
  // }

  private resetEditMode() {
    this.editMode = 0;
    this.isDateError = false;
    this.expandedElement = null;
  }

  // public getStatus(statusId: number){
  //   switch(statusId){
  //     case 0: return 'PROGRAMS.STATUS_DISABLED'; break;
  //     case 1: return 'PROGRAMS.STATUS_ENABLED'; break;
  //     case 2: return 'PROGRAMS.STATUS_DEMO'; break;
  //   }
  // }

  public getValue(value: any){
    if(!value){
      return '-';      
    } else {
      return value;
    }
  }

  public isDate(dateToCheck: any){
    return Date.parse(dateToCheck);
  }

}
