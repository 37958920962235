import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { KioskMonitoring, Kiosk } from '../../interfaces/kiosk';
import { Gym } from '../../interfaces/gym';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-monitoring-detail',
  templateUrl: './monitoring-detail.component.html',
  styleUrls: ['./monitoring-detail.component.scss']
})
export class MonitoringDetailComponent implements OnInit {
  public kiosks: KioskMonitoring[];
  public gymInfo: Gym;
  public gymHrType: string;
  public gymStatus: string;
  public gymFloorType: string;
  public gymCapacity: number;
  public kioskComponents: any;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) { }


  ngOnInit() {
    const info = this.route.snapshot.data.info;
    if (info && info !== 'error') {
      this.setComponentData(info);

      this.kiosks = [];
      this.gymInfo.kiosks.forEach(k => {
        this.kiosks.push(this.createKioskInfo(k));
      });
    }
  }


  setComponentData(info) {
    this.gymInfo =  info.gymInfo;
    this.kioskComponents = info.allKioskComponents;
    this.gymHrType = this.globalService.hrTypeList.find(x => x.id === this.gymInfo.hrType).name;
    this.gymStatus = this.globalService.gymStatusList.find(x => x.id === this.gymInfo.status).name;
    this.gymFloorType = this.globalService.floorTypeList.find(x => x.id === this.gymInfo.floorType).name;
    const counts = {};
    this.gymInfo.stations.forEach(x => { counts[x.station] = (counts[x.station] || 0) + 1; });
    const stationAmount: number[] = Object.values(counts);
    this.gymCapacity = Math.min(...stationAmount);
  }


  createKioskInfo(k: Kiosk) {
    const nk: KioskMonitoring = {
      id: k.id,
      gymId: k.gymId,
      computer: k.computer,
      status: k.status,
      name: k.name,
      container: k.container,
      isMaster: k.isMaster,
      gymStationsIds: k.gymStationsIds,
      scriptStatus: k.scriptStatus,
      components: this.kioskComponents.filter(x => x.kioskID === k.id).sort( (c1, c2) => c2.componentType - c1.componentType),
      hasErrors: false,
      controlledStationNames: ''
    };

    nk.hasErrors = nk.components.some(x => x.status === constants.alertStatus.CRITICAL);
    const stationsArray = this.gymInfo.stations.filter(x => nk.gymStationsIds.includes(x.id)).map(x => x.station);
    const stationsUniq = Array.from(new Set(stationsArray)).sort((a, b) => a - b);
    const stationsAmount = [];
    stationsUniq.forEach(s => stationsAmount.push(`${this.globalService.stationTypesList.find( x => x.id === s).name} (${stationsArray.filter(ss => ss === s).length})`));
    nk.controlledStationNames = stationsAmount.join(', ');

    return nk;
  }

}
