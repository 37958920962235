import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { BodyChallengesService } from './../../../services/body-challenges.service';
import { GlobalService } from './../../../services/global.service';
import { AuthService } from './../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from './../../../services/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { BodyChallenge, Challenge, ChallengeGym, ChallengeParameter, ChallengeUser, ChallengeType } from './../../../interfaces/bodychallenge';
import { Component, OnInit, ViewChild } from '@angular/core';
import { constants } from '../../../../assets/constants';

@Component({
  selector: 'app-body-challenge-detail',
  templateUrl: './body-challenge-detail.component.html',
  styleUrls: ['./body-challenge-detail.component.scss']
})
export class BodyChallengeDetailComponent implements OnInit {
  canCreate: boolean;
  canModify: boolean;

  bodyChallenge: BodyChallenge = {
    id: 0,
    name: '',
    creationDate: '',
    initDate: '',
    finishDate: '',
    status: 0,
    challenges: [],
    gyms: []
  }
  gymList: ChallengeGym[];
  parameterList: ChallengeParameter[];
  userList: ChallengeUser[];
  availableTypes: ChallengeType[];

  availableGymUsers: ChallengeGym[];

  isNew: Boolean = false;

  editMode: number;
  editable: boolean = true;

  displayedColumns = ['type', 'parameters', 'users'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private route: ActivatedRoute,
    private alertsService: AlertsService,
    private translate: TranslateService,
    public globalService: GlobalService,
    private authService: AuthService,
    private bodyChallengesService: BodyChallengesService) {

    this.editMode = 0;
  }

  ngOnInit() {
    if (this.route.snapshot.data.bodyChallenge === 'new') {
      this.isNew = true;

      this.translate.get('BODY_CHALLENGES.TITLE').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });

      let creationDate = new Date();
      creationDate.setHours(creationDate.getHours() + 2);
      this.bodyChallenge.creationDate = creationDate.toISOString();

    } else {
      this.bodyChallenge = this.route.snapshot.data.bodyChallenge;
      this.globalService.changePageTitle([this.bodyChallenge.name, '(#' + this.bodyChallenge.id + ')']);
    }

    this.gymList = this.route.snapshot.data.gymList;
    this.parameterList = this.route.snapshot.data.parameterList;
    this.userList = this.route.snapshot.data.userList;
    this.availableTypes = this.route.snapshot.data.availableTypes;

    this.canCreate = this.authService.isInRole(constants.roles.management_body_challenge_editor);
    this.canModify = this.authService.isInRole(constants.roles.management_body_challenge_editor);

    this.editable = this.bodyChallenge.status === 0; // 0-Created, 1-Started, 2-Finished
    this.availableGymUsers = [];

    this.distributeGymUsers();
  }

  ngAfterViewInit() {
    this.updateData();
  }

  updateData() {
    this.dataSource = new MatTableDataSource(this.bodyChallenge.challenges);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  public distributeGymUsers() {
    this.availableGymUsers.length = 0;
    if (this.bodyChallenge.gyms) {
      this.bodyChallenge.gyms.forEach(gym => {
        let newGymUsers: ChallengeGym = {
          id: gym,
          name: this.gymList.find(g => g.id === gym).name,
          users: this.userList.filter(u => u.pramacloudGymId === gym)
        };
        this.availableGymUsers.push(newGymUsers);
      });
    }
  };

  public saveBodyChallenge() {
    this.bodyChallengesService.putBodyChallenge(this.bodyChallenge).subscribe(
      result => {
        this.translate.get('BODY_CHALLENGES.SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createBodyChallenge() {
    this.bodyChallengesService.postBodyChallenge(this.bodyChallenge).subscribe(
      result => {
        this.translate.get('BODY_CHALLENGES.CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public changeGymSelection() {
    this.distributeGymUsers();
    this.updateData();
  }

  public fixUTC(date: Date) {
    date.setHours(date.getHours() + 2);    
    return date;
  }
  public changeDate(dateItem) {
    dateItem = this.fixUTC(dateItem).toISOString();
  }

  onSubmit() {
    if (this.canCreate || this.canModify) {
      if (this.isNew) {
        this.createBodyChallenge();
      } else {
        this.saveBodyChallenge();
      }
    }
  }

  private openEditor(challenge: Challenge) {
    //this.updateChallengeParameters(challenge);
    this.editMode = challenge.id;
    this.dataSource = new MatTableDataSource(this.bodyChallenge.challenges);
  }

  // private updateChallengeParameters(challenge) {
  //   this.challenge = [];
  //   for (const role of profile.roles) {
  //     this.profileRoles.push(role.id);
  //   }
  // }

  // Aux function for comparison of combobox objects
  public compareId(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  public compareUsers(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.pramacloudId === val2.pramacloudId;
    } else {
      return false;
    }
  }

  public createNewChallenge() {
    let newChallenge: Challenge = {
      id: Math.floor(Math.random() * (-1 + -999999 + 1)) + -1,
      creationDate: new Date().toISOString(),
      challengeType: null,
      parameters: [],
      users: []
    }
    this.bodyChallenge.challenges.push(newChallenge);
    this.updateData();
  }
}