import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Status } from '../interfaces/status';

@Pipe({
  name: 'bodychallengestatus'
})
export class BodyChallengeStatusPipe implements PipeTransform {

  constructor(private globalService: GlobalService) { }

  transform(id: any): any {
    //// NO SE USA ESTE PIPE DE MOMENTO
    switch(id){
      case 0: return 'STATUS_CREATED';
      case 1: return 'STATUS_STARTED';
      case 2: return 'STATUS_FINISHED';
    }
    //this.globalService.getGymStatusList().filter(sta => (sta.id === id))[0].name;
  }

}
