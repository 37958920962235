import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SpecificProgramSession } from '../../../interfaces/specificprogramsession';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { GymProgram } from '../../../interfaces/gymprogram';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.scss']
})
export class SessionListComponent implements OnInit, AfterViewInit {

  @Input() sessions: SpecificProgramSession[];
  @Input() program: GymProgram;

  editMode: number = null;

  displayedColumns = ['select', 'alias', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private global: GlobalService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initSessionList();
    this.dataSource = new MatTableDataSource(this.sessions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;

  }

  // Check if total session list has the program sessions, and replace them (program.session into this.sessions)
  private initSessionList() {
    for (let i = 0; i < this.sessions.length; i++) {
      if (this.hasSession(this.sessions[i])) {
        this.sessions[i] = this.program.sessions.filter(ses => (ses.id === this.sessions[i].id))[0];
      }
    }
  }

  private editSession(session: SpecificProgramSession) {
    if (!this.isChecked(session)) {
      this.program.sessions.push(session);
    }
    this.editMode = session.id;
    this.dataSource = new MatTableDataSource(this.sessions);
  }

  private saveSession(session: SpecificProgramSession) {
    const index = this.program.sessions.findIndex(ses => (ses.id === session.id));
    this.program.sessions[index] = session;
    this.editMode = 0;
    this.dataSource = new MatTableDataSource(this.sessions);
  }

  private isChecked(session: SpecificProgramSession) {
    return this.program.sessions.filter(ses => (ses.id === session.id)).length > 0;
  }
  private hasSession(session: SpecificProgramSession) {
    return this.program.sessions.filter(ses => (ses.id === session.id)).length > 0;
  }

  private getAlias(session: SpecificProgramSession) {
    return this.program.sessions.filter(ses => (ses.id === session.id))[0].alias;
  }

  private toggleSession(session: SpecificProgramSession) {
    if (!this.isChecked(session)) {
      this.program.sessions.push(session);
    } else {
      this.global.removeByKey(this.program.sessions, { key: 'id', value: session.id });
    }
  }

}
