import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { Script } from '../../interfaces/script';
import { ScriptsService } from '../../services/scripts.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-modal-remove-script',
    templateUrl: 'modal-remove-script.html',
})
export class ModalRemoveScriptComponent {

    script: Script;
    scripts: Script[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveScriptComponent>,
        private globalService: GlobalService,
        private scriptsService: ScriptsService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {

        this.script = data.script;
        this.scripts = data.scripts;
    }

    removeScript(script: Script) {
        this.deleteScript(script);
        this.dialogRef.close();
    }

    modalRemoveScriptCancel() {
        this.dialogRef.close();
    }

    public deleteScript(script?: Script) {
        const scriptToDelete = script ? script : this.script;

        this.scriptsService.deleteScript(scriptToDelete.id).subscribe(
            result => {
                this.router.navigate(['/scripts']);
                this.translate.get('SCRIPTS.SCRIPT_DELETED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ type: 'success', msg: translation });
                });
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

