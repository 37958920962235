import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-specific-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class SpecificProgramsComponent implements OnInit, AfterViewInit {
  canCreate: boolean;
  canModify: boolean;

  programList: any;

  displayedColumns = ['id', 'name', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private authService: AuthService) { }

  ngOnInit() {
    this.programList = this.route.snapshot.data.programList;

    this.translate.get('SPECIFIC_PROGRAMS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canModify = this.authService.isInRole(constants.roles.program_modify);
    this.canCreate = this.authService.isInRole(constants.roles.program_create);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.programList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  getGymName(id: number) {
    return this.route.snapshot.data.gymList.filter(gym => (gym.id === id))[0].name;
  }
}
