import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';

import { ContactsService } from '../../services/contacts.service';
import { Contact } from '../../interfaces/contact';
import { AlertsService } from '../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { MatDialog } from '@angular/material';
import { ModalRemoveContactComponent } from './modal-remove-contact.component';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;

  contact: Contact = {
    id: null,
    name: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    city: '',
    zip: '',
    country: '',
    gender: '',
    role: ''
  };
  contactList: Contact[];

  isNew: Boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private contactsService: ContactsService,
    private alertsService: AlertsService,

    private translate: TranslateService,
    public globalService: GlobalService,
    private authService: AuthService,
    public dialog: MatDialog) { }

  ngOnInit() {

    this.contactList = this.route.snapshot.data.contactList;
    this.route.params.subscribe(params => {
      if (params.id !== 'new') {
        this.contact = this.contactList.find(obj => obj.id === Number(params.id));
        this.globalService.changePageTitle([this.contact.name]);
      } else {
        this.isNew = true;
        this.translate.get('CONTACTS.TITLE_NEW').subscribe((translation) => {
          this.globalService.changePageTitle([translation]);
        });
      }
    });

    this.canCreate = this.authService.isInRole(constants.roles.contact_create);
    this.canModify = this.authService.isInRole(constants.roles.contact_modify);
    this.canDelete = this.authService.isInRole(constants.roles.contact_delete);
  }

  public saveContact() {
    this.contactsService.putContact(this.contact).subscribe(
      result => {
        this.translate.get('CONTACTS.CONTACT_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createContact() {
    this.contact.id = 0;
    this.contactsService.postContact(this.contact).subscribe(
      result => {
        this.translate.get('CONTACTS.CONTACT_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation });
        });
        this.router.navigate(['/contacts']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public deleteContact() {
    this.contactsService.deleteContact(this.contact.id).subscribe(
      result => {
        this.router.navigate(['/contacts']);
        this.translate.get('CONTACTS.CONTACT_DELETED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private modalRemoveContactOpen(contact: Contact) {
    this.dialog.open(ModalRemoveContactComponent, {
      data: {
        contact: contact,
        contacts: this.contactList
      }
    });
  }

  onSubmit() {
    if (this.isNew) {
      this.createContact();
    } else {
      this.saveContact();
    }
  }

}
