import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Gym } from '../interfaces/gym';
import { Script } from '../interfaces/script';
import { constants } from '../../assets/constants';
import { Kiosk } from '../interfaces/kiosk';
import { GymTimetable } from '../interfaces/gymtimetable';

@Injectable()
export class GymsService {

  private urlGetGyms = constants.urlAPI + constants.endpoints.gyms.get;
  private urlGetGymsRel = constants.urlAPI + constants.endpoints.gyms.getRel;

  private urlGetGym = constants.urlAPI + constants.endpoints.gym.get;
  // private urlGetGym = 'http://localhost:4200/management/gym/:id';
  private urlGetGymTimetable = constants.urlAPI + constants.endpoints.gym.getTimetable;
  private urlPostGymTimetable = constants.urlAPI + constants.endpoints.gym.postTimetable;

  private urlPutGym = constants.urlAPI + constants.endpoints.gym.put;
  private urlPostGym = constants.urlAPI + constants.endpoints.gym.post;
  private urlGetGymStatus = constants.urlAPI + constants.endpoints.gyms.getStatus;
  private urlGetGymTypes = constants.urlAPI + constants.endpoints.gyms.getTypes;
  private urlGetGymFormats = constants.urlAPI + constants.endpoints.gyms.getFormats;
  private urlDisableGym = constants.urlAPI + constants.endpoints.gym.disable;
  private urlForceScript = constants.urlAPI + constants.endpoints.gym.forceScript;

  constructor(public http: HttpClient) { }

  public getGyms(): Observable<any> {
    return this.http.get(this.urlGetGyms);
  }

  public getGymsRel(applicationId): Observable<any> {
    return this.http.get(this.urlGetGymsRel.replace(':id', applicationId));
  }

  public getGym(id): Observable<any> {
    return this.http.get(this.urlGetGym.replace(':id', id));
  }

  public putGym(gym: Gym): Observable<any> {
    const data = gym;
    return this.http.put(this.urlPutGym, data);
  }

  public postGym(gym: Gym): Observable<any> {
    const data = gym;
    return this.http.post(this.urlPostGym, data);
  }

  public getGymStatus(): Observable<any> {
    return this.http.get(this.urlGetGymStatus);
  }

  public getGymTypes(): Observable<any> {
    return this.http.get(this.urlGetGymTypes);
  }

  public getGymCategories(): Observable<any> {
    return this.http.get(this.urlGetGymFormats);
  }

  public disableGym(script: Script, gym: Gym): Observable<any> {
    return this.http.delete(this.urlDisableGym.replace(':id1', script.id.toString()).replace(':id2', gym.id.toString()));
  }

  public disableGyms(script: Script): Observable<any> {
    return this.http.delete(this.urlDisableGym.replace(':id1', script.id.toString()).replace(':id2', '0'));
  }

  public forceScript(script: Script, kiosk: Kiosk): Observable<any> {
    return this.http.get(this.urlForceScript.replace(':id1', script.id.toString()).replace(':id2', kiosk.id.toString()));
  }

  public getGymTimetable(gym: any): Observable<any> {
    return this.http.get(this.urlGetGymTimetable.replace(':id', gym.toString()));
  }
  public postGymTimetable(timetable: GymTimetable[], gym: number): Observable<any> {
    const data = timetable;
    return this.http.post(this.urlPostGymTimetable.replace(':id', gym.toString()), data);
  }

}
