import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Status } from '../interfaces/status';

@Pipe({
  name: 'userstatus'
})
export class UserStatusPipe implements PipeTransform {

  constructor(private globalService: GlobalService) { }

  transform(id: any): any {
    return this.globalService.getUserStatusList().filter(sta => (sta.id === id))[0].name;
  }

}
