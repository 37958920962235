import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Profile } from '../interfaces/profile';
import { constants } from '../../assets/constants';

@Injectable()
export class ProfilesService {

  private urlGetProfiles = constants.urlAPI + constants.endpoints.profiles.get;
  private urlPutProfile = constants.urlAPI + constants.endpoints.profile.put;
  private urlPostProfile = constants.urlAPI + constants.endpoints.profile.post;
  private urlDeleteProfile = constants.urlAPI + constants.endpoints.profile.delete;

  constructor(public http: HttpClient) { }

  public getProfiles(): Observable<any> {
    return this.http.get(this.urlGetProfiles);
  }

  public putProfile(profile: Profile): Observable<any> {
    const data = profile;
    return this.http.put(this.urlPutProfile, data);
  }

  public postProfile(profile: Profile): Observable<any> {
    const data = profile;
    return this.http.post(this.urlPostProfile, data);
  }

  public deleteProfile(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteProfile.replace(':id', id.toString()));
  }


}
