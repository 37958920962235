import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { constants } from '../../../assets/constants';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { ScriptsService } from '../../services/scripts.service';
import { AuthService } from '../../services/auth.service';
import { Script } from '../../interfaces/script';

@Component({
  selector: 'app-scripts',
  templateUrl: './scripts.component.html',
  styleUrls: ['./scripts.component.scss']
})
export class ScriptsComponent implements OnInit, AfterViewInit {
  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;

  scriptList: any; // Script[]

  displayedColumns = ['name', 'description', 'gymsActive', 'executionStatus', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private scriptsService: ScriptsService,
    private authService: AuthService) { }

  ngOnInit() {
    this.scriptList = this.route.snapshot.data.scriptList;

    this.translate.get('SCRIPTS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.chain_create);
    this.canModify = this.authService.isInRole(constants.roles.chain_modify);
    this.canDelete = this.authService.isInRole(constants.roles.chain_delete);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.scriptList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
