import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { Profile } from '../../interfaces/profile';
import { ProfilesService } from '../../services/profiles.service';
import { RolesService } from '../../services/roles.service';
import { Role } from '../../interfaces/role';
import { AlertsService } from '../../services/alerts.service';
import { GlobalService } from '../../services/global.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalRemoveProfileComponent } from './modal-remove-profile.component';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit, AfterViewInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;

  profileList: any; // Profile[]
  roleList: Role[];
  newProfile: Profile;
  errorCarga: Boolean = false;
  cargado: Boolean = false;
  page: number;
  editMode: number;
  newMode: Boolean;

  displayedColumns = ['name', 'description', 'roles', 'actions'];
  dataSource = new MatTableDataSource;

  profileRoles: number[];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


  constructor(private profilesService: ProfilesService,
    private rolesService: RolesService,
    private alertsService: AlertsService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private authService: AuthService,
    public dialog: MatDialog) {

    this.profileList = [];
    this.getProfiles();
    this.editMode = 0;
    this.newMode = false;
  }

  ngOnInit() {
    this.profileList = this.route.snapshot.data.profileList;
    this.roleList = this.route.snapshot.data.global.roleList;

    this.translate.get('PROFILES.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.profileRoles = [];

    this.canCreate = this.authService.isInRole(constants.roles.profile_create);
    this.canModify = this.authService.isInRole(constants.roles.profile_modify);
    this.canDelete = this.authService.isInRole(constants.roles.profile_delete);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.profileList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  private getProfiles() {
    this.profilesService.getProfiles().subscribe(
      result => {
        this.profileList = result;
        this.cargado = true;
      },
      error => {
        console.log('Error');
        this.errorCarga = true;
        this.cargado = false;
      }
    );
  }

  public saveProfile(profile: Profile) {
    this.prepareUserProfiles(profile);
    this.profilesService.putProfile(profile).subscribe(
      result => {
        this.translate.get('PROFILES.PROFILE_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
        this.resetEditForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createProfile(profile: Profile) {
    this.prepareUserProfiles(profile);
    this.profilesService.postProfile(profile).subscribe(
      result => {
        this.translate.get('PROFILES.PROFILE_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
        this.resetEditForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public deleteProfile(profileId: number) {
    this.profilesService.deleteProfile(profileId).subscribe(
      result => {
        this.translate.get('PROFILES.PROFILE_DELETED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
        this.resetEditForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private hasRole(profile: Profile, role: Role) {
    return profile.roles.find(i => i.id === role.id);
  }

  private toggleRole(profile: Profile, role: Role) {
    if (!this.hasRole(profile, role)) {
      profile.roles.push(role);
    } else {
      this.globalService.removeByKey(profile.roles, {
        key: 'id',
        value: role.id
      });
    }
  }

  private updateProfileRoles(profile) {
    this.profileRoles = [];
    for (const role of profile.roles) {
      this.profileRoles.push(role.id);
    }
  }

  private prepareUserProfiles(profile) {
    profile.roles = [];
    for (const id of this.profileRoles) {
      profile.roles.push(this.roleList.filter(role => (role.id === id))[0]);
    }
  }

  private openEditor(profile: Profile) {
    this.updateProfileRoles(profile);
    this.editMode = profile.id;
    if (this.newMode) {
      this.globalService.removeByKey(this.profileList, { key: 'id', value: 0});
      this.newMode = false;
    }
    this.dataSource = new MatTableDataSource(this.profileList);
  }

  private openCreateNew() {
    this.editMode = 0;
    if (this.newMode) {
      this.globalService.removeByKey(this.profileList, { key: 'id', value: 0});
    }
    this.newMode = true;
    this.newProfile = {
      id: 0,
      name: '',
      description: '',
      roles: []
    };
    this.updateProfileRoles(this.newProfile);
    this.profileList.unshift(this.newProfile);
    this.dataSource = new MatTableDataSource(this.profileList);
  }

  public resetEditForm(event) {
    event.stopPropagation();
    this.profilesService.getProfiles().subscribe(
      result => {
        this.profileList = result;
        this.editMode = 0;
        this.newMode = false;
        this.dataSource = new MatTableDataSource(this.profileList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.firstPage();
        this.dataSource.sort = this.sort;
      },
      error => {
        console.log('Error');
      }
    );
  }

  private modalRemoveProfileOpen(profile: Profile) {
    this.dialog.open(ModalRemoveProfileComponent, {
      data: {
        profile: profile,
        profiles: this.profileList
      }
    });
  }
}
