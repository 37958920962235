import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionGym, SubscriptionItem, SubscriptionAlert } from '../../interfaces/subscription';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  gymList: SubscriptionGym[];
  packList: SubscriptionItem[];
  programList: SubscriptionItem[];
  alerts: SubscriptionAlert;

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) {
    this.gymList = this.route.snapshot.data.gymList;
    this.packList = this.route.snapshot.data.packList;
    this.programList = this.route.snapshot.data.programList;
    this.alerts = this.route.snapshot.data.alerts;
  }

  ngOnInit() {
    this.translate.get('SUBSCRIPTIONS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });
  }

}
