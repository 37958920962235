import { Component, OnInit } from '@angular/core';
import { AlertsService } from '../../../services/alerts.service';
import {MatSnackBar} from '@angular/material';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  private msg: string;
  private button: string;
  private time: number;

  constructor(private alerts: AlertsService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.alerts.alert$.subscribe((params: any) => {
      this.msg = params.msg;
      this.button = params.button;
      this.time = params.time;

      this.snackBar.open(this.msg, this.button, {
        duration: this.time,
      });
    });
  }


}
