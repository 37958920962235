import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { VersionsService } from '../services/versions.service';

@Injectable()
export class VersionGymListResolver implements Resolve<Observable<string>> {

    constructor(private versionsService: VersionsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.versionsService.getVersionGymsLog(Number(route.paramMap.get('id'))).catch(() => {
            return Observable.of('error');
        });
    }

}

