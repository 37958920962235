import { Injectable } from '@angular/core';
import { SessionToken } from '../interfaces/sessiontoken';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LoginData } from '../interfaces/logindata';
import { constants } from '../../assets/constants';
import { GlobalService } from './global.service';
import { EventsService } from './events.service';
import 'rxjs/add/operator/mergeMap';

@Injectable()
export class AuthService {

  private urlLogin = constants.urlAPI + constants.endpoints.login.post;
  private urlValidateToken = constants.urlAPI + constants.endpoints.validateToken.post;
  private urlRefreshToken = constants.urlAPI + constants.endpoints.refreshToken.post;

  constructor(public http: HttpClient,
    private globalService: GlobalService,
    private eventsService: EventsService) { }


  public checkLogin(data: LoginData): Observable<any> {
    return this.http.post(this.urlLogin, data);
  }

  public validateOrRefreshToken(): Observable<any> {
    return this.validToken().mergeMap(resp => this.refreshToken(resp));
  }


  private validToken(): Observable<any> {
    const result = {
      valid: false,
      expired: false
    };
    const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
    if (!token || !token.token || token.token === '') {
      return Observable.of(result);
    } else {
      return this.http.post(this.urlValidateToken, token);
    }
  }


  private refreshToken(validationData): Observable<any> {
    if (!validationData.valid && validationData.expired) {
      const result = {
        valid: false,
        expired: false
      };
      const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
      const data = { memberID: token.user, refreshToken: token.refreshToken };
      return this.http.post(this.urlRefreshToken, data)
        .catch(error => {
          result.expired = true;
          return Observable.of(result);
        })
        .map((resp: any) => {
          if (resp && resp.session) {
            this.saveSession(resp);
            result.valid = true;
          } else {

          }
          return result;
        });
    } else {
      return Observable.of(validationData);
    }
  }


  public saveSession(session) {
    localStorage.clear();
    localStorage.setItem(constants.keySession, JSON.stringify(session.session));
    localStorage.setItem(constants.keyRoles, JSON.stringify(session.roles));
  }


  public isLogged() {
    const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
    const roles: number[] = JSON.parse(localStorage.getItem(constants.keyRoles));

    if (token && token != null && roles && roles != null) {
      return true;
    }
    return false;
  }


  public closeSession(): Observable<boolean> {
    localStorage.clear();

    this.globalService.changePageTitle(['']);
    this.eventsService.doEvent({key: 'logout', value: 'ok'});

    return Observable.of(true);
  }


  public isInRole(requiredRole: number) {

    // return true;

    const roles: number[] = JSON.parse(localStorage.getItem(constants.keyRoles));
    if (roles && roles !== null) {
      return roles.some(x => x === requiredRole);
    }

    return false;
  }

}
