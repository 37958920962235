import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { GymAlertsService } from '../services/gymalerts.service';

@Injectable()
export class GymAlertsResolver implements Resolve<Observable<string>> {

    constructor(private gymsAlertsService: GymAlertsService) { }

    resolve() {
        return this.gymsAlertsService.getGymAlerts().catch(() => {
            return Observable.of('error');
        });
    }

}

