import { Component, OnInit, Input } from '@angular/core';
import { GymsService } from '../../../services/gyms.service';
import { Gym } from '../../../interfaces/gym';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { ApplicationVersion } from '../../../interfaces/applicationversion';
import { ModalDisableGymComponent } from './modal-disable-gym.component';
import { Script } from '../../../interfaces/script';
import { ModalDisableGymsComponent } from './modal-disable-gyms.component';
import { ModalForceScriptComponent } from './modal-force-script.component';


@Component({
  selector: 'app-script-gym-list',
  templateUrl: './script-gym-list.component.html',
  styleUrls: ['./script-gym-list.component.scss']
})
export class ScriptGymListComponent implements OnInit {

  @Input() gymsActive;
  @Input() gymList;
  @Input() script;

  private dataSource;
  private displayedColumns = ['gymName', 'versionName'];
  // private gymList: Gym[];

  constructor(private gymsService: GymsService,
    public dialog: MatDialog) {
    // this.getGymList();
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.gymList);
    for (const gym of this.gymList) {
      gym.activelocal = gym.active;
    }
  }

  private getGymList() {
    this.gymsService.getGyms().subscribe(
      result => {
        this.gymList = result;
        this.dataSource = new MatTableDataSource(this.gymList);
      },
      error => {
        console.log('Error: getGyms');
      }
    );
  }

  private isChecked(gymId: number) {
    return this.gymsActive.filter(dep => (dep === gymId)).length > 0;
  }

  private modalDisableGym(gym: Gym) {
    this.dialog.open(ModalDisableGymComponent, {
      data: {
        gym: gym,
        script: this.script
      }
    });
  }

  public modalDisableGyms() {
    this.dialog.open(ModalDisableGymsComponent, {
      data: {
        script: this.script
      }
    });
  }

  private modalForceScript(kiosk) {
    this.dialog.open(ModalForceScriptComponent, {
      data: {
        script: this.script,
        kiosk: kiosk
      }
    });
  }

}
