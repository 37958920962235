import { Component, OnInit } from '@angular/core';
import { GymTimetable } from '../../interfaces/gymtimetable';
import { ActivatedRoute } from '@angular/router';
import { GymsService } from '../../services/gyms.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {

  public timetable: GymTimetable[];

  constructor(private route: ActivatedRoute,
    private gymsService: GymsService,
    private translate: TranslateService,
    private alertsService: AlertsService
    ) {
    //if (this.timetable === undefined) {
    this.timetable = this.route.snapshot.data.timetable;
    //this.timetable = [];
    //}
  }

  ngOnInit() {
  }

  public saveTimetable() {
    this.gymsService.postGymTimetable(this.timetable, 17).subscribe(
      result => {
        this.translate.get('GYMS.TIMETABLE_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

}
