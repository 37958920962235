import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../interfaces/contact';
import { constants } from '../../assets/constants';

@Injectable()
export class ContactsService {

  private urlGetContacts = constants.urlAPI + constants.endpoints.contacts.get;
  private urlPutContact = constants.urlAPI + constants.endpoints.contact.put;
  private urlPostContact = constants.urlAPI + constants.endpoints.contact.post;
  private urlDeleteContact = constants.urlAPI + constants.endpoints.contact.delete;

  constructor(public http: HttpClient) { }

  public getContacts(): Observable<any> {
    return this.http.get(this.urlGetContacts);
  }

  public putContact(contact: Contact): Observable<any> {
    const data = contact;
    return this.http.put(this.urlPutContact, data);
  }

  public postContact(contact: Contact): Observable<any> {
    const data = contact;
    return this.http.post(this.urlPostContact, data);
  }

  public deleteContact(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteContact + id);
  }

}
