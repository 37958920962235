import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { Script } from '../interfaces/script';

@Injectable()
export class ScriptsService {

  private urlGetScripts = constants.urlAPI + constants.endpoints.scripts.get;
  private urlGetScript = constants.urlAPI + constants.endpoints.script.get;
  private urlPutScript = constants.urlAPI + constants.endpoints.script.put;
  private urlPostScript = constants.urlAPI + constants.endpoints.script.post;
  private urlDeleteScript = constants.urlAPI + constants.endpoints.script.delete;
  private urlScriptStatus = constants.urlAPI + constants.endpoints.scriptStatus.get;

  constructor(public http: HttpClient) { }

  public getScriptStatus(): Observable<any> {
    return this.http.get(this.urlScriptStatus);
  }

  public getScripts(): Observable<any> {
    return this.http.get(this.urlGetScripts);
  }

  public getScript(id): Observable<any> {
    return this.http.get(this.urlGetScript.replace(':id', id));
  }

  public putScript(script: Script): Observable<any> {
    const data = script;
    return this.http.put(this.urlPutScript, data);
  }

  public postScript(script: Script): Observable<any> {
    const data = script;
    return this.http.post(this.urlPostScript, data);
  }

  public deleteScript(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteScript.replace(':id', id.toString()));
  }
}
