import { BodyChallengeAvailableUnitsResolver } from './resolvers/body-challenge-available-units.resolver';
import { BodyChallengeAvailableTypesResolver } from './resolvers/body-challenge-available-types.resolver';
import { BodyChallengeParameterListResolver } from './resolvers/body-challenge-parameter-list.resolver';
import { BodyChallengeUserListResolver } from './resolvers/body-challenge-user-list.resolver';
import { BodyChallengeGymListResolver } from './resolvers/body-challenge-gym-list.resolver';
import { BodyChallengeDetailResolver } from './resolvers/body-challenge-detail.resolver';
import { BodyChallengeFormResolver } from './resolvers/body-challenge-form.resolver';
import { BodyChallengeListResolver } from './resolvers/body-challenge-list.resolver';
import { BodyChallengeFormComponent } from './components/body-challenges/body-challenge-form/body-challenge-form.component';
import { BodyChallengeListComponent } from './components/body-challenges/body-challenge-list/body-challenge-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './components/users/users.component';
import { UserDetailComponent } from './components/users/user-detail.component';
import { GymsComponent } from './components/gyms/gyms.component';
import { GymDetailComponent } from './components/gyms/gym-detail.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { ApplicationDetailComponent } from './components/applications/application-detail.component';
import { VersionsComponent } from './components/versions/versions.component';
import { VersionDetailComponent } from './components/versions/version-detail.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactDetailComponent } from './components/contacts/contact-detail.component';
import { ChainsComponent } from './components/chains/chains.component';
import { ChainDetailComponent } from './components/chains/chain-detail.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { VersionListResolver } from './resolvers/version-list.resolver';
import { GymListResolver } from './resolvers/gym-list.resolver';
import { VersionDetailResolver } from './resolvers/version-detail.resolver';
import { ApplicationListResolver } from './resolvers/application-list.resolver';
import { GymDetailResolver } from './resolvers/gym-detail.resolver';
import { ApplicationDetailResolver } from './resolvers/application-detail.resolver';
import { UserListResolver } from './resolvers/user-list.resolver';
import { UserDetailResolver } from './resolvers/user-detail.resolver';
import { ContactListResolver } from './resolvers/contact-list.resolver';
import { ChainListResolver } from './resolvers/chain-list.resolver';
import { GlobalResolver } from './resolvers/global.resolver';
import { ApplicationVersionListResolver } from './resolvers/applicationVersion-list.resolver';
import { ProfileListResolver } from './resolvers/profile-list.resolver';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth-guard';
import { constants } from '../assets/constants';
import { HomeComponent } from './components/home/home.component';
import { ScriptsComponent } from './components/scripts/scripts.component';
import { ScriptDetailComponent } from './components/scripts/script-detail.component';
import { ScriptListResolver } from './resolvers/script-list.resolver';
import { ScriptDetailResolver } from './resolvers/script-detail.resolver';
import { GymVersionRelListResolver } from './resolvers/gym-version-rel-list.resolver';
import { GymScriptRelListResolver } from './resolvers/gym-script-rel-list.resolver';
import { ProgramListResolver } from './resolvers/program-list.resolver';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramDetailComponent } from './components/programs/program-detail.component';
import { SpecificProgramsComponent } from './components/specific-programs/programs.component';
import { SpecificProgramDetailComponent } from './components/specific-programs/program-detail.component';
import { ProgramDetailResolver } from './resolvers/program-detail.resolver';
import { CreatorProgramsListResolver } from './resolvers/creator-programs-list.resolver';
import { CreatorSessionsListResolver } from './resolvers/creator-sessions-list.resolver';
import { SpecificProgramDetailResolver } from './resolvers/specific-program-detail.resolver';
import { KiosksComponent } from './components/kiosks/kiosks.component';
import { KioskListResolver } from './resolvers/kiosk-list.resolver';
import { GymTimetableResolver } from './resolvers/gym-timetable.resolver';
import { TimetableComponent } from './components/gyms/timetable.component';
import { MonitoringListComponent } from './components/monitoring/monitoring-list.component';
import { MonitoringGymsResolver } from './resolvers/monitoring-gyms.resolver';
import { MonitoringDetailComponent } from './components/monitoring/monitoring-detail.component';
import { MonitoringDetailResolver } from './resolvers/monitoring-detail.resolver';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { SubscriptionsGymListResolver } from './resolvers/subscriptions-gym-list.resolver';
import { SubscriptionsPackListResolver } from './resolvers/subscriptions-pack-list.resolver';
import { SubscriptionsProgramListResolver } from './resolvers/subscriptions-program-list.resolver';
import { SubscriptionsAlertsResolver } from './resolvers/subscriptions-alerts.resolver';
import { SubscriptionsGymComponent } from './components/subscriptions/gym/gym.component';
import { SubscriptionsGymProgramListResolver } from './resolvers/subscriptions-gym-program-list.resolver';
import { SubscriptionsGymPackListResolver } from './resolvers/subscriptions-gym-pack-list.resolver';
import { SubscriptionsGymOrderListResolver } from './resolvers/subscriptions-gym-order-list.resolver';
import { SubscriptionsGymProListResolver } from './resolvers/subscriptions-gym-pro-list.resolver';
import { SubscriptionsGymBootiqListResolver } from './resolvers/subscriptions-gym-bootiq-list.resolver';
import { SubscriptionsProgramComponent } from './components/subscriptions/program/program.component';
import { SubscriptionsProgramGymListResolver } from './resolvers/subscriptions-program-gym-list.resolver';
import { SubscriptionsPackComponent } from './components/subscriptions/pack/pack.component';
import { SubscriptionsPackGymListResolver } from './resolvers/subscriptions-pack-gym-list.resolver';
import { VersionsManagerComponent } from './components/versions/versions-manager.component';
import { VersionsManagerGymComponent } from './components/versions/gym-versions/gym-versions.component';
import { VersionsManagerGymVersionsComponent } from './components/versions/version-gyms/version-gyms.component';
import { VersionGymListResolver } from './resolvers/version-gym-list.resolver';
import { AlertManagerComponent } from './components/alerts/alert-manager.component';
import { GymAlertsResolver } from './resolvers/gym-alerts.resolver';
import { AlertsSubscriptionResolver } from './resolvers/alerts-subcscription.resolver';
import { AlertsApplicationResolver } from './resolvers/alerts-application.resolver';
import { BodyChallengeDetailComponent } from './components/body-challenges/body-challenge-detail/body-challenge-detail.component';
import { LanguagesResolver } from './resolvers/languages-resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: { global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.management_user] }
  },
  {
    path: 'users',
    component: UsersComponent,
    resolve: { userList: UserListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.user_list] }
  },
  {
    path: 'user/:id',
    component: UserDetailComponent,
    resolve: { gymList: GymListResolver, user: UserDetailResolver, profileList: ProfileListResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.user_detail] }
  },
  {
    path: 'user/new',
    component: UserDetailComponent,
    resolve: { gymList: GymListResolver, user: UserDetailResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.user_create] }
  },
  {
    path: 'gyms',
    component: GymsComponent,
    resolve: { gymList: GymListResolver, chainList: ChainListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.gym_list] }
  },
  {
    path: 'gym/:id',
    component: GymDetailComponent,
    resolve: {
      gym: GymDetailResolver,
      global: GlobalResolver,
      applicationList: ApplicationListResolver,
      programList: ProgramListResolver,
      creatorPrograms: CreatorProgramsListResolver,
      timetable: GymTimetableResolver,
      gymProgramList: SubscriptionsGymProgramListResolver,
      gymPackList: SubscriptionsGymPackListResolver,
      gymOrderList: SubscriptionsGymOrderListResolver,
      gymProList: SubscriptionsGymProListResolver,
      gymBootiqList: SubscriptionsGymBootiqListResolver,
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.gym_detail] }
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    resolve: { applicationList: ApplicationListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.applications_list] }
  },
  {
    path: 'application/:id',
    component: ApplicationDetailComponent,
    resolve: { application: ApplicationDetailResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_detail] }
  },
  {
    path: 'applications/versions',
    component: VersionsComponent,
    resolve: { versionList: VersionListResolver, gymList: GymListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_version_list] }
  },
  {
    path: 'versions-manager',
    component: VersionsManagerComponent,
    resolve: { versionList: VersionListResolver, gymList: GymListResolver, applicationList: ApplicationListResolver,
      subsAlerts: AlertsSubscriptionResolver,
      appAlerts: AlertsApplicationResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_version_list] }
  },
  {
    path: 'versions-manager/gym/:id',
    component: VersionsManagerGymComponent,
    resolve: { applicationList: ApplicationListResolver, gym: GymDetailResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_version_list] }
  },
  {
    path: 'versions-manager/version/:id',
    component: VersionsManagerGymVersionsComponent,
    resolve: { applicationList: ApplicationListResolver, versionList: VersionListResolver, versionGymList: VersionGymListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_version_list] }
  },
  {
    path: 'applications/version/:id',
    component: VersionDetailComponent,
    resolve: { version_obj: VersionDetailResolver, applicationList: ApplicationListResolver, gymList: GymVersionRelListResolver, languages: LanguagesResolver, versionList: VersionListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_version_detail] }
  },
  {
    path: 'applications/version/new',
    component: VersionDetailComponent,
    resolve: { version_obj: VersionDetailResolver, applicationList: ApplicationListResolver, gymList: GymVersionRelListResolver, languages: LanguagesResolver,versionList: VersionListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.application_version_create] }
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    resolve: { contactList: ContactListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.contacts_list] }
  },
  {
    path: 'contact/:id',
    component: ContactDetailComponent,
    resolve: { contactList: ContactListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.contact_detail] }
  },
  {
    path: 'contact/new',
    component: ContactDetailComponent,
    canActivate: [AuthGuard],
    data: { role: [constants.roles.contact_create] }
  },
  {
    path: 'chains',
    component: ChainsComponent,
    resolve: { chainList: ChainListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.chains_list] }
  },
  {
    path: 'chain/:id',
    component: ChainDetailComponent,
    resolve: { chainList: ChainListResolver, gymList: GymListResolver, userList: UserListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.chain_detail] }
  },
  {
    path: 'chain/new',
    component: ChainDetailComponent,
    resolve: { chainList: ChainListResolver, gymList: GymListResolver, userList: UserListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.chain_create] }
  },
  {
    path: 'profiles',
    component: ProfilesComponent,
    resolve: { profileList: ProfileListResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.profiles_list] }
  },
  {
    path: 'scripts',
    component: ScriptsComponent,
    resolve: { scriptList: ScriptListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.scripts_list] }
  },
  {
    path: 'script/:id',
    component: ScriptDetailComponent,
    resolve: { script: ScriptDetailResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.script_detail] }
  },
  {
    path: 'programs',
    component: ProgramsComponent,
    resolve: { programList: ProgramListResolver, gymList: GymListResolver, creatorPrograms: CreatorProgramsListResolver, creatorSessions: CreatorSessionsListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.program_list] }
  },
  {
    path: 'program/:id',
    component: ProgramDetailComponent,
    resolve: { global: GlobalResolver, creatorPrograms: CreatorProgramsListResolver, creatorSessions: CreatorSessionsListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.program_detail] }
  },
  {
    path: 'specific-programs',
    component: SpecificProgramsComponent,
    resolve: { programList: ProgramListResolver, gymList: GymListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.program_list] }
  },
  {
    path: 'specific-program/:id',
    component: SpecificProgramDetailComponent,
    resolve: { program: SpecificProgramDetailResolver, global: GlobalResolver, creatorPrograms: CreatorProgramsListResolver, creatorSessions: CreatorSessionsListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.program_detail] }
  },
  {
    path: 'kiosks',
    component: KiosksComponent,
    resolve: { kioskList: KioskListResolver, global: GlobalResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.kiosk_list] }
  },
  {
    path: 'timetable',
    component: TimetableComponent,
    resolve: { timetable: GymTimetableResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.timetable_edit] }
  },
  {
    path: 'monitoring',
    component: MonitoringListComponent,
    resolve: { gyms: MonitoringGymsResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.monitoring_list] }
  },
  {
    path: 'monitoring/:id',
    component: MonitoringDetailComponent,
    resolve: { info: MonitoringDetailResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.monitoring_detail] }
  },
  {
    path: 'subscriptions',
    component: SubscriptionsComponent,
    resolve: { gymList: SubscriptionsGymListResolver,
      packList: SubscriptionsPackListResolver,
      programList: SubscriptionsProgramListResolver,
      alerts: SubscriptionsAlertsResolver},
    canActivate: [AuthGuard],
    data: { role: [constants.roles.subscription_list] }
  },
  {
    path: 'subscriptions/gym/:id',
    component: SubscriptionsGymComponent,
    resolve: { 
      gym: GymDetailResolver,
      gymProgramList: SubscriptionsGymProgramListResolver,
      gymPackList: SubscriptionsGymPackListResolver,
      gymOrderList: SubscriptionsGymOrderListResolver,
      gymProList: SubscriptionsGymProListResolver,
      gymBootiqList: SubscriptionsGymBootiqListResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.subscription_list] }
  },
  {
    path: 'subscriptions/program/:id',
    component: SubscriptionsProgramComponent,
    resolve: { 
      creatorPrograms: CreatorProgramsListResolver,
      programGymList: SubscriptionsProgramGymListResolver,
      alerts: SubscriptionsAlertsResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.subscription_list] }
  },
  {
    path: 'subscriptions/pack/:id',
    component: SubscriptionsPackComponent,
    resolve: { 
      creatorPrograms: CreatorProgramsListResolver,
      packGymList: SubscriptionsPackGymListResolver,
      alerts: SubscriptionsAlertsResolver
    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.subscription_list] },
  },
  {
    path: 'alerts',
    component: AlertManagerComponent,
    resolve: { gymAlerts: GymAlertsResolver,
    contactList: ContactListResolver,
    subsAlerts: AlertsSubscriptionResolver,
    appAlerts: AlertsApplicationResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.subscription_list] }
  },
  {
    path: 'body-challenges',
    component: BodyChallengeListComponent,
    resolve: { bodyChallengeList: BodyChallengeListResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.management_body_challenge_list] }
  },
  {
    path: 'body-challenge/detail/:id',
    component: BodyChallengeDetailComponent,
    resolve: { 
      bodyChallenge: BodyChallengeDetailResolver,
      gymList: BodyChallengeGymListResolver,
      userList: BodyChallengeUserListResolver,
      parameterList: BodyChallengeParameterListResolver,
      availableTypes: BodyChallengeAvailableTypesResolver,
      availableUnits: BodyChallengeAvailableUnitsResolver

    },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.management_body_challenge_list] }
  },
  {
    path: 'body-challenge/form/:id',
    component: BodyChallengeFormComponent,
    resolve: { bodyChallengeForm: BodyChallengeFormResolver },
    canActivate: [AuthGuard],
    data: { role: [constants.roles.management_body_challenge_editor] }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
