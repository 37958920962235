import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApplicationVersion } from '../../interfaces/applicationversion';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import * as semver from 'semver';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit, AfterViewInit {
  canCreate: boolean;
  canModify: boolean;

  versionList: any; // ApplicationVersion[]
  gymAmount: number;
  displayedColumns = ['application', 'version', 'gyms', 'updated', 'description', 'active', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private authService: AuthService) { }

  ngOnInit() {
    this.versionList = this.route.snapshot.data.versionList.sort((x1: ApplicationVersion, x2: ApplicationVersion) => semver.compare(x1.version, x2.version)).reverse();
    this.gymAmount = this.route.snapshot.data.gymList.length;

    this.translate.get('VERSIONS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.application_version_create);
    this.canModify = this.authService.isInRole(constants.roles.application_version_modify);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.versionList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }
}
