import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  canListUsers: boolean;
  canListGyms: boolean;
  canListApplications: boolean;
  canListVersions: boolean;
  canListContacts: boolean;
  canListChains: boolean;
  canListProfiles: boolean;
  canListScripts: boolean;
  canListPrograms: boolean;
  canListKiosks: boolean;
  canListTimetables: boolean;
  canListMonitoring: boolean;
  canListSubscriptions: boolean;
  canListAlerts: boolean;
  canListBodyChallenges: boolean;
  showLinks: boolean;

  constructor(private translate: TranslateService,
    private globalService: GlobalService,
    private authService: AuthService) { }

  ngOnInit() {
    this.showLinks = !this.authService.isInRole(constants.roles.timetable_edit);

    this.translate.get('HOME.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });
    this.checkPermissions();
  }

  private checkPermissions() {
    this.canListUsers = this.authService.isInRole(constants.roles.user_list);
    this.canListGyms = this.authService.isInRole(constants.roles.gym_list);
    this.canListVersions = this.authService.isInRole(constants.roles.application_version_list);
    this.canListContacts = this.authService.isInRole(constants.roles.contacts_list);
    this.canListChains = this.authService.isInRole(constants.roles.chains_list);
    this.canListScripts = this.authService.isInRole(constants.roles.scripts_list);
    this.canListApplications = this.authService.isInRole(constants.roles.applications_list);
    this.canListProfiles = this.authService.isInRole(constants.roles.profiles_list);
    this.canListPrograms = this.authService.isInRole(constants.roles.program_list);
    this.canListKiosks = false; // this.authService.isInRole(constants.roles.kiosk_list);
    this.canListMonitoring = this.authService.isInRole(constants.roles.monitoring_list);
    this.canListSubscriptions = this.authService.isInRole(constants.roles.subscription_list);

    this.canListTimetables = this.authService.isInRole(constants.roles.timetable_edit);
    this.canListAlerts = this.authService.isInRole(constants.roles.application_version_list); // create actual role
    this.canListBodyChallenges = this.authService.isInRole(constants.roles.management_body_challenge_list); // create actual role
  }


}
