import { Component, OnInit, Input } from '@angular/core';
import { ApplicationVersion } from '../../../interfaces/applicationversion';
import { ApplicationsService } from '../../../services/applications.service';
import { GlobalService } from '../../../services/global.service';
import { Application } from '../../../interfaces/application';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-version-list',
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.scss']
})
export class VersionListComponent implements OnInit {

  @Input() versions: ApplicationVersion[];

  dataSource;
  displayedColumns = ['version', 'description', 'creationDate'];

  private applicationList: Application[];

  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.versions);
  }


}
