import { Component, OnInit, Input, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { GymStation } from '../../../interfaces/gymstation';
import { Kiosk } from '../../../interfaces/kiosk';
import { GlobalService } from '../../../services/global.service';
import { GymStationType } from '../../../interfaces/gymstationtype';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { KiosksComponent } from '../../kiosks/kiosks.component';

@Component({
  selector: 'app-gym-station-editor',
  templateUrl: './gym-station-editor.component.html',
  styleUrls: ['./gym-station-editor.component.scss']
})
export class GymStationEditorComponent implements OnInit, AfterViewInit {

  @Input() stations: GymStation[];
  @Input() kiosks: Kiosk[];
  @Input() stationTypes: GymStationType[];

  newStationIdCount: number;
  newKioskIdCount: number;
  editMode: number;
  newKiosk: Kiosk;
  stationMult: number;
  numTiles: number;
  showTileInput: boolean;

  containerList: string[];

  constructor(private globalService: GlobalService, public dialog: MatDialog) {
    this.newStationIdCount = -1;
    this.newKioskIdCount = -1;
    this.editMode = 0;
    this.resetKiosk();

  }

  ngOnInit() {
    this.getContainers();
    this.stationMult = 1;
    this.showTileInput = false;

  }

  ngAfterViewInit() {
    this.stations.sort(function (obj1, obj2) {
      return obj1.station - obj2.station; // Temporary fix
    });

  }

  addStation(stationType: GymStation) {
    for (let i = 0; i < this.stationMult; i++) {
      const station: GymStation = {
        id: this.newStationIdCount,
        gym: 0,
        station: stationType.id,
        kiosks: [],
        tiles: this.numTiles
      };
      this.stations.push(station);
      this.newStationIdCount--;
    }
    this.stationMult = 1;
  }

  private modalRemoveStationOpen(station: GymStation) {
    this.dialog.open(ModalRemoveStationComponent, {
      data: {
        station: station,
        stations: this.stations
      }
    });
  }
  private modalRemoveKioskOpen(kiosk: Kiosk) {
    this.dialog.open(ModalRemoveKioskComponent, {
      data: {
        kiosk: kiosk,
        kiosks: this.kiosks
      }
    });
  }

  private getStation(id: number) {
    return this.stations.filter(sta => (sta.id === id))[0];
  }

  editKiosk(id: number) {
    this.editMode = id;
  }

  addKiosk() {
    this.kiosks.push(this.newKiosk);
    this.editMode = 0;
    this.newKioskIdCount--;
    this.resetKiosk();
  }

  private resetKiosk() {
    this.newKiosk = {
      id: this.newKioskIdCount,
      gymId: 0,
      computer: 0,
      status: 1,
      name: '',
      container: '',
      gymStationsIds: [],
      isMaster: false,
      scriptStatus: null
    };
  }

  getContainers() {
    this.containerList = [];
    for (const kiosk of this.kiosks) {
      if (kiosk.container !== '' && !this.containerList.includes(kiosk.container)) {
        this.containerList.push(kiosk.container);
      }
    }
  }

  private getKiosksOfStation(station: GymStation): Kiosk[] {
    const kioskRet: Kiosk[] = [];
    for (const kiosk of this.kiosks) {
      if (kiosk.gymStationsIds.includes(station.id)) {
        kioskRet.push(kiosk);
      }
    }
    return kioskRet;
  }

  public changeStationType($event) {
    this.numTiles = 1;
    this.showTileInput = $event.value.requireTile;
  }

  private changeIsMaster(kiosk: Kiosk) {
    for (const k of this.kiosks) {
      k.isMaster = false;
    }
    setTimeout(() => {
      kiosk.isMaster = true;
    }, 10);
  }

  public checkRequireTile(station: GymStation) {
    return this.globalService.stationTypesList.filter(sta => (sta.id === station.station))[0].requireTile;
  }

  // private getStationOrder(station: GymStation) {
  //   return this.globalService.stationTypesList.filter(sta => (sta.id === station.station))[0].order;
  // }

  public disableStation(kiosk: Kiosk, stationId: number) {
    const interactiveStations = this.stationTypes.filter(x => x.type === 2).map(x => x.id); // get interactive stations
    const s = this.stations.find(x => x.id === stationId);
    let disable: boolean;
    if (interactiveStations.includes(s.station)) {
      disable = this.kiosks.some(k => k.id !== kiosk.id && k.gymStationsIds.includes(stationId));
    } else {
      disable = false;
    }
    return disable;
  }

}

// Modals
@Component({
  selector: 'app-modal-remove-station',
  templateUrl: 'modal-remove-station.html',
})
export class ModalRemoveStationComponent {

  station: GymStation;
  stations: GymStation[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalRemoveStationComponent>,
    private globalService: GlobalService) {
    this.station = data.station;
    this.stations = data.stations;
  }

  removeStation(station: GymStation) {
    this.globalService.removeByKey(this.stations, { key: 'id', value: station.id });
    this.dialogRef.close();
  }

  modalRemoveStationCancel() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-modal-remove-kiosk',
  templateUrl: 'modal-remove-kiosk.html',
})
export class ModalRemoveKioskComponent {

  kiosk: Kiosk;
  kiosks: Kiosk[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalRemoveKioskComponent>,
    private globalService: GlobalService) {
    this.kiosk = data.kiosk;
    this.kiosks = data.kiosks;
  }

  removeKiosk(kiosk: Kiosk) {
    this.globalService.removeByKey(this.kiosks, { key: 'id', value: kiosk.id });
    this.dialogRef.close();
  }

  modalRemoveKioskCancel() {
    this.dialogRef.close();
  }

}

