import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { GymsService } from '../services/gyms.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class GymVersionRelListResolver implements Resolve<Observable<string>> {

    constructor(private gymsService: GymsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        let id = route.paramMap.get('id');
        if (!id || id === 'new') {
            id = '0';
        }
        return this.gymsService.getGymsRel(id).catch(() => {
            return Observable.of('error');
        });
    }

}

