import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { GlobalService } from '../services/global.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class GlobalResolver implements Resolve<Observable<string>> {

    constructor(private globalService: GlobalService) { }

    resolve() {
        return this.globalService.getGlobals().catch(() => {
            return Observable.of('error');
        });
    }

}

