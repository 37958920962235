import { GlobalService } from './../../../../services/global.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SubscriptionsGymItemExt } from '../../../../interfaces/subscription';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SubscriptionsService } from '../../../../services/subscriptions.service';
import { AlertsService } from '../../../../services/alerts.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Gym } from '../../../../interfaces/gym';

@Component({
  selector: 'app-gym-bootiqs',
  templateUrl: './gym-bootiqs.component.html',
  styleUrls: ['./gym-bootiqs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GymBootiqsComponent implements OnInit {

  @Input() gym: Gym;
  @Input() gymBootiqList: SubscriptionsGymItemExt[];

  displayedColumns = ['name', 'alert', 'testAlert', 'status', 'activeFrom', 'activeUntil', 'actions'];
  dataSource = new MatTableDataSource;
  expandedElement: SubscriptionsGymItemExt | null;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public editMode: number;
  isDateError: Boolean = false;

  constructor(private subscriptionsService: SubscriptionsService,
    private alertsService: AlertsService,
    private global: GlobalService) {
    this.editMode = 0;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.gymBootiqList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  private editItem(item: any) {
    this.editMode = item.id;
  }

  private saveItem(item: any) {
    // if (this.checkDateChange(item)) {
    //   this.resetEditMode();
    // }
    item.activeFrom = this.global.roundDate(item.activeFrom);
    item.activeUntil = this.global.roundDate(item.activeUntil);
    this.subscriptionsService.putGymSubscriptionItem([item], this.gym.id).subscribe(
      result => {
        this.alertsService.alertTrigger({ msg: 'Information update' })
      },
      error => {
        console.log('Error', error);
        this.alertsService.alertTrigger({ msg: 'Error saving data' })
      }
    );
    this.resetEditMode();

  }

  private checkDateChange(item: any) {
    const dateFrom = new Date(item.activeFrom);
    const dateUntil = new Date(item.activeUntil);
    if (dateFrom && dateUntil) {
      if (dateFrom > dateUntil) {
        this.isDateError = true;
        return false;
      }
    }
    this.isDateError = false;
    return true;
  }

  private resetEditMode() {
    this.editMode = 0;
    this.isDateError = false;
    this.expandedElement = null;
  }

  public getStatus(statusId: number) {
    switch (statusId) {
      case 0: return 'PROGRAMS.STATUS_DISABLED'; break;
      case 1: return 'PROGRAMS.STATUS_ENABLED'; break;
      case 2: return 'PROGRAMS.STATUS_DEMO'; break;
    }
  }

}
