import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { Chain } from '../../interfaces/chain';
import { ChainsService } from '../../services/chains.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-modal-remove-chain',
    templateUrl: 'modal-remove-chain.html',
})
export class ModalRemoveChainComponent {

    chain: Chain;
    chains: Chain[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveChainComponent>,
        private globalService: GlobalService,
        private chainsService: ChainsService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {

        this.chain = data.chain;
        this.chains = data.chains;
    }

    removeChain(chain: Chain) {
        this.deleteChain(chain);
        this.dialogRef.close();
    }

    modalRemoveChainCancel() {
        this.dialogRef.close();
    }

    public deleteChain(chain?: Chain) {
        const chainToDelete = chain ? chain : this.chain;

        this.chainsService.deleteChain(chainToDelete.id).subscribe(
            result => {
                this.router.navigate(['/chains']);
                this.translate.get('CHAINS.CHAIN_DELETED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ type: 'success', msg: translation });
                });
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

