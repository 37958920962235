import { Component, OnInit } from '@angular/core';
import { GymAlertsService } from '../../services/gymalerts.service';
import { GymAlert } from '../../interfaces/gymalert';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alert-manager',
  templateUrl: './alert-manager.component.html',
  styleUrls: ['./alert-manager.component.scss']
})
export class AlertManagerComponent implements OnInit {

  private gymAlerts: GymAlert[];
  private subsAlerts: any;
  private appAlerts: any;

  constructor(private gymAlertsService: GymAlertsService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private route: ActivatedRoute) {
    this.gymAlerts = route.snapshot.data.gymAlerts;
    this.subsAlerts = route.snapshot.data.subsAlerts;
    this.appAlerts = route.snapshot.data.appAlerts;
  }

  ngOnInit() {
    this.translate.get('ALERTS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });
  }

}
