import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class AlertsService {

  private alertSource = new Subject<string>();

  alert$ = this.alertSource.asObservable();

  public alertTrigger(params) {
    if (!params.button) {
      params.button = 'Ok';
    }
    if (!params.time) {
      params.time = 4000;
    }
    this.alertSource.next(params);
  }

}
