import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Gym } from '../../../interfaces/gym';
import { GymsService } from '../../../services/gyms.service';
import { Script } from '../../../interfaces/script';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';

@Component({
    selector: 'app-modal-disable-gyms',
    templateUrl: 'modal-disable-gyms.html',
})
export class ModalDisableGymsComponent {

    gym: Gym;
    script: Script;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalDisableGymsComponent>,
        public gymsService: GymsService,
        public translate: TranslateService,
        public alertsService: AlertsService) {

        this.script = data.script;
    }

    public modalDisableGymsOk() {
        this.disableGyms();
        this.dialogRef.close();
    }

    public modalDisableGymsCancel() {
        this.dialogRef.close();
    }

    public disableGyms() {
        this.gymsService.disableGyms(this.script).subscribe(
            result => {
                this.translate.get('SCRIPTS.GYMS_DISABLED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ msg: translation, button: 'Ok', time: 8000 });
                });
            },
            error => {
                console.log('Error: disableGyms');
            }
        );
        this.dialogRef.close();
    }
}

