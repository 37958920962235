import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SpecificProgramSession } from '../../../interfaces/specificprogramsession';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { GymProgram } from '../../../interfaces/gymprogram';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-demo-session-list',
  templateUrl: './demo-session-list.component.html',
  styleUrls: ['./demo-session-list.component.scss']
})
export class DemoSessionListComponent implements OnInit, AfterViewInit {

  @Input() program: GymProgram;

  editMode: number = null;

  displayedColumns = ['select', 'alias'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private global: GlobalService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.program.sessions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;

  }

  private isChecked(session: SpecificProgramSession) {
    return session.id === this.program.idDemo;
  }

  private toggleSession($event, session: SpecificProgramSession) {
    event.preventDefault();
    this.program.idDemo = session.id;
  }

}
