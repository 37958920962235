import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ApplicationsService } from '../../services/applications.service';
import { Application } from '../../interfaces/application';
import { AlertsService } from '../../services/alerts.service';

import { VersionListComponent } from '../partials/version-list/version-list.component';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.scss']
})
export class ApplicationDetailComponent implements OnInit {

  canModify: boolean;
  canCreate: boolean;
  editing: boolean;

  application: Application = {
    id: 0,
    name: '',
    path: '',
    active: true,
    type: null,
    versions: []
  };

  isNew: Boolean = false;
  modalRef: BsModalRef;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private applicationsService: ApplicationsService,
    private alertsService: AlertsService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private authService: AuthService) {
  }

  ngOnInit() {
    if (this.route.snapshot.data.application === 'new') {
      this.isNew = true;

      this.translate.get('APPLICATIONS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.application = this.route.snapshot.data.application;
      this.globalService.changePageTitle([this.application.name]);
    }

    this.canModify = this.authService.isInRole(constants.roles.application_modify);
    this.canCreate = this.authService.isInRole(constants.roles.application_create);
  }

  public saveApplication() {
    this.applicationsService.putApplication(this.application).subscribe(
      result => {
        this.translate.get('APPLICATIONS.APPLICATION_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createApplication() {
    this.application.id = 0;
    this.applicationsService.postApplication(this.application).subscribe(
      result => {
        this.translate.get('APPLICATIONS.APPLICATION_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/applications']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  // public deleteApplication() {
  //   this.applicationsService.deleteApplication(this.application.id).subscribe(
  //     result => {
  //       this.router.navigate(['/applications']);
  //       this.alertsService.alertTrigger({ type: 'success', msg: 'La aplicación se ha eliminado correctamente' });
  //     },
  //     error => {
  //       console.log('Error', error);
  //     }
  //   );
  // }

  // private openDeleteModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' });
  // }

  // private acceptDelete() {
  //   this.deleteApplication();
  //   this.modalRef.hide();
  // }

  // private cancelDelete() {
  //   this.modalRef.hide();
  // }

  onSubmit() {
    if (this.isNew) {
      this.createApplication();
    } else {
      this.saveApplication();
    }
  }

}
