import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { SessionToken } from '../interfaces/sessiontoken';
import { constants } from '../../assets/constants';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: SessionToken = JSON.parse(localStorage.getItem(constants.keySession));
    if (token && token !== null && token.token !== null) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token.token)
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
