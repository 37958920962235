import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { Contact } from '../../interfaces/contact';
import { ContactsService } from '../../services/contacts.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-modal-remove-contact',
    templateUrl: 'modal-remove-contact.html',
})
export class ModalRemoveContactComponent {

    contact: Contact;
    contacts: Contact[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveContactComponent>,
        private globalService: GlobalService,
        private contactsService: ContactsService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {

        this.contact = data.contact;
        this.contacts = data.contacts;
    }

    removeContact(contact: Contact) {
        this.deleteContact(contact);
        this.dialogRef.close();
    }

    modalRemoveContactCancel() {
        this.dialogRef.close();
    }

    public deleteContact(contact?: Contact) {
        const contactToDelete = contact ? contact : this.contact;

        this.contactsService.deleteContact(contactToDelete.id).subscribe(
            result => {
                this.router.navigate(['/contacts']);
                this.translate.get('CONTACTS.CONTACT_DELETED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ type: 'success', msg: translation });
                });
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

