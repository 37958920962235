import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { BodyChallengesService } from '../services/body-challenges.service';

@Injectable()
export class BodyChallengeUserListResolver implements Resolve<Observable<string>> {

    constructor(private bodyChallengesService: BodyChallengesService) { }

    resolve() {
        return this.bodyChallengesService.getBodyChallengeUserList().catch(() => {
            return Observable.of('error');
        });
    }
}

