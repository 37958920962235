import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { EventsService } from '../../../services/events.service';
import { Router } from '@angular/router';
import { AlertsService } from '../../../services/alerts.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  title: any;

  logged: boolean;


  constructor(private globalService: GlobalService,
    private router: Router,
    private eventsService: EventsService,
    private alertsService: AlertsService,
    private authService: AuthService) {
  }


  ngOnInit() {
    this.checkIsLogged();

    this.globalService.pageTitleChangeEvent$.subscribe((params) => {
      this.title = params;
    });
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'login' && params.value === 'ok') {
        this.checkIsLogged();
      }
    });
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'logout' && params.value === 'ok') {
        this.logged = false;
      }
    });
  }


  closeSession() {
    this.authService.closeSession().subscribe(resp => {
      this.router.navigate(['/login']);
    });
  }

  private checkIsLogged() {
    this.authService.validateOrRefreshToken().subscribe(
      result => {
        this.logged = result.valid;
      });
  }
}
