import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { ApplicationMini } from '../../interfaces/application';

@Component({
  selector: 'app-versions-manager',
  templateUrl: './versions-manager.component.html',
  styleUrls: ['./versions-manager.component.scss']
})
export class VersionsManagerComponent implements OnInit, AfterViewInit {

  appList: ApplicationMini[];
  private subsAlerts: any;
  private appAlerts: any;

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private authService: AuthService) {
    this.appList = [];
    this.subsAlerts = route.snapshot.data.subsAlerts;
    this.appAlerts = route.snapshot.data.appAlerts;
  }

  ngOnInit() {
    // Cargamos todas las apps en array
    this.route.snapshot.data.applicationList.forEach(app => {      
      this.appList.push(app);
    });

    this.translate.get('VERSIONS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

  }

  ngAfterViewInit() {
  }
}
