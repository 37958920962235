import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { GymsService } from '../../../services/gyms.service';
import { Gym } from '../../../interfaces/gym';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Chain } from '../../../interfaces/chain';
import { User } from '../../../interfaces/user';
import { compileNgModule } from '@angular/compiler';

@Component({
  selector: 'app-chain-content-manager-list',
  templateUrl: './chain-content-manager-list.component.html',
  styleUrls: ['./chain-content-manager-list.component.scss']
})
export class ChainContentManagerListComponent implements OnInit, AfterViewInit {

  @Input() userList: Gym[];
  @Input() chain: Chain;

  private displayedColumns = ['actions', 'id', 'name', 'lastName'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor() {

  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.userList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {

  }

  public userChecked(user: User) {
    return this.chain.contentManagers.indexOf(user.id) >= 0;
  }

  public toggleUser(user: User) {
    if(this.userChecked(user)) {
      this.chain.contentManagers = this.chain.contentManagers.filter(x => x != user.id);
    }
    else {
      this.chain.contentManagers.push(user.id);
    }
    console.log(this.chain.contentManagers);
  }

}
