import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { VersionsService } from '../../../services/versions.service';
import { Application } from '../../../interfaces/application';
import { ApplicationsService } from '../../../services/applications.service';
import { ApplicationVersion } from '../../../interfaces/applicationversion';

@Component({
  selector: 'app-remote-dependency-list',
  templateUrl: './remote-dependency-list.component.html',
  styleUrls: ['./remote-dependency-list.component.scss']
})
export class RemoteDependencyListComponent implements OnInit {
  private panelOpenState = false;
  applicationList: Application[];
  // private versionList: ApplicationVersion[];

  @Input() dependencies: ApplicationVersion[];
  @Input() dependencyType: number;
  @Input() title: string;

  constructor(private globalService: GlobalService,
    private versionsService: VersionsService,
    private applicationsService: ApplicationsService) { }

  ngOnInit() {
    this.applicationList = this.globalService.getApplications(this.dependencyType); // APPs:1 DLLs:2
  }


  private getApplicationVersions(index: number) {
    // if (this.applicationList[index].versions !== undefined || this.applicationList[index].versions.length !== 0) {
    if (true) {
      this.applicationsService.getApplication(this.applicationList[index].id).subscribe(
        result => {
          this.applicationList[index].versions = result.versions;
        },
        error => {
          console.log('Error');
        }
      );
    }
  }

  private isChecked(versionId: number) {

    return this.dependencies.filter(dep => (dep.id === versionId)).length > 0;
  }

  private changeRemoteDependencies($event, version: ApplicationVersion) {
    if ($event.checked) {
      this.dependencies.push(version);
    } else {
      this.globalService.removeByKey(this.dependencies, {
        key: 'id',
        value: version.id
      });
    }
    // this.globalService.removeByKey(this.dependencies, {
    //   key: 'application',
    //   value: applicationId
    // });
    // if (version) {
    //   this.dependencies.push(version);
    // }
    // console.log(this.dependencies);
  }
}
