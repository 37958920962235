import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Cacheable } from "ngx-cacheable";

import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { constants } from '../../assets/constants';

import { ILanguageCountry, ILanguageDataField, LangDictionary  } from "./../interfaces/language";
import { ApplicationVersionReleaseNote } from "../interfaces/applicationversion";

@Injectable()
export class LanguageService {

  public currentLanguage: number;

  private urlGetLanguages: string;
  
  private _languages: ILanguageCountry[];

  constructor(private http: HttpClient) {
    this.urlGetLanguages = constants.urlAPI + constants.endpoints.languages.get;
    this.currentLanguage = 1;
  }

  createReleaseNotes(langs: ILanguageCountry[], releaseNoteTypes: ILanguageCountry[]){
    let releaseNotes: ApplicationVersionReleaseNote[] = [];

    langs.forEach(lang => {
      releaseNoteTypes.forEach( type => {
        releaseNotes.push( {
          idLanguage:lang.id,
          idVersion: 0,
          releaseNote: '',
          type: type.id
        });
      });
    }); 

    return releaseNotes;
  }

  completeReleaseNotes(releaseNotes: ApplicationVersionReleaseNote[], langs: ILanguageCountry[], releaseNoteTypes: ILanguageCountry[]) {

    if(!releaseNotes)
      releaseNotes = [];

    langs.forEach(lang => {
      releaseNoteTypes.forEach( type => {

        if(releaseNotes.findIndex(x => x.idLanguage == lang.id && x.type == type.id) < 0) {
          releaseNotes.push( {
            idLanguage:lang.id,
            idVersion: 0,
            releaseNote: '',
            type: type.id
          });
        }

      })
    });
  }


  createLanguageField(key: string, langs: ILanguageCountry[]) : ILanguageDataField {
    let field: ILanguageDataField = {
      id: 0,
      key: key,
      resources: []
    };

    langs.forEach(lang => {
      field.resources.push( {
        country: lang,
        value: ""
      });
    }); 

    return field;
  }


  completeLanguageField(field: ILanguageDataField, langs: ILanguageCountry[]) {
    langs.forEach(lang => {
      if(field.resources.findIndex(x => x.country.id == lang.id) < 0) {
        field.resources.push( {
          country: lang,
          value: ""
        });
      }
    })
  }

  
  @Cacheable()
  public getLanguages(): Observable<any> {
    return this.http.get(this.urlGetLanguages);
  }


  public getValue(object: LangDictionary) {
    let resource = object.resources.find(resource => resource.language.id === this.currentLanguage);
    if(resource){
      return resource.value;
    }else{
      return 'LANGUAGE_NOT_DEFINED';
    }
  }

  // Devolvemos index del resource correspondiente a -currentLanguage- mentira, forzado a español
  public getLangIndex(object: LangDictionary){
    // let resourceIndex = object.resources.findIndex(resource => resource.language.id === this.currentLanguage);
    let resourceIndex = object.resources.findIndex(resource => resource.language.id === 1);
    return resourceIndex;
  }
}
