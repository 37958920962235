import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'scriptstatus'
})
export class ScriptStatusPipe implements PipeTransform {

  constructor(private globalService: GlobalService) { }

  transform(id: any): any {
    return this.globalService.getScriptStatusList().filter(sta => (sta.id === id))[0].name;
  }

}
