import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { GymStationType } from '../interfaces/gymstationtype';

@Pipe({
  name: 'stationname'
})
export class StationNamePipe implements PipeTransform {

  constructor(private globalService: GlobalService) { }

  transform(id: number): string {
    return this.globalService.getStationTypes().filter(sta => (sta.id === id))[0].name;
  }

}
