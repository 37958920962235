import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ContactsService } from '../../../services/contacts.service';
import { Contact } from '../../../interfaces/contact';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { GlobalService } from '../../../services/global.service';
import { MatAutocomplete } from '@angular/material';

@Component({
  selector: 'app-gym-contact-list',
  templateUrl: './gym-contact-list.component.html',
  styleUrls: ['./gym-contact-list.component.scss']
})
export class GymContactListComponent implements OnInit {

  @Input() contacts: Contact[];
  private contactList: Contact[];
  displayedColumns = ['name', 'role', 'phone', 'email', 'actions'];
  filteredContactList: Observable<Contact[]>;
  contactCtrl: FormControl = new FormControl();
  tableContacts: Observable<Contact[]>;

  editMode: number;
  newContact: Contact;
  newContactIndex: number;

  constructor(private contactsService: ContactsService, private globalService: GlobalService) {
    this.contactsService.getContacts().subscribe(
      result => {
        this.contactList = result;
        this.filterContacts('');
      }
    );

    this.newContactIndex = 0;
    this.editMode = this.newContactIndex;
    this.clearNewContact();

  }

  ngOnInit() { }

  updateFilteredContactList() {
    this.filteredContactList = this.contactCtrl.valueChanges.pipe(
      startWith(''),
      map(val => this.filterContacts(val))
    );
  }

  private filterContacts(val: string): Contact[] {
    this.sortContactList();
    for (const contact of this.contacts) {
      this.globalService.removeByKey(this.contactList, { key: 'id', value: contact.id });
    }
    return this.contactList.filter(contact => contact.name.toLowerCase().indexOf(val.toString().toLowerCase()) !== -1);
  }

  addContact($event) {
    this.contactCtrl.setValue('');
    this.contacts.push($event.option.value);
    this.updateFilteredContactList();
  }

  private removeContact(contact: Contact) {
    this.contactList.push(contact);
    this.globalService.removeByKey(this.contacts, { key: 'id', value: contact.id });
    this.updateFilteredContactList();
  }

  private getTableData(): Observable<Contact[]> {
    return new Observable(observer => {
      observer.next(this.contacts);
    });
  }
  private sortContactList() {
    this.contactList.sort(function (a, b) {
      const nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  public addNewContact() {
    if(this.newContact.name !== '' && this.newContact.email !== ''){
      this.contacts.push(this.newContact);
      this.clearNewContact();
      this.editMode = 0;
    }
  }

  public openContact(contact: Contact){

  }

  public editContact(contact: Contact){
    this.editMode = contact.id;
  }

  public saveContact(contact: Contact){    
    this.editMode = 0;
  }

  private clearNewContact(){
    this.newContactIndex--;
    this.newContact = {
      id: this.newContactIndex,
      name: '',
      email: '',
      phone: '',
      address: '',
      address2: '',
      city: '',
      zip: '',
      country: '',
      gender: '',
      role: ''
    }
  }
}
