import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { Profile } from '../../interfaces/profile';
import { ProfilesService } from '../../services/profiles.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-modal-remove-profile',
    templateUrl: 'modal-remove-profile.html',
})
export class ModalRemoveProfileComponent {

    profile: Profile;
    profiles: Profile[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveProfileComponent>,
        private globalService: GlobalService,
        private profilesService: ProfilesService,
        private router: Router,
        private translate: TranslateService,
        private alertsService: AlertsService) {

        this.profile = data.profile;
        this.profiles = data.profiles;
    }

    removeProfile(profile: Profile) {
        this.deleteProfile(profile);
        this.dialogRef.close();
    }

    modalRemoveProfileCancel() {
        this.dialogRef.close();
    }

    public deleteProfile(profile?: Profile) {
        const profileToDelete = profile ? profile : this.profile;

        this.profilesService.deleteProfile(profileToDelete.id).subscribe(
            result => {
                this.router.navigate(['/profiles']);
                this.translate.get('PROFILES.PROFILE_DELETED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ type: 'success', msg: translation });
                });
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

