import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { MonitoringService } from '../services/monitoring.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class MonitoringDetailResolver implements Resolve<Observable<string>> {

    constructor(private monitoringService: MonitoringService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const gymIdParameter = route.paramMap.get('id');

        if (!gymIdParameter || gymIdParameter === '') {
            return Observable.of('error');
        } else {
            return this.monitoringService.getMonitoringDetail(gymIdParameter).catch(() => {
                return Observable.of('error');
            });
        }
    }

}

