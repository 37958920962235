import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { GymProgram } from '../interfaces/gymprogram';

@Injectable()
export class ProgramsService {

  private urlGetPrograms = constants.urlAPI + constants.endpoints.specificPrograms.get;
  private urlGetProgram = constants.urlAPI + constants.endpoints.specificPrograms.getId;
  private urlGetSpecificProgram = constants.urlAPI + constants.endpoints.specificPrograms.getId;
  private urlPostSpecificProgram = constants.urlAPI + constants.endpoints.specificPrograms.post;
  private urlPutProgram = constants.urlAPI + constants.endpoints.programs.put;
  private urlPutSpecificProgram = constants.urlAPI + constants.endpoints.specificPrograms.put;
  private urlGetCreatorPrograms = constants.urlAPI + constants.endpoints.programs.creatorGet;
  private urlGetCreatorSessions = constants.urlAPI + constants.endpoints.sessions.creatorGet;

  constructor(public http: HttpClient) { }

  public getPrograms(): Observable<any> {
    return this.http.get(this.urlGetPrograms);
  }

  public getProgram(id): Observable<any> {
    return this.http.get(this.urlGetProgram.replace(':id', id));
  }

  public putProgram(program: GymProgram): Observable<any> {
    const data = program;
    return this.http.put(this.urlPutProgram, data);
  }
  public putSpecificProgram(program: GymProgram): Observable<any> {
    const data = program;
    return this.http.put(this.urlPutSpecificProgram, data);
  }

  public postSpecificProgram(program: GymProgram): Observable<any> {
    const data = program;
    return this.http.post(this.urlPostSpecificProgram, data);
  }

  public getSpecificProgram(id): Observable<any> {
    return this.http.get(this.urlGetSpecificProgram.replace(':id', id));
  }

  public getCreatorPrograms(): Observable<any> {
    return this.http.get(this.urlGetCreatorPrograms);
  }
  public getCreatorSessions(id): Observable<any> {
    return this.http.get(this.urlGetCreatorSessions.replace(':id', id));
  }

  // public putProgramDefaultDemoSession(program: GymProgram): Observable<any> {
  //   const data = program;
  //   return this.http.put(this.urlPutProgramDefaultDemoSession, data);
  // }

}
