import { Pipe, PipeTransform } from '@angular/core';
import { constants } from '../../assets/constants';

@Pipe({
  name: 'trainingmodeicon'
})
export class TrainingmodeiconPipe implements PipeTransform {

  transform(id: any, args?: any): any {
    if (id > 5) {
      return constants.trainingModesIcons.path + constants.trainingModesIcons.icons.filter((tm) => tm.id === id)[0].iconFile;
    }
    return constants.trainingModesIcons.path + constants.trainingModesIcons.icons.filter((tm) => tm.id === id)[0].iconFile;
  }

}
