import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Gym } from '../../../interfaces/gym';
import { GymsService } from '../../../services/gyms.service';
import { Script } from '../../../interfaces/script';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';

@Component({
    selector: 'app-modal-disable-gym',
    templateUrl: 'modal-disable-gym.html',
})
export class ModalDisableGymComponent {

    gym: Gym;
    script: Script;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalDisableGymComponent>,
        public gymsService: GymsService,
        public translate: TranslateService,
        public alertsService: AlertsService) {

        this.gym = data.gym;
        this.script = data.script;
    }

    public modalDisableGymOk() {
        this.disableGym();
        this.dialogRef.close();
    }

    public modalDisableGymCancel() {
        this.dialogRef.close();
    }

    public disableGym() {
        // for (const kiosk of this.gym.kiosk) {
        //     if (kiosk.scriptStatus.status === 1) {
        //         kiosk.scriptStatus.status = 2;
        //     }
        // }
        this.gymsService.disableGym(this.script, this.gym).subscribe(
            result => {
                this.translate.get('SCRIPTS.GYM_DISABLED').subscribe((translation) => {
                    this.alertsService.alertTrigger({ msg: translation, button: 'Ok', time: 8000 });
                });
            },
            error => {
                console.log('Error: disableGym');
            }
        );
        this.dialogRef.close();
    }
}

