import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ScriptsService } from '../services/scripts.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class ScriptListResolver implements Resolve<Observable<string>> {

    constructor(private scriptsService: ScriptsService) { }

    resolve() {
        return this.scriptsService.getScripts().catch(() => {
            return Observable.of('error');
        });
    }

}

