import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';

import { ChainsService } from '../../services/chains.service';
import { Chain } from '../../interfaces/chain';
import { AlertsService } from '../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { ModalRemoveChainComponent } from './modal-remove-chain.component';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';
import { Gym } from '../../interfaces/gym';
import { User } from '../../interfaces/user';

@Component({
  selector: 'app-chain-detail',
  templateUrl: './chain-detail.component.html',
  styleUrls: ['./chain-detail.component.scss']
})
export class ChainDetailComponent implements OnInit {

  canCreate: boolean;
  canDelete: boolean;
  canModify: boolean;

  chain: Chain = {
    id: null,
    name: '',
    description: '',
    logo: '',
    contentManagers: []
  };
  chainList: Chain[];
  gymList: Gym[];

  isNew: Boolean = false;

  logoPath: string;
  urlNoImage: string;
  userList: User[];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private chainsService: ChainsService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    private authService: AuthService,
    private globalService: GlobalService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.chainList = this.route.snapshot.data.chainList;
    this.gymList = this.route.snapshot.data.gymList;
    this.userList = this.route.snapshot.data.userList;
    
    this.logoPath = constants.urlAPP + constants.logos.urlChains;
    this.urlNoImage = constants.logos.urlNoImage;

    this.route.params.subscribe(params => {
      if (params.id !== 'new') {
        this.chain = this.chainList.find(obj => obj.id === Number(params.id));
        this.globalService.changePageTitle([this.chain.name]);
      } else {
        this.isNew = true;
        this.translate.get('CHAINS.TITLE_NEW').subscribe((translation) => {
          this.globalService.changePageTitle([translation]);
        });
      }
    });

    this.canCreate = this.authService.isInRole(constants.roles.chain_create);
    this.canModify = this.authService.isInRole(constants.roles.chain_modify);
    this.canDelete = this.authService.isInRole(constants.roles.chain_delete);

  }

  private getChain(id) {
    this.chainsService.getChains().subscribe(
      result => {
        this.chain = result.find(obj => obj.id === Number(id));
      },
      error => {
        console.log('Error');
      }
    );
  }

  public saveChain() {
    this.chainsService.putChain(this.chain).subscribe(
      result => {
        this.translate.get('CHAINS.CHAIN_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createChain() {
    this.chain.id = 0;

    this.chainsService.postChain(this.chain).subscribe(
      result => {
        this.translate.get('CHAINS.CHAIN_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/chains']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private modalRemoveChainOpen(chain: Chain) {
    this.dialog.open(ModalRemoveChainComponent, {
      data: {
        chain: chain,
        chains: this.chainList
      }
    });
  }

  onSubmit() {
    if (this.isNew) {
      this.createChain();
    } else {
      this.saveChain();
    }
  }

  async postFile(data: File): Promise<any> {
    this.globalService.postFile(data).subscribe(
      fileName => {
        this.logoPath = constants.urlAPP + constants.urlTempFiles;
        this.chain.logo = fileName;
      }, error => {
        this.translate.get('CHAINS.ERROR_UPLOAD_LOGO').subscribe((translation) => {
          this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        });
      }
    );
  }

}



