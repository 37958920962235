import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { GymsService } from '../services/gyms.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { C } from '@angular/cdk/keycodes';

@Injectable()
export class GymDetailResolver implements Resolve<Observable<string>> {

    constructor(private gymsService: GymsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') !== 'new') {
            return this.gymsService.getGym(route.paramMap.get('id')).catch((err) => {
                console.log(err);
                return Observable.of('error');
            });
          } else {
            return Observable.of('new');
          }
    }
}
