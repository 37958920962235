import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramsService } from '../services/programs.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class CreatorSessionsListResolver implements Resolve<Observable<string>> {

  constructor(private programsService: ProgramsService) { }

  resolve(route: ActivatedRouteSnapshot) {

    return this.programsService.getCreatorSessions(route.paramMap.get('id')).catch(() => {
      return Observable.of('error');
    });

  }

}

