import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { of } from 'rxjs/observable/of';

@Injectable()
export class MonitoringService {

  private urlMonitoringGymList = constants.urlAPI + constants.endpoints.monitoringList.get;
  private urlMonitoringGymDetail = constants.urlAPI + constants.endpoints.monitoringDetail.get;
  private urlMonitoringDetailLogs = constants.urlAPI + constants.endpoints.monitoringDetail.get;

  constructor(public http: HttpClient) { }

  public getMonitoringGymList(): Observable<any> {
    return this.http.get(this.urlMonitoringGymList);
  }

  public getMonitoringDetail(gymId): Observable<any> {
    const url = this.urlMonitoringGymDetail.replace(':id', gymId);
    return this.http.get(url);
  }

  public getMonitoringDetailLogs(gymId): Observable<any> {
    const url = this.urlMonitoringGymDetail.replace(':id', gymId);
    return this.http.get(url);
  }
}
