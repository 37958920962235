import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Application } from '../interfaces/application';
import { constants } from '../../assets/constants';

@Injectable()
export class ApplicationsService {

  private urlGetApplications =  constants.urlAPI + constants.endpoints.applications.get;
  private urlGetApplication = constants.urlAPI + constants.endpoints.application.get;
  private urlPutApplication = constants.urlAPI + constants.endpoints.application.put;
  private urlPostApplication = constants.urlAPI + constants.endpoints.application.post;
  // private urlDeleteApplication = 'http://testapi.pramacloud.com/management/application/';

  constructor(public http: HttpClient) { }

  public getApplications(): Observable<any> {
    return this.http.get(this.urlGetApplications);
  }

  public getApplication(id): Observable<any> {
    return this.http.get(this.urlGetApplication.replace(':id', id));
  }

  public putApplication(application: Application): Observable<any> {
    const data = application;
    return this.http.put(this.urlPutApplication, data);
  }

  public postApplication(application: Application): Observable<any> {
    const data = application;
    return this.http.post(this.urlPostApplication, data);
  }

  // public deleteApplication(id: number): Observable<any> {
  //   return this.http.delete(this.urlDeleteApplication + id);
  // }
}
