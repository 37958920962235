import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  spinner: boolean;
  public spinnerMessage: string;

  public isLoading() {
    return this.spinner;
  }

  constructor() {
    this.spinner = false;
  }

  public showSpinner(message?: string) {
    this.spinner = true;
    this.spinnerMessage = message ? message : 'Please wait';
  }

  public hideSpinner() {
    this.spinner = false;
  }
}
