import { Pipe, PipeTransform } from '@angular/core';
import { constants } from '../../assets/constants';

@Pipe({
  name: 'stationicon'
})
export class StationiconPipe implements PipeTransform {

  transform(id: any, args?: any): any {
    return constants.stationIcons.path + constants.stationIcons.icons.filter((tm) => tm.id === id)[0].iconFile;
  }

}
