import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SubscriptionsService } from '../services/subscriptions.service';

@Injectable()
export class SubscriptionsPackListResolver implements Resolve<Observable<string>> {

    constructor(private subscriptionsService: SubscriptionsService) { }

    resolve() {
        return this.subscriptionsService.getPacks().catch(() => {
            return Observable.of('error');
        });
    }

}

