import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProfilesService } from '../services/profiles.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class ProfileListResolver implements Resolve<Observable<string>> {

    constructor(private profilesService: ProfilesService) { }

    resolve() {
        return this.profilesService.getProfiles().catch(() => {
            return Observable.of('error');
        });
    }

}

