import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ProgramsService } from '../../../services/programs.service';
import { MatTableDataSource } from '@angular/material';
import { GymProgram } from '../../../interfaces/gymprogram';
import { GlobalService } from '../../../services/global.service';
import { constants } from '../../../../assets/constants';

@Component({
  selector: 'app-gym-program-list',
  templateUrl: './gym-program-list.component.html',
  styleUrls: ['./gym-program-list.component.scss']
})
export class GymProgramListComponent implements OnInit, AfterViewInit {

  @Input() programsActive;
  @Input() specificProgramList;
  @Input() specificPrograms;

  @Input() programType: number;

  displayedColumns;
  dataSource = new MatTableDataSource;
  programList: GymProgram[];

  editMode = 0;

  isDateError: Boolean = false;


  constructor(private programsService: ProgramsService, private global: GlobalService) { }


  ngOnInit() {
    if (this.specificPrograms) {
      this.displayedColumns = ['name', 'activeFrom', 'activeUntil', 'actions'];
    } else {
      this.displayedColumns = ['name', 'status', 'activeFrom', 'activeUntil', 'actions'];
    }
  }


  ngAfterViewInit() {
    this.initProgramList();
    this.dataSource = new MatTableDataSource(this.programList);
  }


  private initProgramList() {
    if (!this.specificPrograms) {

      if (this.programType === constants.programTypes.Pro || this.programType === constants.programTypes.Bootiq) {
        this.programList = this.programsActive.filter(pro => (pro.isSpecific === false) && (pro.type === constants.programTypes.Pro || pro.type === constants.programTypes.Bootiq));
      }
      else {
        this.programList = this.programsActive.filter(pro => (pro.isSpecific === false) &&
          pro.type === constants.programTypes.Program || pro.type === constants.programTypes.OldProgram);
      }

    } else {
      this.programList = this.specificProgramList;
      for (let i = 0; i < this.programList.length; i++) {
        if (this.programsActive.filter(pro => (pro.id === this.programList[i].id && pro.isSpecific === true))[0] !== undefined) {
          this.programList[i].activeFrom = this.programsActive.filter(pro => (pro.id === this.programList[i].id && pro.isSpecific === true))[0].activeFrom;
        }
        if (this.programsActive.filter(pro => (pro.id === this.programList[i].id && pro.isSpecific === true))[0] !== undefined) {
          this.programList[i].activeUntil = this.programsActive.filter(pro => (pro.id === this.programList[i].id && pro.isSpecific === true))[0].activeUntil;
        }
        this.programList[i].status = 1;
      }
    }
  }


  private isChecked(program: GymProgram) {
    return this.programsActive.filter(pro => (pro.id === program.id && pro.isSpecific === true)).length > 0;
  }


  private toggleProgram(program: GymProgram) {
    if (!this.isChecked(program)) {
      this.programsActive.push(program);
    } else {
      this.global.removeByKeys(this.programsActive, { key: 'id', value: program.id, key2: 'isSpecific', value2: true });
    }
  }


  private editProgram(program: GymProgram) {
    this.editMode = program.id;
    this.resetProgramDate(program);
  }


  private saveProgram(program: GymProgram) {
    if (this.checkDateChange(program)) {
      this.resetEditMode();
      this.programsActive.filter(pro => (pro.id === program.id && pro.isSpecific === this.specificPrograms))[0].activeFrom = this.global.fixUTC(new Date(program.activeFrom)).toISOString();
      this.programsActive.filter(pro => (pro.id === program.id && pro.isSpecific === this.specificPrograms))[0].activeUntil = this.global.fixUTC(new Date(program.activeUntil)).toISOString();
      this.programsActive.filter(pro => (pro.id === program.id && pro.isSpecific === this.specificPrograms))[0].status = program.status;
    }
  }


  private checkDateChange(program: GymProgram) {
    const dateFrom = new Date(program.activeFrom);
    const dateUntil = new Date(program.activeUntil);
    if (dateFrom && dateUntil) {
      if (dateFrom > dateUntil) {
        this.isDateError = true;
        return false;
      }
    }
    this.isDateError = false;
    return true;
  }


  private changeProgramStatus(program: GymProgram) {
    if (program.status === 0 || program.status === 2) {
      program.activeFrom = program.activeUntil = null;
      this.resetEditMode();
    } else {
      this.resetProgramDate(program);
    }
  }


  private resetProgramDate(program: GymProgram) {
    const d = new Date();
    program.activeFrom = program.activeFrom !== null ? program.activeFrom : d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2);
    program.activeUntil = program.activeUntil !== null ? program.activeUntil : '2100-01-01';
  }


  private resetEditMode() {
    this.editMode = 0;
    this.isDateError = false;
  }

}
