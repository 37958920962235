import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Gym } from '../../../interfaces/gym';
import { GymsService } from '../../../services/gyms.service';
import { Script } from '../../../interfaces/script';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../../services/alerts.service';
import { GymTimetable } from '../../../interfaces/gymtimetable';

@Component({
    selector: 'app-modal-timetable-edit',
    templateUrl: 'modal-timetable-edit.html',
})
export class ModalTimetableEditComponent {

    event: GymTimetable;
    timetable: GymTimetable[];
    isNew: boolean;
    startTime: string;
    finishTime: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalTimetableEditComponent>,
        public gymsService: GymsService,
        public translate: TranslateService,
        public alertsService: AlertsService) {

        this.event = data.event;
        this.timetable = data.timetable;
        this.isNew = data.isNew;
        // this.startTime = this.formatTime(this.getTimePart(this.event.startTime, 0), this.getTimePart(this.event.startTime, 1));
        // this.finishTime = this.formatTime(this.getTimePart(this.event.finishTime, 0), this.getTimePart(this.event.finishTime, 1));
        this.startTime = this.event.startTime.slice(0, -3);
        this.finishTime = this.event.finishTime.slice(0, -3);
    }

    formatTime(hours: number, minutes: number): string {
        return hours + ':' + ('0' + minutes).slice(-2);
    }

    getTimePart(input: string, part: number) {
        return Number(input.split(':')[part]);
    }

    private checkDate(): boolean {
        let status = true;
        if (
            isNaN(Number(this.startTime.split(':')[0]))
            || isNaN(Number(this.startTime.split(':')[1]))
        ) {
            status = false;
        }
        return status;
    }

    public saveEvent() {
        //this.disableGym();
        if (this.checkDate()) {
            this.event.startTime = this.startTime + ':00';
            this.event.finishTime = this.finishTime + ':00';
            if (this.isNew) {
                this.timetable.push(this.event);
            }
        }
        this.dialogRef.close();
    }

    public closeModal() {
        this.dialogRef.close();
    }

    public deleteEvent() {
        const deleteIndex = this.timetable.findIndex(x => x.id === this.event.id);
        if (deleteIndex >= 0) {
            this.timetable.splice(deleteIndex, 1);
        }
        this.dialogRef.close();
    }

}

