import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SubscriptionsGymItemExt, SubscriptionsOrder } from '../../../../interfaces/subscription';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SubscriptionsService } from '../../../../services/subscriptions.service';
import { AlertsService } from '../../../../services/alerts.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Gym } from '../../../../interfaces/gym';
import { GlobalService } from '../../../../services/global.service';
import { constants } from '../../../../../assets/constants';
import { LoadingService } from '../../../../services/loading.service';

@Component({
  selector: 'app-gym-orders',
  templateUrl: './gym-orders.component.html',
  styleUrls: ['./gym-orders.component.scss']
})
export class GymOrdersComponent implements OnInit {

  @Input() gym: Gym;
  @Input() gymOrderList: SubscriptionsOrder[];

  displayedColumns = ['order', 'date', 'attachment', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public editMode: number;
  isDateError: Boolean = false;
  newItemCounter: number;

  constructor(private subscriptionsService: SubscriptionsService,
    private alertsService: AlertsService,
    public globalService: GlobalService,
    private loadingService: LoadingService) {
    this.editMode = 0;
    this.newItemCounter = -1;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.gymOrderList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  private editItem(item: any) {
    this.editMode = item.id;
  }

  private saveItem(item: any) {
    item.date = this.globalService.roundDate(item.activeFrom);    
    if (item.id < 0) {
      this.subscriptionsService.postGymOrder(item).subscribe(
        result => {
          item.id = result;
          this.alertsService.alertTrigger({ msg: 'Information update' })
        },
        error => {
          console.log('Error', error);
          this.alertsService.alertTrigger({ msg: 'Error saving data' })
        }
      );
    } else {
      this.subscriptionsService.putGymOrder(item).subscribe(
        result => {
          this.alertsService.alertTrigger({ msg: 'Information update' })
        },
        error => {
          console.log('Error', error);
          this.alertsService.alertTrigger({ msg: 'Error saving data' })
        }
      );
    }
    this.editMode = 0;
    this.isDateError = false;

  }

  public deleteItem(item: any) {
    this.subscriptionsService.deleteGymOrder(item).subscribe(
      result => {
        const index = this.gymOrderList.findIndex(x => x.id === item.id);
        this.gymOrderList.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.gymOrderList);
        this.alertsService.alertTrigger({ msg: 'Item deleted' })
      },
      error => {
        console.log('Error', error);
        this.alertsService.alertTrigger({ msg: 'Error saving data' })
      }
    );
  }

  private checkDateChange(item: any) {
    const dateFrom = new Date(item.activeFrom);
    const dateUntil = new Date(item.activeUntil);
    if (dateFrom && dateUntil) {
      if (dateFrom > dateUntil) {
        this.isDateError = true;
        return false;
      }
    }
    this.isDateError = false;
    return true;
  }

  private resetEditMode(item: any) {
    this.editMode = 0;
    this.isDateError = false;
    if (item.id < 0 && (item.order === null || item.order === '') && (item.date === null || item.date === '')) {
      const index = this.gymOrderList.findIndex(x => x.id === item.id);
      this.gymOrderList.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.gymOrderList);
    }
  }

  public getStatus(statusId: number) {
    switch (statusId) {
      case 0: return 'PROGRAMS.STATUS_DISABLED'; break;
      case 1: return 'PROGRAMS.STATUS_ENABLED'; break;
      case 2: return 'PROGRAMS.STATUS_DEMO'; break;
    }
  }

  public newItem() {
    const newItem: SubscriptionsOrder = {
      id: this.newItemCounter,
      gymId: this.gym.id,
      order: null,
      attachment: null,
      date: null
    }
    this.gymOrderList.unshift(newItem);
    this.dataSource = new MatTableDataSource(this.gymOrderList);
    this.editMode = this.newItemCounter;
    this.newItemCounter--;
  }

  public isOrderValidated(item: any) {
    return item.order === '' || item.date === '' || item.order === null || item.date === null;
  }

  public async postFile(data: File, order: SubscriptionsOrder): Promise<any> {
    this.loadingService.showSpinner();
    this.globalService.postFile(data).subscribe(
      fileName => {
        order.attachment = fileName;
        this.saveItem(order);
        this.loadingService.hideSpinner();
        // this.logoPath = constants.urlAPP + constants.urlTempFiles;
        // this.gym.logo = fileName;
      }, error => {
        // this.translate.get('GYMS.ERROR_UPLOAD_LOGO').subscribe((translation) => {
        //   this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
        // });
      }
    );
  }

  public getFile(item: any){
    const url = constants.urlAPP + constants.urlSubscriptionOrderAttachments.replace(':orderId', item.id).replace(':file', item.attachment);
    window.open(url, '_blank');
  }

}
