import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesno'
})
export class YesNoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (args !== undefined) {
        if (args === 'icon') {
          return 'check';
        }
      } else {
        return 'Yes';
      }
    } else {
      if (args !== undefined) {
        if (args === 'icon') {
          return 'clear';
        }
      } else {
        return 'No';
      }
    }

  }

}
