import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { AuthService } from '../../../services/auth.service';
import { constants } from '../../../../assets/constants';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  canListUsers: boolean;
  canListGyms: boolean;
  canListApplications: boolean;
  canListVersions: boolean;
  canListContacts: boolean;
  canListChains: boolean;
  canListProfiles: boolean;
  canListScripts: boolean;
  canListPrograms: boolean;
  canListKiosks: boolean;
  canListTimetables: boolean;
  canListMonitoring: boolean;
  canListSubscriptions: boolean;
  canListAlerts: boolean;
  canListBodyChallenges: boolean;
  logged: boolean;

  submenus = { programs: false };

  @Input() drawer;

  constructor(private globalService: GlobalService,
    private authService: AuthService,
    private eventsService: EventsService) {
    this.logged = false;
  }

  ngOnInit() {
    this.checkPermissions();
    this.checkIsLogged();

    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'login' && params.value === 'ok') {
        this.checkPermissions();
        this.checkIsLogged();
      }
    });
    this.eventsService.event$.subscribe((params: any) => {
      if (params.key === 'logout' && params.value === 'ok') {
        this.checkPermissions();
        this.logged = false;
      }
    });
  }

  private checkPermissions() {
    this.canListUsers = this.authService.isInRole(constants.roles.user_list);
    this.canListGyms = this.authService.isInRole(constants.roles.gym_list);
    this.canListVersions = this.authService.isInRole(constants.roles.application_version_list);
    this.canListContacts = this.authService.isInRole(constants.roles.contacts_list);
    this.canListChains = this.authService.isInRole(constants.roles.chains_list);
    this.canListScripts = this.authService.isInRole(constants.roles.scripts_list);
    this.canListApplications = this.authService.isInRole(constants.roles.applications_list);
    this.canListProfiles = this.authService.isInRole(constants.roles.profiles_list);
    this.canListPrograms = this.authService.isInRole(constants.roles.program_list);
    this.canListKiosks = false; // this.authService.isInRole(constants.roles.kiosk_list);
    this.canListMonitoring = this.authService.isInRole(constants.roles.monitoring_list);
    this.canListSubscriptions = this.authService.isInRole(constants.roles.subscription_list);

    this.canListTimetables = this.authService.isInRole(constants.roles.timetable_edit);
    this.canListAlerts = this.authService.isInRole(constants.roles.application_version_list); // create actual role
    this.canListBodyChallenges = this.authService.isInRole(constants.roles.management_body_challenge_list); // create actual role
  }

  private checkIsLogged() {
    this.authService.validateOrRefreshToken().subscribe(
      result => {
        this.logged = result.valid;
      });
  }

  toggleSubmenu(submenu: any) {
    this.submenus[submenu] = !this.submenus[submenu];
  }

}
