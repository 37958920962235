import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/do';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../services/alerts.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private alertsService: AlertsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    // return Observable.of(true);

    const requiredRole: number = route.data['role'][0] as number;
    const hasRole = this.authService.isInRole(requiredRole);
    const result = {
      valid: false,
      expired: false
    };

    return this.authService.validateOrRefreshToken()
      .catch(error => {
        return Observable.of(result);
      })
      .map(
        response => {
          if (response.valid) { // token ok
            if (hasRole) {
              return true;
            } else {
              this.redirectToHome();
              return false;
            }
          } else {
            if (response.expired) { // token expired
              this.redirectToLogin(true);
              return false;
            } else {
              this.redirectToLogin(false); // no token
              return false;
            }
          }
        }
      );
  }

  private redirectToHome() {
    this.translate.get('LOGIN.ACCESS_DENIED').subscribe((translation) => {
      this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
    });
    this.router.navigate(['']);
  }


  private redirectToLogin(expired: boolean) {
    if (expired) {
      this.translate.get('LOGIN.SESSION_EXPIRED').subscribe((translation) => {
        this.alertsService.alertTrigger({ msg: translation, button: 'Ok' });
      });
    }
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
