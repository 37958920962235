import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { Chain } from '../../interfaces/chain';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { ChainsService } from '../../services/chains.service';
import { AlertsService } from '../../services/alerts.service';
import { ModalRemoveChainComponent } from './modal-remove-chain.component';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-chains',
  templateUrl: './chains.component.html',
  styleUrls: ['./chains.component.scss']
})
export class ChainsComponent implements OnInit, AfterViewInit {
  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;

  chainList: any;

  logoPath: string;

  displayedColumns = ['name', 'description', 'logo', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private globalService: GlobalService,
    private translate: TranslateService,
    private chainsService: ChainsService,
    private alertsService: AlertsService,
    private authService: AuthService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.chainList = this.route.snapshot.data.chainList;
    this.logoPath = constants.urlAPP + constants.logos.urlChains;

    this.translate.get('CHAINS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.chain_create);
    this.canModify = this.authService.isInRole(constants.roles.chain_modify);
    this.canDelete = this.authService.isInRole(constants.roles.chain_delete);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.chainList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

  public updateChainList() {
    this.chainsService.getChains().subscribe(
      result => {
        this.chainList = result;
        this.dataSource = new MatTableDataSource(this.chainList);
      }
    );
  }

  private modalRemoveChainOpen(chain: Chain) {
    this.dialog.open(ModalRemoveChainComponent, {
      data: {
        chain: chain,
        chains: this.chainList
      }
    });
  }

}

