import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ApplicationVersionGym } from '../../../interfaces/applicationversion';
import { Gym } from '../../../interfaces/gym';
import { VersionsService } from '../../../services/versions.service';


@Component({
  selector: 'app-application-gyms-list',
  templateUrl: './application-gyms-list.component.html',
  styleUrls: ['./application-gyms-list.component.scss']
})
export class ApplicationGymsListComponent implements OnInit {

  @Input() appId: number;

  private gymList: ApplicationVersionGym[];

  displayedColumns = ['name', 'alert', 'version', 'date', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;


  constructor(private versionsService: VersionsService) {
    this.gymList = [];
    this.dataSource = new MatTableDataSource(this.gymList);
  }

  ngOnInit() {
    this.versionsService.getGymVersions(this.appId).subscribe(
      result => {
         this.gymList = result;
         this.refreshTableData();
      });    
  }

  ngAfterViewInit() {
    this.refreshTableData();
  }

  private refreshTableData(){
    this.dataSource = new MatTableDataSource(this.gymList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
