import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(translate: TranslateService, private globalService: GlobalService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
