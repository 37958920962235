import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apptype'
})
export class ApptypePipe implements PipeTransform {

  transform(appType: any): any {
    if (appType === 1) {
      return 'APP';
    } else if (appType === 2) {
      return 'DLL';
    } else {
      return '-';
    }
  }

}
