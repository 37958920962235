import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, NgForm } from '@angular/forms';

import { UsersService } from '../../services/users.service';
import { User } from '../../interfaces/user';
import { Profile } from '../../interfaces/profile';
import { AlertsService } from '../../services/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { Status } from '../../interfaces/status';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  gyms: any;
  user: User = {
    id: null,
    email: '',
    password: '',
    status: null,
    recoveryHash: '',
    creationDate: '',
    birthDate: '',
    refreshToken: '',
    refreshTokenExpires: '',
    name: '',
    lastName: '',
    country: '',
    experience: 0,
    gender: '',
    zip: '',
    city: '',
    allowNewsletter: true,
    allowNotifications: true,
    phone: '',
    profiles: [],
    gymsAccess: []
  };

  isNew: Boolean = false;
  profiles: Profile[];
  userProfiles: number[];
  userGyms: number[];
  userStatusList: Status[];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    public globalService: GlobalService) {

      this.userStatusList = this.route.snapshot.data.global.userStatusList;
  }

  ngOnInit() {
    if (this.route.snapshot.data.user === 'new') {
      this.isNew = true;

      this.translate.get('USERS.TITLE_NEW').subscribe((translation) => {
        this.globalService.changePageTitle([translation]);
      });
    } else {
      this.user = this.route.snapshot.data.user;
      this.globalService.changePageTitle([this.user.email]);
    }
    this.gyms = this.route.snapshot.data.gymList;
    this.profiles = this.route.snapshot.data.profileList;
    this.userProfiles = [];
    this.userGyms = [];

    this.updateUserProfiles();
    this.updateUserGyms();
  }

  public saveUser() {
    this.prepareUserProfiles();
    this.prepareUserGyms();
    this.usersService.putUser(this.user).subscribe(
      result => {
        this.translate.get('USERS.USER_SAVED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createUser() {
    this.user.id = 0;
    this.usersService.postUser(this.user).subscribe(
      result => {
        this.translate.get('USERS.USER_CREATED').subscribe((translation) => {
          this.alertsService.alertTrigger({ type: 'success', msg: translation });
        });
        this.router.navigate(['/users']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private updateUserProfiles() {
    for (const profile of this.user.profiles) {
      this.userProfiles.push(profile.id);
    }
  }

  private updateUserGyms() {
    for (const id of this.user.gymsAccess) {
      this.userGyms.push(id);
    }
  }

  prepareUserProfiles() {
    this.user.profiles = [];
    for (const id of this.userProfiles) {
      this.user.profiles.push(this.profiles.filter(prof => (prof.id === id))[0]);
    }
  }

  prepareUserGyms() {
    this.user.gymsAccess = [];
    for (const id of this.userGyms) {
      this.user.gymsAccess.push(id);
    }
  }

  onSubmit() {
    if (this.isNew) {
      this.createUser();
    } else {
      this.saveUser();
    }
  }

}
