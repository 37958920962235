import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap';

// Pagination
import { NgxPaginationModule } from 'ngx-pagination';

// Angular Material
import { MatButtonModule, MatCheckboxModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

import * as moment from 'moment';

// Services
import { GlobalService } from './services/global.service';
import { UsersService } from './services/users.service';
import { AlertsService } from './services/alerts.service';
import { GymsService } from './services/gyms.service';
import { KiosksService } from './services/kiosks.service';
import { ContactsService } from './services/contacts.service';
import { ChainsService } from './services/chains.service';
import { ProfilesService } from './services/profiles.service';
import { RolesService } from './services/roles.service';
import { ScriptsService } from './services/scripts.service';
import { AuthService } from './services/auth.service';
import { EventsService } from './services/events.service';
import { ProgramsService } from './services/programs.service';
import { MonitoringService } from './services/monitoring.service';
import { SubscriptionsService } from './services/subscriptions.service';
import { LoadingService } from './services/loading.service';
import { GymAlertsService } from './services/gymalerts.service';
import { LanguageService } from './services/language.service';

// Guards
import { AuthGuard } from './guards/auth-guard';

// Interceptors
import { AuthInterceptor } from './interceptors/auth-interceptor';

// Pipes
import { AppNamePipe } from './pipes/appname.pipe';
import { YesNoPipe } from './pipes/yesno.pipe';
import { StationNamePipe } from './pipes/stationname.pipe';
import { TrainingmodeiconPipe } from './pipes/trainingmodeicon.pipe';
import { ApptypePipe } from './pipes/apptype.pipe';
import { GymStatusPipe } from './pipes/gymstatus.pipe';
import { UserStatusPipe } from './pipes/userstatus.pipe';
import { StationiconPipe } from './pipes/stationicon.pipe';
import { ScriptStatusPipe } from './pipes/scriptstatus.pipe';
import { BodyChallengeStatusPipe } from './pipes/bodychallengestatus.pipe';

// Components
import { AlertsComponent } from './components/common/alerts/alerts.component';
import { UsersComponent } from './components/users/users.component';
import { HeaderComponent } from './components/common/header/header.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { UserDetailComponent } from './components/users/user-detail.component';
import { GymsComponent } from './components/gyms/gyms.component';
import { GymDetailComponent } from './components/gyms/gym-detail.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { VersionsComponent } from './components/versions/versions.component';
import { VersionsManagerComponent } from './components/versions/versions-manager.component';
import { ApplicationDetailComponent } from './components/applications/application-detail.component';
import { VersionDetailComponent } from './components/versions/version-detail.component';
import { ApplicationsService } from './services/applications.service';
import { VersionsService } from './services/versions.service';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactDetailComponent } from './components/contacts/contact-detail.component';
import { VersionListComponent } from './components/partials/version-list/version-list.component';
import { ChainsComponent } from './components/chains/chains.component';
import { ChainDetailComponent } from './components/chains/chain-detail.component';
import { DependencyListComponent } from './components/partials/dependency-list/dependency-list.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { VersionGymListComponent } from './components/partials/version-gym-list/version-gym-list.component';
import { VersionParentAppListComponent } from './components/partials/version-parent-app-list/version-parent-app-list.component';
import { GymContactListComponent } from './components/partials/gym-contact-list/gym-contact-list.component';
import { GymTrainingModesListComponent } from './components/partials/gym-training-modes-list/gym-training-modes-list.component';
import { GymStationEditorComponent } from './components/partials/gym-station-editor/gym-station-editor.component';
import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ScriptsComponent } from './components/scripts/scripts.component';
import { ScriptDetailComponent } from './components/scripts/script-detail.component';
import { ChainGymListComponent } from './components/partials/chain-gym-list/chain-gym-list.component';
import { RemoteDependencyListComponent } from './components/partials/remote-dependency-list/remote-dependency-list.component';
import { ScriptGymListComponent } from './components/partials/script-gym-list/script-gym-list.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramDetailComponent } from './components/programs/program-detail.component';
import { SpecificProgramsComponent } from './components/specific-programs/programs.component';
import { SpecificProgramDetailComponent } from './components/specific-programs/program-detail.component';
import { SessionListComponent } from './components/partials/session-list/session-list.component';
import { DemoSessionListComponent } from './components/partials/demo-session-list/demo-session-list.component';
import { GymProgramListComponent } from './components/partials/gym-program-list/gym-program-list.component';
import { KiosksComponent } from './components/kiosks/kiosks.component';
import { GymTimetableEditorComponent } from './components/partials/gym-timetable-editor/gym-timetable-editor.component';
import { TimetableComponent } from './components/gyms/timetable.component';
import { MonitoringListComponent } from './components/monitoring/monitoring-list.component';
import { MonitoringDetailComponent } from './components/monitoring/monitoring-detail.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { GymsListComponent } from './components/subscriptions/gyms-list/gyms-list.component';
import { PacksListComponent } from './components/subscriptions/packs-list/packs-list.component';
import { ProgramsListComponent } from './components/subscriptions/programs-list/programs-list.component';
import { ProListComponent } from './components/subscriptions/pro-list/pro-list.component';
import { SubscriptionAlertsComponent } from './components/subscriptions/alerts/subscription-alerts.component';
import { SubscriptionLogComponent } from './components/subscriptions/subscription-log/subscription-log.component';
import { SubscriptionsGymComponent } from './components/subscriptions/gym/gym.component';
import { GymProgramsComponent } from './components/subscriptions/gym/gym-programs/gym-programs.component';
import { GymPacksComponent } from './components/subscriptions/gym/gym-packs/gym-packs.component';
import { GymProsComponent } from './components/subscriptions/gym/gym-pros/gym-pros.component';
import { GymBootiqsComponent } from './components/subscriptions/gym/gym-bootiqs/gym-bootiqs.component';
import { GymOrdersComponent } from './components/subscriptions/gym/gym-orders/gym-orders.component';
import { SubscriptionsProgramComponent } from './components/subscriptions/program/program.component';
import { ProgramGymsComponent } from './components/subscriptions/program/program-gyms/program-gyms.component';
import { SubscriptionsPackComponent } from './components/subscriptions/pack/pack.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { ChainContentManagerListComponent } from './components/partials/chain-content-manager-list/chain-content-manager-list.component';

import { ApplicationGymsListComponent } from './components/versions/application-gyms-list/application-gyms-list.component';
import { ApplicationVersionListComponent } from './components/versions/application-version-list/application-version-list.component';
import { VersionsManagerGymComponent } from './components/versions/gym-versions/gym-versions.component';
import { GymVersionsLogComponent } from './components/versions/gym-versions/gym-programs/gym-versions-log.component';
import { VersionsManagerGymVersionsComponent } from './components/versions/version-gyms/version-gyms.component';
import { VersionGymsLogComponent } from './components/versions/version-gyms/gym-programs/version-gyms-log.component';
import { AlertManagerComponent } from './components/alerts/alert-manager.component';
import { GymAlertsComponent } from './components/alerts/gym-alerts/gym-alerts.component';

import { BodyChallengeListComponent } from './components/body-challenges/body-challenge-list/body-challenge-list.component';
import { BodyChallengeDetailComponent } from './components/body-challenges/body-challenge-detail/body-challenge-detail.component';
import { BodyChallengeFormComponent } from './components/body-challenges/body-challenge-form/body-challenge-form.component';

// Modal Components
import { ModalRemoveStationComponent } from './components/partials/gym-station-editor/gym-station-editor.component';
import { ModalRemoveKioskComponent } from './components/partials/gym-station-editor/gym-station-editor.component';
import { ModalRemoveChainComponent } from './components/chains/modal-remove-chain.component';
import { ModalRemoveUserComponent } from './components/users/modal-remove-user.component';
import { ModalRemoveProfileComponent } from './components/profiles/modal-remove-profile.component';
import { ModalRemoveContactComponent } from './components/contacts/modal-remove-contact.component';
import { ModalDisableGymComponent } from './components/partials/script-gym-list/modal-disable-gym.component';
import { ModalDisableGymsComponent } from './components/partials/script-gym-list/modal-disable-gyms.component';
import { ModalForceScriptComponent } from './components/partials/script-gym-list/modal-force-script.component';
import { ModalRemoveScriptComponent } from './components/scripts/modal-remove-script.component';
import { ModalTimetableEditComponent } from './components/partials/gym-timetable-editor/modal-timetable-edit.component';

// Resolvers
import { VersionListResolver } from './resolvers/version-list.resolver';
import { VersionDetailResolver } from './resolvers/version-detail.resolver';
import { GymListResolver } from './resolvers/gym-list.resolver';
import { GymVersionRelListResolver } from './resolvers/gym-version-rel-list.resolver';
import { GymScriptRelListResolver } from './resolvers/gym-script-rel-list.resolver';
import { GymDetailResolver } from './resolvers/gym-detail.resolver';
import { ApplicationListResolver } from './resolvers/application-list.resolver';
import { ApplicationDetailResolver } from './resolvers/application-detail.resolver';
import { UserListResolver } from './resolvers/user-list.resolver';
import { UserDetailResolver } from './resolvers/user-detail.resolver';
import { ContactListResolver } from './resolvers/contact-list.resolver';
import { ChainListResolver } from './resolvers/chain-list.resolver';
import { ProfileListResolver } from './resolvers/profile-list.resolver';
import { GlobalResolver } from './resolvers/global.resolver';
import { ScriptListResolver } from './resolvers/script-list.resolver';
import { ScriptDetailResolver } from './resolvers/script-detail.resolver';
import { ProgramListResolver } from './resolvers/program-list.resolver';
import { ProgramDetailResolver } from './resolvers/program-detail.resolver';
import { SpecificProgramDetailResolver } from './resolvers/specific-program-detail.resolver';
import { CreatorProgramsListResolver } from './resolvers/creator-programs-list.resolver';
import { CreatorSessionsListResolver } from './resolvers/creator-sessions-list.resolver';
import { KioskListResolver } from './resolvers/kiosk-list.resolver';
import { GymTimetableResolver } from './resolvers/gym-timetable.resolver';
import { MonitoringDetailResolver } from './resolvers/monitoring-detail.resolver';
import { MonitoringGymsResolver } from './resolvers/monitoring-gyms.resolver';
import { SubscriptionsGymListResolver } from './resolvers/subscriptions-gym-list.resolver';
import { SubscriptionsPackListResolver } from './resolvers/subscriptions-pack-list.resolver';
import { SubscriptionsProgramListResolver } from './resolvers/subscriptions-program-list.resolver';
import { SubscriptionsAlertsResolver } from './resolvers/subscriptions-alerts.resolver';
import { SubscriptionsGymOrderListResolver } from './resolvers/subscriptions-gym-order-list.resolver';
import { SubscriptionsGymPackListResolver } from './resolvers/subscriptions-gym-pack-list.resolver';
import { SubscriptionsGymProListResolver } from './resolvers/subscriptions-gym-pro-list.resolver';
import { SubscriptionsGymBootiqListResolver } from './resolvers/subscriptions-gym-bootiq-list.resolver';
import { SubscriptionsGymProgramListResolver } from './resolvers/subscriptions-gym-program-list.resolver';
import { SubscriptionsProgramGymListResolver } from './resolvers/subscriptions-program-gym-list.resolver';
import { SubscriptionsPackGymListResolver } from './resolvers/subscriptions-pack-gym-list.resolver';
import { GymAlertsResolver } from './resolvers/gym-alerts.resolver';
import { AlertsSubscriptionResolver } from './resolvers/alerts-subcscription.resolver';
import { AlertsApplicationResolver } from './resolvers/alerts-application.resolver';
import { VersionGymListResolver } from './resolvers/version-gym-list.resolver';
import { BodyChallengeListResolver } from './resolvers/body-challenge-list.resolver';
import { BodyChallengeFormResolver } from './resolvers/body-challenge-form.resolver';
import { BodyChallengeDetailResolver } from './resolvers/body-challenge-detail.resolver';
import { BodyChallengeUserListResolver } from './resolvers/body-challenge-user-list.resolver';
import { BodyChallengeParameterListResolver } from './resolvers/body-challenge-parameter-list.resolver';
import { BodyChallengeGymListResolver } from './resolvers/body-challenge-gym-list.resolver';
import { BodyChallengeAvailableTypesResolver } from './resolvers/body-challenge-available-types.resolver';
import { BodyChallengeAvailableUnitsResolver } from './resolvers/body-challenge-available-units.resolver';
import { LanguagesResolver } from './resolvers/languages-resolver';



@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    HeaderComponent,
    UserDetailComponent,
    AlertsComponent,
    GymsComponent,
    GymDetailComponent,
    ApplicationsComponent,
    VersionsComponent,
    ApplicationDetailComponent,
    VersionDetailComponent,
    ContactsComponent,
    ContactDetailComponent,
    VersionListComponent,
    ChainsComponent,
    ChainDetailComponent,
    DependencyListComponent,
    ProfilesComponent,
    VersionGymListComponent,
    VersionParentAppListComponent,
    AppNamePipe,
    YesNoPipe,
    GymContactListComponent,
    GymTrainingModesListComponent,
    GymStationEditorComponent,
    StationNamePipe,
    LoginComponent,
    SidebarComponent,
    FooterComponent,
    HomeComponent,
    ScriptsComponent,
    ScriptDetailComponent,
    ChainGymListComponent,
    ProgramsComponent,
    ProgramDetailComponent,
    ProgramsComponent,
    SpecificProgramDetailComponent,
    SpecificProgramsComponent,
    RemoteDependencyListComponent,
    ScriptGymListComponent,
    SessionListComponent,
    DemoSessionListComponent,
    GymProgramListComponent,
    KiosksComponent,
    GymTimetableEditorComponent,
    TimetableComponent,
    VersionsManagerComponent,
    ApplicationGymsListComponent,
    ApplicationVersionListComponent,
    VersionsManagerGymComponent,
    VersionsManagerGymVersionsComponent,
    GymVersionsLogComponent,
    VersionGymsLogComponent,
    AlertManagerComponent,
    GymAlertsComponent,
    ChainContentManagerListComponent,

    ModalRemoveStationComponent,
    ModalRemoveChainComponent,
    ModalRemoveProfileComponent,
    ModalRemoveUserComponent,
    ModalRemoveContactComponent,
    ModalRemoveKioskComponent,
    ModalDisableGymComponent,
    ModalDisableGymsComponent,
    ModalForceScriptComponent,
    ModalRemoveScriptComponent,
    ModalTimetableEditComponent,
    MonitoringListComponent,
    MonitoringDetailComponent,

    TrainingmodeiconPipe,
    ApptypePipe,
    GymStatusPipe,
    UserStatusPipe,
    StationiconPipe,
    ScriptStatusPipe,
    BodyChallengeStatusPipe,
    SubscriptionsComponent,
    GymsListComponent,
    PacksListComponent,
    ProgramsListComponent,
    ProListComponent,
    SubscriptionAlertsComponent,
    SubscriptionLogComponent,
    SubscriptionsGymComponent,
    GymProgramsComponent,
    GymPacksComponent,
    GymProsComponent,
    GymBootiqsComponent,
    GymOrdersComponent,
    SubscriptionsProgramComponent,
    SubscriptionsPackComponent,
    ProgramGymsComponent,
    LoadingComponent,
    BodyChallengeListComponent,
    BodyChallengeDetailComponent,
    BodyChallengeFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    NgxPaginationModule,
    FlexLayoutModule,

    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatGridListModule,
    MatTableModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule

  ],
  providers: [
    GlobalService,
    UsersService,
    GymsService,
    KiosksService,
    ApplicationsService,
    VersionsService,
    ContactsService,
    ChainsService,
    ProfilesService,
    RolesService,
    AlertsService,
    ScriptsService,
    AuthService,
    EventsService,
    ProgramsService,
    MonitoringService,
    SubscriptionsService,
    LoadingService,
    GymAlertsService,
    LanguageService,

    VersionListResolver,
    VersionDetailResolver,
    GymListResolver,
    GymVersionRelListResolver,
    GymScriptRelListResolver,
    GymDetailResolver,
    ApplicationListResolver,
    ApplicationDetailResolver,
    UserListResolver,
    UserDetailResolver,
    ContactListResolver,
    ChainListResolver,
    ProfileListResolver,
    ScriptListResolver,
    ScriptDetailResolver,
    ProgramListResolver,
    ProgramDetailResolver,
    SpecificProgramDetailResolver,
    CreatorProgramsListResolver,
    CreatorSessionsListResolver,
    KioskListResolver,
    GlobalResolver,
    GymTimetableResolver,
    MonitoringGymsResolver,
    MonitoringDetailResolver,
    SubscriptionsGymListResolver,
    SubscriptionsPackListResolver,
    SubscriptionsProgramListResolver,
    SubscriptionsAlertsResolver,
    SubscriptionsGymOrderListResolver,
    SubscriptionsGymPackListResolver,
    SubscriptionsGymProListResolver,
    SubscriptionsGymBootiqListResolver,
    SubscriptionsGymProgramListResolver,
    SubscriptionsProgramGymListResolver,
    SubscriptionsPackGymListResolver,
    VersionGymListResolver,
    GymAlertsResolver,
    AlertsSubscriptionResolver,
    AlertsApplicationResolver,
    BodyChallengeListResolver,
    BodyChallengeFormResolver,
    BodyChallengeDetailResolver,
    BodyChallengeUserListResolver,
    BodyChallengeParameterListResolver,
    BodyChallengeGymListResolver,
    BodyChallengeAvailableTypesResolver,
    BodyChallengeAvailableUnitsResolver,
    LanguagesResolver,
    AuthGuard,

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

  ],
  entryComponents: [
    ModalRemoveStationComponent,
    ModalRemoveChainComponent,
    ModalRemoveProfileComponent,
    ModalRemoveUserComponent,
    ModalRemoveContactComponent,
    ModalRemoveKioskComponent,
    ModalDisableGymComponent,
    ModalDisableGymsComponent,
    ModalForceScriptComponent,
    ModalRemoveScriptComponent,
    ModalTimetableEditComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
