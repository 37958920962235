import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { constants } from '../../assets/constants';
import { of } from 'rxjs/observable/of';

@Injectable()
export class KiosksService {

  private urlGetKiosks = constants.urlAPI + constants.endpoints.kiosks.get;

  constructor(public http: HttpClient) { }

  public getKiosks(): Observable<any> {
    return this.http.get(this.urlGetKiosks);
    
    // return of(
    //   [
    //     {
    //       id: 1,
    //       name: 'Kiosk 1',
    //       versionStatus: {
    //         id: 1,
    //         version: 1,
    //         active: true,
    //         syncDate: '2018-11-20 9:00:00'
    //       },
    //       scriptStatus: {
    //         id: 1,
    //         script: 1,
    //         status: 3,
    //         result: 'Correct',
    //         creationDate: '2018-11-20 10:00:00',
    //         executionDate: '2018-11-20 11:00:00',
    //         resultDate: '2018-11-20 11:00:01'
    //       },
    //       gym: {
    //         id: 1,
    //         name: 'Gym 1'
    //       }
    //     }
    //   ]
    // );
    
    // return of(
    //   [
    //     {
    //       id: 1,
    //       name: 'Kiosk 1',
    //       applicationVersion: [
    //         {
    //           id: 1,
    //           application: {
    //             id: 1,
    //             name: 'Application 1'
    //           },
    //           version: '1.0.0',
    //           creationDate: '2018-11-20 10:00:00',
    //           description: 'Application 1 description',
    //           versionStatus: {
    //             id: 1,
    //             version: 1,
    //             active: true,
    //             syncDate: '2018-11-20 10:00:00'
    //           }
    //         },
    //         {
    //           id: 2,
    //           application: {
    //             id: 2,
    //             name: 'Application 2'
    //           },
    //           version: '2.0.0',
    //           creationDate: '2018-11-20 10:00:00',
    //           description: 'Application 2 description',
    //           versionStatus: {
    //             id: 1,
    //             active: true,
    //             syncDate: '2018-11-20 10:00:00'
    //           }
    //         }
    //       ],
    //       scriptStatus: [
    //         {
    //           id: 1,
    //           scriptObj: {
    //             id: 1,
    //             name: 'Script 1',
    //             description: 'Description Script 1'
    //           },
    //           status: 1,
    //           result: 'Script succesfully on',
    //           creationDate: '2018-11-20 10:00:00',
    //           executionDate: '2018-11-20 10:00:00',
    //           resultDate: '2018-11-20 10:00:00'
    //         },
    //         {
    //           id: 2,
    //           scriptObj: {
    //             id: 2,
    //             name: 'Script 2',
    //             description: 'Description Script 2'
    //           },
    //           status: 2,
    //           result: 'Script disabled',
    //           creationDate: '2018-11-20 10:00:00',
    //           executionDate: '2018-11-20 10:00:00',
    //           resultDate: '2018-11-20 10:00:00'
    //         }
    //       ],
    //       gym: {
    //         id: 1,
    //         name: 'Gym 1'
    //       }
    //     },
    //     {
    //       id: 2,
    //       name: 'Kiosk 1',
    //       applicationVersion: [
    //         {
    //           id: 1,
    //           application: {
    //             id: 1,
    //             name: 'Application 1'
    //           },
    //           version: '1.0.0',
    //           creationDate: '2018-11-20 10:00:00',
    //           description: 'Application 1 description',
    //           versionStatus: {
    //             id: 1,
    //             version: 1,
    //             active: true,
    //             syncDate: '2018-11-20 10:00:00'
    //           }
    //         },
    //         {
    //           id: 2,
    //           application: {
    //             id: 2,
    //             name: 'Application 2'
    //           },
    //           version: '2.0.0',
    //           creationDate: '2018-11-20 10:00:00',
    //           description: 'Application 2 description',
    //           versionStatus: {
    //             id: 1,
    //             active: true,
    //             syncDate: '2018-11-20 10:00:00'
    //           }
    //         }
    //       ],
    //       scriptStatus: [
    //         {
    //           id: 1,
    //           scriptObj: {
    //             id: 1,
    //             name: 'Script 1',
    //             description: 'Description Script 1'
    //           },
    //           status: 1,
    //           result: 'OK',
    //           creationDate: '2018-11-20 10:00:00',
    //           executionDate: '2018-11-20 10:00:00',
    //           resultDate: '2018-11-20 10:00:00'
    //         },
    //         {
    //           id: 2,
    //           scriptObj: {
    //             id: 2,
    //             name: 'Script 2',
    //             description: 'Description Script 2'
    //           },
    //           status: 4,
    //           result: 'Cannot do things',
    //           creationDate: '2018-11-20 10:00:00',
    //           executionDate: '2018-11-20 10:00:00',
    //           resultDate: '2018-11-20 10:00:00'
    //         }
    //       ],
    //       gym: {
    //         id: 2,
    //         name: 'Gym 2'
    //       }
    //     }
    //   ]
    // );
  }

}
