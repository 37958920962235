import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ApplicationsService } from '../services/applications.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { GymAlertsService } from '../services/gymalerts.service';

@Injectable()
export class AlertsSubscriptionResolver implements Resolve<Observable<string>> {

  constructor(private gymAlertsService: GymAlertsService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.gymAlertsService.getSubsAlerts().catch(() => {
      return Observable.of('error');
    });
  }
}
