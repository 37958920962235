import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { constants } from '../../assets/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { GymAlert } from '../interfaces/gymalert';

@Injectable()
export class GymAlertsService {

  private urlGetGymAlerts = constants.urlAPI + constants.endpoints.gymAlerts.get;
  private urlPutGymAlerts = constants.urlAPI + constants.endpoints.gymAlerts.put;
  private urlGetAppAlerts = constants.urlAPI + constants.endpoints.gymAlerts.getApp;
  private urlPutAppAlerts = constants.urlAPI + constants.endpoints.gymAlerts.putApp;
  private urlGetSubsAlerts = constants.urlAPI + constants.endpoints.gymAlerts.getSubs;
  private urlPutSubsAlerts = constants.urlAPI + constants.endpoints.gymAlerts.putSubs;

  constructor(public http: HttpClient) { }

  public getGymAlerts(): Observable<any> {
    return this.http.get(this.urlGetGymAlerts);
  }
  public putGymAlerts(alert: GymAlert): Observable<any> {
    const data = alert;
    return this.http.put(this.urlPutGymAlerts, data);
  }

  public getAppAlerts(): Observable<any> {
    return this.http.get(this.urlGetAppAlerts);
  }
  public putAppAlerts(alert: GymAlert): Observable<any> {
    const data = alert;
    return this.http.put(this.urlPutAppAlerts, data);
  }

  public getSubsAlerts(): Observable<any> {
    return this.http.get(this.urlGetSubsAlerts);
  }
  public putSubsAlerts(alert: GymAlert): Observable<any> {
    const data = alert;
    return this.http.put(this.urlPutSubsAlerts, data);
  }
}
