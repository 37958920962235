import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Role } from '../interfaces/role';
import { constants } from '../../assets/constants';

@Injectable()
export class RolesService {

  public roleList: Role[];

  private urlGetRoles = constants.urlAPI + constants.endpoints.roles.get;

  constructor(public http: HttpClient) {
    this.getRoles().subscribe(
      result => {
        this.roleList = result;
      },
      error => {
        console.log('Error: get roles');
      }
    );
  }

  public getRoles(): Observable<any> {
    return this.http.get(this.urlGetRoles);
  }

}
