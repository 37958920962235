import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SubscriptionsService } from '../services/subscriptions.service';

@Injectable()
export class SubscriptionsPackGymListResolver implements Resolve<Observable<string>> {

    constructor(private subscriptionsService: SubscriptionsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') !== 'new') {
            return this.subscriptionsService.getProgramGyms(route.paramMap.get('id')).catch(() => {
                return Observable.of('error');
            });
          } else {
            return Observable.of('new');
          }
    }

}
