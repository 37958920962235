import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { Gym } from '../../../interfaces/gym';
import { ApplicationMini } from '../../../interfaces/application';

@Component({
  selector: 'app-gym-versions',
  templateUrl: './gym-versions.component.html',
  styleUrls: ['./gym-versions.component.scss']
})
export class VersionsManagerGymComponent implements OnInit {

  private gym: Gym;
  private appList: ApplicationMini[];
  

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private globalService: GlobalService) {

    this.gym = this.route.snapshot.data.gym;  
    this.appList = [];  
  }

  ngOnInit() {
    // Cargamos todas las apps en array
    this.route.snapshot.data.applicationList.forEach(app => {
      this.appList.push(app);
    });
    
    this.translate.get('VERSIONS.VERSIONS').subscribe((translation) => {
      this.globalService.changePageTitle([translation + ' - ' + this.gym.name]);
    });
  }

}
