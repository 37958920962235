import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { BodyChallengesService } from '../services/body-challenges.service';

@Injectable()
export class BodyChallengeListResolver implements Resolve<Observable<string>> {

    constructor(private bodyChallengesService: BodyChallengesService) { }

    resolve() {
        return this.bodyChallengesService.getBodyChallengeList().catch(() => {
            return Observable.of('error');
        });
        
        return Observable.of([
            {
                id: 1,
                name: 'Test body challenge',
                creationDate: '2020-10-01T17: 42: 23.4361442+02: 00',
                initDate: '2020-10-02T17: 42: 23.4361805+02: 00',
                finishDate: '2020-10-31T17: 42: 23.4362162+01: 00',
                status: 1,
                challenges: [
                    {
                        id: 2,
                        creationDate: '2020-10-01T17: 42: 23.4360803+02: 00',
                        challengeType: {
                            id: 2,
                            name: 'Burn'
                        },
                        parameters: [
                            {
                                id: 1,
                                name: 'Peso corporal',
                                unit: {
                                    id: 1,
                                    name: 'kilograms',
                                    alias: 'kg'
                                }
                            },
                            {
                                id: 3,
                                name: 'Altura',
                                unit: {
                                    id: 2,
                                    name: 'centimeters',
                                    alias: 'cm'
                                }
                            }
                        ],
                        users: [
                            {
                                pramacloudId: 123,
                                pramacloudGymId: 17,
                                name: 'Rodolfato',
                                nick: 'Ent',
                                points: 34,
                                measurements: [
                                    {
                                        id: 3,
                                        name: 'First burn measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358589+02: 00',
                                        measurementPoints: 15,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 1,
                                                    name: 'Peso corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 25
                                            },
                                            {
                                                parameter: {
                                                    id: 3,
                                                    name: 'Altura',
                                                    unit: {
                                                        id: 2,
                                                        name: 'centimeters',
                                                        alias: 'cm'
                                                    }
                                                },
                                                value: 19
                                            }
                                        ]
                                    },
                                    {
                                        id: 4,
                                        name: 'Last burn measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358594+02: 00',
                                        measurementPoints: 15,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 1,
                                                    name: 'Peso corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 20
                                            },
                                            {
                                                parameter: {
                                                    id: 3,
                                                    name: 'Altura',
                                                    unit: {
                                                        id: 2,
                                                        name: 'centimeters',
                                                        alias: 'cm'
                                                    }
                                                },
                                                value: 16
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                pramacloudId: 124213,
                                pramacloudGymId: 17,
                                name: 'Pipita',
                                nick: 'Losetas',
                                points: 30,
                                measurements: [
                                    {
                                        id: 3,
                                        name: 'First burn measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358598+02: 00',
                                        measurementPoints: 15,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 1,
                                                    name: 'Peso corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 25
                                            },
                                            {
                                                parameter: {
                                                    id: 3,
                                                    name: 'Altura',
                                                    unit: {
                                                        id: 2,
                                                        name: 'centimeters',
                                                        alias: 'cm'
                                                    }
                                                },
                                                value: 19
                                            }
                                        ]
                                    },
                                    {
                                        id: 4,
                                        name: 'Last burn measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358602+02: 00',
                                        measurementPoints: 15,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 1,
                                                    name: 'Peso corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 20
                                            },
                                            {
                                                parameter: {
                                                    id: 3,
                                                    name: 'Altura',
                                                    unit: {
                                                        id: 2,
                                                        name: 'centimeters',
                                                        alias: 'cm'
                                                    }
                                                },
                                                value: 16
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 1,
                        creationDate: '2020-10-01T17: 42: 23.4358938+02: 00',
                        challengeType: {
                            id: 1,
                            name: 'Build'
                        },
                        parameters: [
                            {
                                id: 2,
                                name: 'Grasa corporal',
                                unit: {
                                    id: 1,
                                    name: 'kilograms',
                                    alias: 'kg'
                                }
                            },
                            {
                                id: 4,
                                name: 'Peso levantado',
                                unit: {
                                    id: 1,
                                    name: 'kilograms',
                                    alias: 'kg'
                                }
                            }
                        ],
                        users: [
                            {
                                pramacloudId: 288,
                                pramacloudGymId: 17,
                                name: 'Agustin',
                                nick: 'Agus',
                                points: 110,
                                measurements: [
                                    {
                                        id: 1,
                                        name: 'First measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4355318+02: 00',
                                        measurementPoints: 60,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 2,
                                                    name: 'Grasa corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 25
                                            },
                                            {
                                                parameter: {
                                                    id: 4,
                                                    name: 'Peso levantado',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 19
                                            }
                                        ]
                                    },
                                    {
                                        id: 2,
                                        name: 'Last measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358157+02: 00',
                                        measurementPoints: 50,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 2,
                                                    name: 'Grasa corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 20
                                            },
                                            {
                                                parameter: {
                                                    id: 4,
                                                    name: 'Peso levantado',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 16
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                pramacloudId: 2812,
                                pramacloudGymId: 17,
                                name: 'Manuel',
                                nick: 'Dominooooooos',
                                points: 75,
                                measurements: [
                                    {
                                        id: 1,
                                        name: 'First measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358517+02: 00',
                                        measurementPoints: 25,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 2,
                                                    name: 'Grasa corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 35
                                            },
                                            {
                                                parameter: {
                                                    id: 4,
                                                    name: 'Peso levantado',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 17
                                            }
                                        ]
                                    },
                                    {
                                        id: 2,
                                        name: 'Last measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358575+02: 00',
                                        measurementPoints: 50,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 2,
                                                    name: 'Grasa corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 30
                                            },
                                            {
                                                parameter: {
                                                    id: 4,
                                                    name: 'Peso levantado',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 16
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                pramacloudId: 2132,
                                pramacloudGymId: 17,
                                name: 'Diego',
                                nick: 'Rambo',
                                points: 30,
                                measurements: [
                                    {
                                        id: 1,
                                        name: 'First measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358581+02: 00',
                                        measurementPoints: 15,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 2,
                                                    name: 'Grasa corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 25
                                            },
                                            {
                                                parameter: {
                                                    id: 4,
                                                    name: 'Peso levantado',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 19
                                            }
                                        ]
                                    },
                                    {
                                        id: 2,
                                        name: 'Last measurement',
                                        creationDate: '2020-10-01T17: 42: 23.4358585+02: 00',
                                        measurementPoints: 15,
                                        values: [
                                            {
                                                parameter: {
                                                    id: 2,
                                                    name: 'Grasa corporal',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 20
                                            },
                                            {
                                                parameter: {
                                                    id: 4,
                                                    name: 'Peso levantado',
                                                    unit: {
                                                        id: 1,
                                                        name: 'kilograms',
                                                        alias: 'kg'
                                                    }
                                                },
                                                value: 16
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                gyms: [
                    1,
                    2,
                    3,
                    4
                ]
            }
        ]
        );        
    }

}

