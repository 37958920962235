import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApplicationVersion, ApplicationGymVersion } from '../interfaces/applicationversion';
import { constants } from '../../assets/constants';

@Injectable()
export class VersionsService {

  private urlGetVersions = constants.urlAPI + constants.endpoints.applicationVersions.get;
  private urlGetGymVersions = constants.urlAPI + constants.endpoints.applicationVersions.getGymVersions;
  private urlGetApplicationVersions = constants.urlAPI + constants.endpoints.applicationVersions.getApplicationVersions;
  private urlGetGymVersionsLog = constants.urlAPI + constants.endpoints.applicationVersions.getGymVersionsLog;
  private urlPutGymVersionsLog = constants.urlAPI + constants.endpoints.applicationVersions.putGymVersionsLog;
  private urlGetVersionGymsLog = constants.urlAPI + constants.endpoints.applicationVersions.getVersionGymsLog;
  private urlPutVersionGymsLog = constants.urlAPI + constants.endpoints.applicationVersions.putVersionGymsLog;

  private urlGetVersion = constants.urlAPI + constants.endpoints.applicationVersion.get;
  private urlPutVersion = constants.urlAPI + constants.endpoints.applicationVersion.put;
  private urlPostVersion = constants.urlAPI + constants.endpoints.applicationVersion.post;
  private urlDeleteVersion = constants.urlAPI + constants.endpoints.applicationVersion.delete;

  constructor(public http: HttpClient) { }

  public getVersions(): Observable<any> {
    return this.http.get(this.urlGetVersions);
  }

  public getVersion(id): Observable<any> {
    return this.http.get(this.urlGetVersion.replace(':id', id));
  }

  public putVersion(version: ApplicationVersion): Observable<any> {
    const data = version;
    return this.http.put(this.urlPutVersion, data);
  }

  public postVersion(version: ApplicationVersion): Observable<any> {
    const data = version;
    return this.http.post(this.urlPostVersion, data);
  }

  public deleteVersion(id: number): Observable<any> {
    return this.http.delete(this.urlDeleteVersion.replace(':id', id.toString()));
  }

  public getGymVersions(id): Observable<any> {
    return this.http.get(this.urlGetGymVersions.replace(':id', id));
  }

  public getApplicationVersions(id): Observable<any> {
    let params = new HttpParams().set('appId', id);
    return this.http.get(this.urlGetApplicationVersions, { params: params });
  }

  public getGymVersionsLog(gymId: number, appId: number): Observable<any> {
    let params = new HttpParams().set('appId', appId.toString());
    return this.http.get(this.urlGetGymVersionsLog.replace(':gymId', gymId.toString()), { params: params });
  }

  public putGymVersionsLog(version: ApplicationGymVersion): Observable<any> {
    const data = version;
    return this.http.put(this.urlPutGymVersionsLog, data);
  }

  public getVersionGymsLog(versionId: number): Observable<any> {    
    return this.http.get(this.urlGetVersionGymsLog.replace(':versionId', versionId.toString()));
  }

  public putVersionGymsLog(version: ApplicationGymVersion): Observable<any> {
    const data = version;
    return this.http.put(this.urlPutVersionGymsLog, data);
  }
}
