import { Component, OnInit, Input } from '@angular/core';
import { GymTimetable, TimetableTime } from '../../../interfaces/gymtimetable';
import { GymsService } from '../../../services/gyms.service';
import { AlertsService } from '../../../services/alerts.service';
import { ActivatedRoute } from '@angular/router';
import { Time } from '@angular/common';
import { ModalTimetableEditComponent } from './modal-timetable-edit.component';
import { MatDialog } from '@angular/material';
import { Gym } from '../../../interfaces/gym';

@Component({
  selector: 'app-gym-timetable-editor',
  templateUrl: './gym-timetable-editor.component.html',
  styleUrls: ['./gym-timetable-editor.component.scss']
})
export class GymTimetableEditorComponent implements OnInit {

  @Input() timetable: GymTimetable[];
  @Input() gymId: number;

  hoursArray: TimetableTime[] = [];
  daysArray: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  timetableStartHour = 6;
  timetableEndHour = 23;

  currentEventId = 0;
  cellBlockSize = '100px';

  constructor(private gymsService: GymsService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {

    this.generateHoursArray();
  }

  ngOnInit() { }

  generateHoursArray() {
    for (let i = this.timetableStartHour; i < this.timetableEndHour; i++) {
      this.hoursArray.push({ hours: i, minutes: 0 });
    }
  }

  getEventsOfDay(day: number): GymTimetable[] {
    if (this.timetable) {
      return this.timetable.filter(x => x.weekDay === day);
    } else {
      return [];
    }
  }

  getEventOffset(event: GymTimetable): number {
    const blockSize = 100;
    const offsetHours = this.hoursArray.findIndex(x => x.hours === this.getTimePart(event.startTime, 0));
    const offsetMinutes = this.getTimePart(event.startTime, 1) * 100 / 60;
    return blockSize + offsetHours * blockSize + offsetMinutes; // Add dayTitle block
  }

  getEventSize(event: GymTimetable): number {
    const eventDuration = this.calcTimeDiff(event.startTime, event.finishTime);
    return eventDuration * 100 / 60;
  }

  calcTimeDiff(time1: string, time2: string): number {
    let diff = 0;
    diff += 60 * (this.getTimePart(time2, 0) - this.getTimePart(time1, 0));
    diff += this.getTimePart(time2, 1) - this.getTimePart(time1, 1);
    return diff;
  }

  getTimePart(input: string, part: number) {
    return Number(input.split(':')[part]);
  }

  formatTime(hours: number, minutes: number): string {
    return hours + ':' + ('0' + minutes).slice(-2);
  }

  private modalEditEvent(event: GymTimetable, isNew: boolean = false) {
    this.dialog.open(ModalTimetableEditComponent, {
      data: {
        event: event,
        timetable: this.timetable,
        isNew: isNew
      },
      width: '500px'
    });
  }

  private modalNewEvent(time: TimetableTime, weekDay: number) {
    const newEvent: GymTimetable = {
      id: this.currentEventId,
      createdBy: 0,
      creationDate: new Date(),
      title: '',
      description: '',
      type: 0,
      weekDay: weekDay,
      startTime: this.formatTime(time.hours, time.minutes) + ':00',
      finishTime: this.formatTime(time.hours + 1, time.minutes) + ':00',
      eventId: 0,
      gymId: this.gymId,
    };

    this.modalEditEvent(newEvent, true);

    this.currentEventId++;
  }
}
