import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Application } from '../../interfaces/application';
import { ApplicationsService } from '../../services/applications.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { constants } from '../../../assets/constants';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit, AfterViewInit {

  canModify: boolean;
  canCreate: boolean;

  applicationList: any;

  displayedColumns = ['name', 'type', 'versions', 'active', 'actions'];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private globalService: GlobalService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.applicationList = this.route.snapshot.data.applicationList;

    this.translate.get('APPLICATIONS.TITLE').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.canCreate = this.authService.isInRole(constants.roles.application_create);
    this.canModify = this.authService.isInRole(constants.roles.application_modify);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.applicationList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.firstPage();
    this.dataSource.sort = this.sort;
  }

}
