import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UsersService } from '../services/users.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class UserDetailResolver implements Resolve<Observable<string>> {

    constructor(private usersService: UsersService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') !== 'new') {
            return this.usersService.getUser(route.paramMap.get('id')).catch(() => {
                return Observable.of('error');
            });
          } else {
            return Observable.of('new');
          }
    }
}
